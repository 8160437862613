import React from 'react';
import { Helmet } from 'react-helmet';
import { ForgotPasswordStyle } from '../../Layouts/style';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { RightArrow } from '../../Icons/SVGContainer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import useAuth from '../../Redux/Actions/authActions';
import { resetPasswordValidationSchema } from '../../Utils/validators';
import PasswordField from '../../Components/PasswordField';

const NewPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const passwordResetToken = searchParams.get('passwordResetToken');
  const { reset_password } = useAuth();
  const resetPasswordState = {
    passwordResetToken: passwordResetToken,
    password: '',
    confirmPassword: '',
  };
  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    // Handle form submission logic here
    // login({ body: values });
    const response = await reset_password(values);
    if (response.data?.resetTenantUserPassword) {
      navigate('/reset-password');
    }
    // Reset the form after submission
    // resetForm();
  };
  return (
    <ForgotPasswordStyle>
      <Helmet>
        <title>Mirasys: New password</title>
      </Helmet>
      <Box className="forgot_pass_area">
        <Box className="forgot_box_con">
          <Box className="forgot_heading">
            <img src="/images/logo.svg" alt="" />
            <Typography component="h2">Set new password</Typography>
            <Typography>Set a new password</Typography>
          </Box>
          <Formik
            initialValues={resetPasswordState}
            validationSchema={resetPasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box className="form_box">
                  <PasswordField
                    label="Password"
                    name="password"
                    placeholder="Password"
                    onChange={props.handleChange}
                    error={props.touched.password && !!props.errors.password}
                    helperText={
                      props.touched.password ? props.errors.password : ''
                    }
                  />
                  {/* <Typography component="label">Password</Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={props.handleChange}
                      error={props.touched.password && !!props.errors.password}
                      helperText={
                        props.touched.password ? props.errors.password : ''
                      }
                    />
                  </FormControl> */}
                </Box>
                <Box className="form_box">
                  <PasswordField
                    label="Confirm Password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={props.handleChange}
                    error={
                      props.touched.confirmPassword &&
                      !!props.errors.confirmPassword
                    }
                    helperText={
                      props.touched.confirmPassword
                        ? props.errors.confirmPassword
                        : ''
                    }
                  />
                  {/* <Typography component="label">Confirm Password</Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      onChange={props.handleChange}
                      error={
                        props.touched.confirmPassword &&
                        !!props.errors.confirmPassword
                      }
                      helperText={
                        props.touched.confirmPassword
                          ? props.errors.confirmPassword
                          : ''
                      }
                    />
                  </FormControl> */}
                </Box>
                <Box className="form_box">
                  <Button className="submitBtn" type="submit">
                    Confirm
                    <RightArrow />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </ForgotPasswordStyle>
  );
};

export default NewPassword;
