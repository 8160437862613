import React from 'react';
import { Modal, Box, Typography, Button, IconButton, styled } from '@mui/material';
import { DeleteIconModal } from '../Icons/SVGContainer';
import CloseIcon from '@mui/icons-material/Close';

const CancelButton = styled(Button)({
  color: '#fff',
  backgroundColor: '#3d4439',
  padding: '0.625rem 2.65rem',
  '&:hover': {
    backgroundColor: '#000',
    color: '#fff',
  },
});
const ConfirmButton = styled(Button)({
  color: '#000',
  backgroundColor: '#fff',
  padding: '0.625rem 2.65rem',
  '&:hover': {
    backgroundColor: '#000',
    color: '#fff',
  },
});

const DeleteDialog = ({ title, message, setIsOpen, isOpen, onClose, onDelete }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#252c20',
          boxShadow: 'none',
          p: 4,
          borderRadius: 2,
          textAlign: 'center'
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <DeleteIconModal />
        </Box>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <CancelButton onClick={onClose}>No</CancelButton>
          <ConfirmButton onClick={onDelete}>
            Yes
          </ConfirmButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteDialog;

