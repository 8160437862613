import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import UserManagementHeading from './UserManagementHeading';
import { Box, CircularProgress } from '@mui/material';
import { UserStyle } from '../../../Layouts/style';
// import UserDataTable from '../../../Components/DataTable/UserDataTable';
import { Pagination, Stack } from '@mui/material';
import BaseModal from '../../../Components/Modal/BaseModal';
import AddUserForm from '../../../Components/Form/AddUserForm';
// import { checkUserManagementPermission } from '../../../Utils/checkPermission';
import { toast } from 'react-toastify';
import UserService  from '../../../RestSevices/userService';
import useAuth from '../../../Redux/Actions/authActions';
import moment from 'moment/moment';
import debounce from 'lodash.debounce';
import CommonDataTable from '../../../Components/DataTable/CommonDatable';
import DeleteDialog from '../../../Components/deleteDialog';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ status: 'active' });
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [editIntialData, setEditIntialData] = useState({});
  const [userId, setUserId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);


  const { logout } = useAuth();

  const fetchUsers = useCallback(async (searchQuery, status) => {
    // Fetch data from API
    setLoading(true);
    UserService.getUserList(page, searchQuery, status)
      .then((response) => {
        setUsers(response.data);
        // setTotalPages(1);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }, []);

  // Debounced version of the fetchResults function
  const debouncedFetchResults = useCallback(debounce(fetchUsers, 300), []);

  useEffect(() => {
    // fetchUsers();
  }, [page, fetchUsers, search]);

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleChangeList = (status, searchVal) => {
    setFilter({ status });
    setSearch(searchVal);
    debouncedFetchResults(searchVal, status);
    setPage(1);
  };
  const handleForm = () => {
    debouncedFetchResults(search, filter.status);
  }
  const handleEditForm = (user) => {
    setEditIntialData(user);
    setModalOpen(true);
  };

  const onDelete = async () => {
    try {
      await UserService.deleteUser(userId).catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      setIsOpen(false);
      toast.success('User deleted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      debouncedFetchResults(search, filter.status);
    } catch (err) {
      console.log(err.message);
      toast.error('Error in deleting user', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDelete = (row) => {
    setUserId(row.id);
    setIsOpen(true);
  }
  const handleClose = () => {
    setIsOpen(false);
  };
  const columns = [
    'nameWithAvatar',
    'status',
    'email',
    'created_at',
    'updated_at',
    'roles',
    'action',
  ];
  const headCells = [
    {
      id: 'nameWithAvatar',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    {
      id: 'emailAddress',
      numeric: false,
      disablePadding: true,
      label: 'Email',
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created At',
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: true,
      label: 'Updated At',
    },
    {
      id: 'roles',
      numeric: false,
      disablePadding: true,
      label: 'Roles',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  const rows = [
    ...users.map((user) => {
      return {
        ...user,
        id: user._id,
      };
    }),
  ];
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Users</title>
      </Helmet>
      <Box className="user_area">
        <UserManagementHeading
          fetchUsers={fetchUsers}
          status={filter.status}
          handleChangeList={handleChangeList}
        />
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
          <CommonDataTable
            rows={rows}
            headCells={headCells}
            columns={columns}
            handleEdit={handleEditForm}
            handleDelete={handleDelete}
          />
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
          </>
        )}
        <BaseModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={`Edit User`}
          subtitle={`Created At: ${moment(editIntialData.created_at).format('DD/MM/YYYY')}`}
        >
          <AddUserForm
            onClose={() => setModalOpen(false)}
            fetchUsers={handleForm}
            initialData={editIntialData}
          />
        </BaseModal>
        <DeleteDialog
          isOpen={isOpen}
          title="Delete User?"
          message="Are you sure you want to delete this user?"
          setIsOpen={setIsOpen}
          onClose={handleClose}
          onDelete={onDelete}
        />
      </Box>
    </UserStyle>
  );
};

export default UserList;
