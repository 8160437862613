import React, { createContext, useContext, useState } from 'react'

import DeviceService from '../RestSevices/deviceService'

// Cria o contexto
const StreamsContext = createContext()

const fetchData = (pc, deviceId, offer) => {
  DeviceService.onlineDevice(deviceId, offer)
    .then(response => {
      let online = response.data
      let answerSDP = atob(atob(online.answer))
      let candidate = online.candidate

      pc.setRemoteDescription(
        new RTCSessionDescription({
          type: 'answer',
          sdp: answerSDP
        })
      )

      if (candidate != undefined) {
        pc.addIceCandidate(JSON.parse(candidate))
      }
    })
    .catch(error => {
      if (error.response?.data?.error === 'Authentication failed') {
        console.log(error)
      } else {
        console.log(error)
      }
    })
}

class Streams {
  constructor() {
    this.streams = new Map()
  }

  get(id) {
    let cc = this.streams.get(id)

    if (cc == undefined) {
      let pc = new RTCPeerConnection({
        iceServers: [
          {
            urls: 'stun:stun.mirasys.com.br:3478'
          },
          {
            urls: 'turn:turn.mirasys.com.br:3478',
            username: 'turnuser',
            credential: 'turn456'
          }
        ]
      })

      let promise = new Promise((resolve, _) => {
        pc.ontrack = event => {
          if (event.track.kind == 'video') {
            resolve(event.streams[0])
          }
        }
      })

      this.streams.set(id, promise)

      pc.addTransceiver('video', {
        direction: 'recvonly'
      })

      pc.oniceconnectionstatechange = e =>
        console.log(
          `Device: ${id} - Status: ${pc.iceConnectionState} - Event: ${e}`
        )

      pc.onicecandidate = event => {
        if (event.candidate === null) {
          fetchData(pc, id, btoa(pc.localDescription.sdp))
        }
      }

      pc.createOffer()
        .then(d => pc.setLocalDescription(d))
        .catch(m => console.log(`Device: ${id} - Error: ${m}`))

      return promise
    }

    return cc
  }
}

// Provedor do contexto
export const StreamsProvider = ({ children }) => {
  const [streams, _setStreams] = useState(new Streams())

  return (
    <StreamsContext.Provider value={{ streams }}>
      {children}
    </StreamsContext.Provider>
  )
}

// Hook personalizado para usar o contexto
export const useStreams = () => {
  return useContext(StreamsContext)
}
