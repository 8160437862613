import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const commonColor = {
  palatte: {
    primary: '#03A415',
    secondary: '#00036E',
    error: '#F00',
    warning: '#F9B959',
    white: '#ffffff',
    black: '#000000',
    success: '#37ad00',
    blue: '#0096ff'
  },
  error: '#F00',
  activeBgColor: '#117137',
  activeTextColor: '#64D662',
  blueChartColor: '#3D12B7',
  canvasBgColor: '#12B76A',
  lightBorderColor: 'rgba(255, 255, 255, 0.14)',
  lightTextColor: 'rgba(255, 255, 255, 0.46)'
}

const dark = {
  ...commonColor,
  color: {
    color1: '#191919',
    color2: '#242426',
    color3: '#252C20',
    color4: '#383838',
    color5: '#1f311e',
    color6: '#364030',
    color7: '#2b3b29',
    color8: '#2F353A',
    color9: '#454b42',
    color10: '#363F30',
    color11: '#2F2828',
    color12: '#4f4f50',
    color13: '#48533E',
    color14: '#3D4439',
    color15: '#2E3529',
    color16: '#5f6a5e',
    color17: '#999999',
    color18: '#000000',

    // new colors
    gray800: '#1E1E1E'
  },

  invertColor: {
    color1: '#FFFFFF',
    color2: '#D9D9D9',
    color3: '#D9DDE6',
    color4: '#E2E2E2'
  }
}

const light = {
  ...commonColor,

  color: {
    color1: '#FFFFFF',
    color2: '#D9D9D9',
    color3: '#D9DDE6',
    color4: '#E2E2E2',
    color5: '#E0CEE1',
    color6: '#CBCDC7',
    color7: '#D4C4D2',
    color8: '#D0C7C2',
    color9: '#BBAEB5',
    color10: '#C0C0CF',
    color11: '#C0D7D7',
    color12: '#B0B0AF',
    color13: '#BABCC1',
    color14: '#C5BCC3',
    color15: '#CBC3D7',
    color16: '#A0A59C',
    color17: '#666666',
    color18: '#FFFFFF'
  },
  invertColor: {
    color1: '#191919',
    color2: '#242426',
    color3: '#252C20',
    color4: '#383838'
  }
}

const createPrimaryTheme = (type = 'dark') => {
  const themeColors = type === 'dark' ? { ...dark } : { ...light }
  let theme = createTheme({
    colors: { ...themeColors },
    palette: {
      primary: {
        main: themeColors.palatte.primary
      },
      secondary: {
        main: themeColors.palatte.secondary
      },
      error: {
        main: themeColors.palatte.error
      },
      success: {
        main: themeColors.palatte.primary
      },
      warning: {
        main: themeColors.palatte.warning
      },
      background: {
        paper: themeColors.color.color6
      },
      text: {
        primary: themeColors.invertColor.color1,
        secondary: themeColors.invertColor.color2
      }
    },
    typography: {
      fontFamily: `'Mulish', sans-serif`,
      h1: {
        fontSize: '2rem',
        fontWeight: '600'
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: '600'
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: '600'
      },
      h4: {
        fontSize: '1.4rem',
        fontWeight: '600'
      },
      h5: {
        fontSize: '1.2rem',
        fontWeight: '600'
      },
      h6: {
        fontSize: '1rem',
        fontWeight: '600'
      }
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained'
        },

        styleOverrides: {
          root: {
            fontSize: '1rem',
            textTransform: 'capitalize',

            '&&&': {
              boxShadow: 'unset'
            }
          },

          sizeLarge: {
            height: '3.5rem'
          }
        }
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            '&&&': {
              borderRadius: '0.8rem',
              minHeight: '43px',

              '.MuiAutocomplete-input': {
                paddingLeft: '1rem',
                paddingRight: '3.5rem'
              }
            }
          }
        }
      },

      MuiFormGroup: {
        styleOverrides: {
          root: {
            '&&&': {
              marginBottom: '1.4rem'
            }
          }
        }
      },

      MuiPopper: {
        styleOverrides: {
          root: {
            '&&&': {
              zIndex: 99999
            }
          }
        }
      },

      MuiLink: {
        defaultProps: {
          underline: 'none'
        }
      },

      MuiCard: {
        styleOverrides: {
          root: {
            '&&&': {
              borderRadius: '1.4rem',
              boxShadow: 'none',
              border: `1px solid ${themeColors.invertColor.color4}`
            }
          }
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&&&': {
            border: '5px solid',
            '.MuiInputBase-root': {
              width: '100%',
              padding: 0
            }
          }
        }
      }
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
        sz_1600: 1600,
        sz_1800: 1800,
        sz_1900: 1900
      }
    }
  })

  theme = responsiveFontSizes(theme)
  return theme
}

export default createPrimaryTheme
