import axios from '../Utils/api';

const SettingsService = {
  getStorageSettings: async () => {
    return axios.get('/settings');
  },
  createStorageSettings: async(data) => {
    return axios.post('/settings', data);
  },
  getStorageSettingsById: (storageKey) => {
    return axios.get(`/settings/${storageKey}`);
  },

  updateStorage: (storageKey, storageData) => {
    return axios.put(`/settings/${storageKey}`, storageData);
  },

  deleteStorageSetting: (storageKey) => {
    return axios.delete(
      `/settings/${storageKey}`
    );
  }
};

export default SettingsService;
