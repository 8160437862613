import React, { useState } from 'react';
import { FormControl, IconButton, TextField, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { generatePassword, copyToClipboard } from '../Utils/dashboardHelper'; // Utility functions for generating and copying passwords

const PasswordFieldWithGenerator = (props) => {
  const { name, label, placeholder, onChange, error, helperText, value, setValue } = props;

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setValue(newPassword);
    onChange({ target: { name, value: newPassword } });
  };

  const handleCopyPassword = () => {
    copyToClipboard(value);
  };

  return (
    <div>
      <Typography component="label">{label}</Typography>
      <FormControl fullWidth>
        <TextField
          name={name}
          type="password"
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          helperText={helperText}
          value={value}
          disabled
          InputProps={{
            endAdornment: (
              <>
                <IconButton onClick={handleGeneratePassword} sx={{ color: '#fff' }}>
                  <AutorenewIcon />
                </IconButton>
                <IconButton onClick={handleCopyPassword} sx={{ color: '#fff' }}>
                  <ContentCopyIcon />
                </IconButton>
              </>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& input': {
                border: 'none',
              },
              '& fieldset': {
                borderColor: '#D9DDE6',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'green',
              },
            },
          }}
        />
      </FormControl>
    </div>
  );
};

export default PasswordFieldWithGenerator;
