import axios from '../Utils/api';

const ProfileService = {
  getProfileData: async (token) => {
    if (!token) {
      throw new Error('Token is required');
    }
    try {
      const url = `/profile`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile data:', error);
      throw error;
    }
  },
  uploadProfileImage: async (image, userId) => {
    try {
      const url = `/users/${userId}/upload`;
      const response = await axios.post(url, {file: image}, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading profile image:', error);
      throw error;
    }
  },
  resetPassword: async (userId, new_password) => {
    try {
      const url = `/users/${userId}/change-password`;
      const response = await axios.post(url, { new_password });
      return response.data;
    } catch (error) {
      console.error('Error changing profile password:', error);
      throw error;
    }
  },
};

export default ProfileService;
