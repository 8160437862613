import axios from '../Utils/api';

const PermissionService = {

  getPermissionList: () => {
    return axios.get(
      `/permissions`
    );
  }
};

export default PermissionService;
