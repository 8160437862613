import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthStyle } from '../../Layouts/style';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { RightArrow } from '../../Icons/SVGContainer';
import PasswordField from '../../Components/PasswordField';
import { Formik } from 'formik';
import { ChangePasswordValidationSchema } from '../../Utils/validators';
import useAuth from '../../Redux/Actions/authActions';
import { useNavigate } from 'react-router-dom';
const ChangePassword = () => {
  const { change_password, user, logout } = useAuth();
  const navigate = useNavigate();
  const ChangePasswordState = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const LogoutUser = () => {
    logout();
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await change_password(values);
      // Handle the response as needed
      if (response.data.changeTenantPassword) {
        navigate('/', { replace: true });
      }
      // Optionally, reset the form after successful password change
      resetForm();
    } catch (error) {
      // Handle error if needed
      console.error('Error during password change:', error);
    }
  };

  return (
    <AuthStyle>
      <Helmet>
        <title>Mirasys: Change Password</title>
      </Helmet>
      <Box className="signup_area">
        <Box className="signup_left">
          <img src="/images/logo.svg" className="logo" />
          <Box className="signup_left_pic"></Box>
        </Box>
        <Box className="signup_right">
          <Box className="sign_heading">
            <Typography component="h2">Change Password</Typography>
            <Typography>Please change your password here.</Typography>
          </Box>
          <Formik
            initialValues={ChangePasswordState}
            validationSchema={ChangePasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box className="form_box">
                  <PasswordField
                    label="Old Password"
                    name="old_password"
                    placeholder="Type old password"
                    onChange={props.handleChange}
                    error={
                      props.touched.old_password && !!props.errors.old_password
                    }
                    helperText={
                      props.touched.old_password
                        ? props.errors.old_password
                        : ''
                    }
                  />
                </Box>
                <Box className="form_box">
                  <PasswordField
                    label="New Password"
                    name="new_password"
                    placeholder="Type new password"
                    onChange={props.handleChange}
                    error={
                      props.touched.new_password && !!props.errors.new_password
                    }
                    helperText={
                      props.touched.new_password
                        ? props.errors.new_password
                        : ''
                    }
                  />
                </Box>
                <Box className="form_box">
                  <PasswordField
                    label="Confirm Password"
                    name="confirm_password"
                    placeholder="Type confirm password"
                    onChange={props.handleChange}
                    error={
                      props.touched.confirm_password &&
                      !!props.errors.confirm_password
                    }
                    helperText={
                      props.touched.confirm_password
                        ? props.errors.confirm_password
                        : ''
                    }
                  />
                </Box>
                <Box className="form_box">
                  <Button className="submitBtn" type="submit">
                    Submit
                    <RightArrow />
                  </Button>
                </Box>
                <Box className="form_box">
                  <Button className="submitBtn" onClick={LogoutUser}>
                    Logout
                    <RightArrow />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </AuthStyle>
  );
};

export default ChangePassword;
