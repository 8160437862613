import React, { useState } from 'react';
import { Button, Menu, MenuItem, TextField, Box, Typography, Select } from '@mui/material';
import { SearchIcon } from '../../../Icons/SVGContainer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SelectChangeEvent } from '@mui/material/Select';
import { FilterDropdownStyle } from '../../../Layouts/style';

interface FilterDropdownProps {
  onFilterChange: (filter: string) => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedKey, setSelectedKey] = useState('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    onFilterChange(event.target.value);
  };

  const handleKeyChange = (event: SelectChangeEvent<string>) => {
    setSelectedKey(event.target.value);
    onFilterChange(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-menu' : undefined;

  return (
      <FilterDropdownStyle>
        <Button variant="contained" color="info" onClick={handleClick} endIcon={<FilterListIcon />}>
          Key
        </Button>
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              minWidth: '610px', // Adjust width here
              backgroundColor: '#363F30',
              color: '#FFF',
              borderRadius: '12px',
              padding: '16px',
            },
          }}
        >
          <Box sx={{ padding: '16px' }} className="filter_heading">
          <Typography variant="h6" sx={{ mb: 2, color: '#FFF', fontWeight: 'bold', fontSize: '18px' }}>Filter</Typography>
            <Box className="filter_head_search">
              <TextField
                placeholder=" Search by key"
                fullWidth
                value={searchText}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Box>
            <Box sx={{ mt: 2}} className="filter_head_search">
              <Select
                value={selectedKey}
                onChange={handleKeyChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                <MenuItem value={'option1'}>Option 1</MenuItem>
                <MenuItem value={'option2'}>Option 2</MenuItem>
                <MenuItem value={'option3'}>Option 3</MenuItem>
              </Select>
            </Box>
          </Box>
        </Menu>
      </FilterDropdownStyle>
  );
};

export default FilterDropdown;
