import { StreamHub } from '.';

import { DashboardStyle } from '../../../Layouts/style';
import { StreamsProvider } from '../../../Contexts/useDeviceStreams';

export function Main() {
  return (
    <StreamsProvider>
      <DashboardStyle>
        <StreamHub />
      </DashboardStyle>
    </StreamsProvider>
  );
};
