import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import { useKeycloak } from '@react-keycloak/web'


const Login = () => {

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      const data = {
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        tokenParsed: keycloak.tokenParsed,
        idToken: keycloak.idToken,
        idTokenParsed: keycloak.idTokenParsed
      };

      // Optionally, store auth data in localStorage or sessionStorage
      // localStorage.setItem('authData', JSON.stringify(data));
      
    }
  }, [keycloak, initialized]);
  // const login = useCallback(() => {
  //   keycloak?.login()
  // }, [keycloak])

  return (
    <div>
      {/* <div>User is {!keycloak?.authenticated ? 'NOT ' : ''} authenticated</div>

      {!!keycloak?.authenticated && (
        <div>
        <h1>Welcome {keycloak.tokenParsed.preferred_username}</h1>
        <p>Email: {keycloak.tokenParsed.email}</p>
        <button type="button" onClick={() => keycloak.logout()}>
          Logout
        </button>
        </div>
      )}
      {!keycloak?.authenticated && (
        <button type="button" onClick={login}>
          Login
        </button>
      )} */}
    </div>
  )
}

export default Login