import React, { useEffect, useRef, useState } from 'react'
import { Skeleton } from '@mui/material'

import { useStreams } from '../../../Contexts/useDeviceStreams'

const DeviceOnlineShow = ({ deviceId, setShowInfo }) => {
  const [loading, setLoading] = useState(true)
  const { streams } = useStreams()
  const videoRef = useRef(null)

  useEffect(() => {
    streams.get(deviceId).then(s => {
      if (videoRef.current != null) {
        videoRef.current.srcObject = s
        setLoading(false)
        setTimeout(() => setShowInfo(true), 3000)
      }
    })
  }, [streams, videoRef])

  return (
    <>
      <div className="relative w-[400px]">
        {loading ? (
          <Skeleton
            sx={{ bgcolor: '#333', position: 'absolute', zIndex: 10 }}
            variant="circle"
            width="100%"
            height="100%"
          />
        ) : null}
        <video
          ref={videoRef}
          autoPlay
          muted
          controls={false}
          className="w-full h-full"
        />
      </div>
    </>
  )
}

export default DeviceOnlineShow
