import React from 'react';
import { Helmet } from 'react-helmet';
import { ForgotPasswordStyle } from '../../Layouts/style';
import { Box, Button, Typography } from '@mui/material';
import { RightArrow } from '../../Icons/SVGContainer';
import { Link } from 'react-router-dom';

const CheckEmail = () => {
  return (
    <ForgotPasswordStyle>
      <Helmet>
        <title>Mirasys: Check email</title>
      </Helmet>
      <Box className="forgot_pass_area">
        <Box className="forgot_box_con">
          <Box className="forgot_heading">
            <img src="/images/logo.svg" alt="" />
            <Typography component="h2">Check your email</Typography>
            <Typography>We sent a password reset link to your email</Typography>
          </Box>
          <form>
            <Box className="form_box">
              <Button className="submitBtn">
                Check email
                <RightArrow />
              </Button>
            </Box>
            <Box className="forgot_botinfo2">
              <Typography>
                Didn’t receive the email?{' '}
                <Link to="/forgot-password">Click to resend</Link>
              </Typography>
            </Box>
            <Box className="forgot_botinfo">
              <Typography>
                Back to <Link to="/login">Login</Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>
    </ForgotPasswordStyle>
  );
};

export default CheckEmail;
