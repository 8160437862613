import { RefObject } from 'react'
import { Box, Tabs, Tab, IconButton } from '@mui/material'
import { Plus, X } from 'lucide-react'

import { TabsState } from './all-interfaces'

interface TabsViewProps {
  value: number
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  tabs: TabsState
  handleChangeInput: (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleBlur: (name: string) => void
  handleKeyDown: (
    name: string,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void
  handleTabDoubleClick: (name: string) => void
  openTabModal: (name: string) => void
  a11yProps: (name: string) => {
    id: string
    'aria-controls': string
  }
  handleAddTab: () => void
  inputRefs: RefObject<(HTMLInputElement | null)[]>
}

export function TabsView({
  value,
  handleChange,
  tabs,
  handleChangeInput,
  handleBlur,
  handleKeyDown,
  handleTabDoubleClick,
  openTabModal,
  a11yProps,
  handleAddTab,
  inputRefs
}: TabsViewProps) {
  return (
    <Box
      className="dash_tab_btn flex relative items-center w-full"
      component="div"
    >
      <Tabs value={value} onChange={handleChange} aria-label="tabs">
        {tabs.map((tab, index) => (
          <Tab
            sx={{ textTransform: 'capitalize' }}
            className="group flex items-center"
            key={tab.name}
            label={
              tab.isEditing ? (
                <input
                  className="w-full absolute z-50 capitalize outline-none border-0 bg-[#272727] text-white px-3 py-2 "
                  maxLength={20}
                  value={tab.name}
                  onChange={e => handleChangeInput(tab.name, e)}
                  onBlur={() => handleBlur(tab.name)}
                  onKeyDown={e => handleKeyDown(tab.name, e)}
                  autoFocus
                  ref={el => {
                    if (inputRefs.current) {
                      inputRefs.current[index] = el
                    }
                  }}
                />
              ) : (
                <div
                  className="flex items-center justify-between w-full px-2"
                  onDoubleClick={() => handleTabDoubleClick(tab.name)}
                >
                  <span className="h-4">{tab.name || 'Item'}</span>
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 pl-2">
                    <X
                      size={13}
                      className="text-zinc-400"
                      onClick={() => openTabModal(tab.name)}
                    />
                  </div>
                </div>
              )
            }
            {...a11yProps(tab.name)}
          />
        ))}
      </Tabs>
      <div className="h-6 flex items-center">
        <IconButton
          onClick={handleAddTab}
          className="group "
          sx={{ padding: '4px' }}
        >
          <Plus
            size={13}
            className="text-zinc-500 p-0 group-hover:text-zinc-200 group-hover:scale-125 transition-all duration-150"
          />
        </IconButton>
      </div>
    </Box>
  )
}
