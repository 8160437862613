import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  DropDeleteIcon,
  DropEditIcon,
  DropRestartIcon,
  DropStartIcon,
  DropStopIcon,
} from '../../Icons/SVGContainer';
import { checkHasObjPermission } from '../../Utils/checkPermission';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ApplicationListDataTable = ({
  rows,
  headCells,
  columns,
  handleEdit,
  setClickedApplication,
  handleDeleteConfirm,
  handleClose,
}) => {
  const visibleRows = rows;
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
      >
        <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
        <TableBody>
          {visibleRows.map((row) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer' }}
              >
                {columns.map((col, index) => (
                  <TableCell align={index === 0 ? 'left' : 'right'} key={col}>
                    {col === 'action' ? (
                      <Box className="action_camera_icon">
                        {checkHasObjPermission(
                          row,
                          'application',
                          'edit_application'
                        ) && (
                          <IconButton
                            title="Edit"
                            onClick={() => {
                              setClickedApplication(row.hash_id);
                              handleEdit();
                            }}
                          >
                            <DropEditIcon />
                          </IconButton>
                        )}
                        {checkHasObjPermission(
                          row,
                          'application',
                          'delete_application'
                        ) && (
                          <IconButton
                            title="Delete"
                            onClick={() => {
                              setClickedApplication(row.hash_id);
                              handleDeleteConfirm();
                            }}
                          >
                            <DropDeleteIcon />
                          </IconButton>
                        )}
                        {checkHasObjPermission(
                          row,
                          'application',
                          'start_application'
                        ) && (
                          <IconButton
                            title="Start"
                            onClick={handleClose}
                            disabled
                          >
                            <DropStartIcon />
                          </IconButton>
                        )}
                        {checkHasObjPermission(
                          row,
                          'application',
                          'stop_application'
                        ) && (
                          <IconButton
                            title="Stop"
                            onClick={handleClose}
                            disabled
                          >
                            <DropStopIcon />
                          </IconButton>
                        )}
                        {checkHasObjPermission(
                          row,
                          'application',
                          'restart_application'
                        ) && (
                          <IconButton
                            title="Restart"
                            onClick={handleClose}
                            disabled
                          >
                            <DropRestartIcon />
                          </IconButton>
                        )}
                      </Box>
                    ) : col === 'status' ? (
                      'Not Configured'
                    ) : (
                      row[col]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApplicationListDataTable;
