import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import { Formik } from 'formik';
import {
  addUserValidationSchema,
  editUserValidationSchema
} from '../../Utils/validators';
import RoleService from '../../RestSevices/roleService';
import useAuth from '../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import {capitalizeFirstLetter, generateUsername} from '../../Utils/dashboardHelper';
import PasswordFieldWithGenerator from '../../Components/PasswordFieldWithGenerator';
import UserService from '../../RestSevices/userService';


const AddUserForm = ({ onClose, initialData = {}, fetchUsers }) => {
  const [addUserState, setAddUserState] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    image: '',
    password: '',
    roles: []
  });
  const [password, setPassword] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false); // Loading state
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const { logout } = useAuth();
  const fetchUserById = async (id) => {
    setLoading(true);
    try {
      const response = await UserService.getUserById(id);

      if (response.status === 200) {
        setLoading(false);
        setUserData(response.data);
        setAddUserState({
          username: response.data.username,
          email: response.data.email,
          firstname: response.data.first_name,
          lastname: response.data.last_name,
          image: response.data.image,
          password: '',
          roles: response.data.roles.map(role => role._id),
          status: response.data.status
        })
      }
    } catch (error) {
      if (error.response?.data?.error === 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error('Error fetching user data', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const handleSubmit = async(values, { resetForm }) => {
    // Handle form submission logic here
    try {
      if (initialData._id) {
        setTriggerUpdate(true);
        setUserData({
          ...userData,
          first_name: values.firstname,
          last_name: values.lastname,
          status: values.status,
          roles: [userRoles[userRoles.findIndex(role => role._id === values.roles[0])]]
        })
      }else{
        const newUser = {
          username: values.username,
          email: values.email,
          first_name: values.firstname,
          last_name: values.lastname,
          image: '',
          password: values.password,
          roles: [userRoles[userRoles.findIndex(role => role._id === values.roles[0])]]
        };
        setUserData(newUser);
        setTriggerUpdate(true);
      }
    } catch (error) {
      if (error.response?.data?.error === 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const fetchRoles = async () => {
    RoleService.getRoleList(page)
      .then((response) => {
        setUserRoles(response.data);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  useEffect(() => {
    if (triggerUpdate) {
      const updateRole = async () => {
        try {
          if (initialData._id) {
            const resp = await UserService.updateUser(initialData._id, userData);
            if (resp.status === 200) {
              toast.success('Role updated successfully!', {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchUsers();
              onClose();
            }
          } else {
            const response = await UserService.createUser(userData);
            if (response.status === 200) {
              toast.success('User created successfully!', {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchUsers();
              onClose();
            }
          }
        } catch (err) {
          if (err.response.status === 401) {
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            logout();
          } else {
            toast.error('Error in creating/updating user', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } finally {
          setTriggerUpdate(false);
        }
      };
      updateRole();
    }
  }, [triggerUpdate, userData, initialData._id, logout]);
  useEffect(() => {
    fetchRoles();
    if(initialData._id){
      fetchUserById(initialData._id);
    }
  }, []);

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
      {loading ? ( 
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : (
        <Formik
          initialValues={addUserState}
          validationSchema={!initialData._id ? addUserValidationSchema : editUserValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Box className="form_field_area">
                <Box className="form_box_hold">
                  <Box className="form_box_con">
                    <Typography component="label">First Name</Typography>
                    <TextField
                      name="firstname"
                      type="text"
                      value={props.values.firstname}
                      onChange={props.handleChange}
                      error={
                        props.touched.firstname && !!props.errors.firstname
                      }
                      helperText={
                        props.touched.firstname ? props.errors.firstname : ''
                      }
                    />
                  </Box>
                  <Box className="form_box_con">
                    <Typography component="label">Last Name</Typography>
                    <TextField
                      name="lastname"
                      type="text"
                      value={props.values.lastname}
                      onChange={props.handleChange}
                      error={
                        props.touched.lastname && !!props.errors.lastname
                      }
                      helperText={
                        props.touched.lastname ? props.errors.lastname : ''
                      }
                    />
                  </Box>
                </Box>
                <Box className="form_box_hold">
                  <Box className="form_box_con full_width">
                    <Typography component="label">Email</Typography>
                    <TextField
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={(e) => {
                        props.handleChange({
                          target: {
                            name: 'username',
                            value: generateUsername(e.target.value),
                          },
                        });
                        // Convert the entered email value to lowercase
                        const lowercasedEmail = e.target.value.toLowerCase();
                        // Call the Formik handleChange with the new lowercase value
                        props.handleChange({
                          target: {
                            name: 'email',
                            value: lowercasedEmail,
                          },
                        });
                      }}
                      error={props.touched.email && !!props.errors.email}
                      helperText={props.touched.email ? props.errors.email : ''}
                      disabled={initialData._id ? true : false}
                    />
                  </Box>
                  {initialData._id && props.values.status && (
                    <Box className="form_box_con">
                      <Typography component="label">Status</Typography>
                      <Select 
                        value={props.values.status} 
                        onChange={(e) => {
                          props.handleChange({
                            target: {
                              name: 'status',
                              value: e.target.value,
                            },
                          });
                        }} 
                        displayEmpty
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </Select>
                    </Box>
                  )}
                </Box>
                <Box className="form_box_hold">
                  {initialData._id && (
                    <Box className="form_box_con">
                      <Typography component="label">User Name</Typography>
                      <TextField
                        name="username"
                        type="text"
                        value={props.values.username}
                        disabled
                      />
                    </Box>
                  )}
                  <Box className="form_box_con full_width">
                    <Typography component="label">Role</Typography>
                    <Select
                      name="roles"
                      value={props.values.roles}
                      onChange={(e) => {
                        props.handleChange({
                          target: {
                            name: 'roles',
                            value: e.target.value !== '' ? [e.target.value] : [],
                          },
                        });
                      }}
                      error={props.touched.roles && !!props.errors.roles}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {userRoles.map((role) => (
                        <MenuItem value={role._id} key={role._id}>
                          {capitalizeFirstLetter(role.name)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      error={props.touched.roles && !!props.errors.roles}
                    >
                      {props.touched.roles ? props.errors.roles : ''}
                    </FormHelperText>
                  </Box>
                </Box>
                {!initialData._id && (
                  <Box className="form_box_con">
                    <PasswordFieldWithGenerator
                      label="Password"
                      name="password"
                      setValue={setPassword}
                      value={props.values.password}
                      onChange={(e) => {
                        props.handleChange({
                          target: {
                            name: 'password',
                            value: e.target.value,
                          },
                        });
                      }}
                      error={props.touched.password && !!props.errors.password}
                      helperText={
                        props.touched.password ? props.errors.password : ''
                      }
                    />
                </Box>
                )}
              </Box>
              <Box className="add_btn">
                <Button className="cancel_btn" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">
                  {initialData._id ? 'Update' : 'Add'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        )}
      </Box>
    </ModalLayoutStyle>
  );
};

export default AddUserForm;
