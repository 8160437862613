import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { StorageFormData } from '../../../types/storageSettings';

interface RenderFieldsByTypeProps {
  type: String | 'storage';
  formikProps: FormikProps<StorageFormData>;
}

const RenderFieldsByType: React.FC<RenderFieldsByTypeProps> = ({ type, formikProps }) => {
  const { values, handleChange, touched, errors } = formikProps;
  switch (type) {
    case 'storage':
      return (
        <>
          <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Type</Typography>
              <TextField
                name="values.type"
                type="text"
                value={values.values.type}
                onChange={handleChange}
                error={touched.values && touched.values.type && !!errors.values?.type}
                helperText={touched.values && touched.values.type ? errors.values?.type : ''}
              />
            </Box>
            <Box className="form_box_con">
              <Typography component="label">URI</Typography>
              <TextField
                name="values.uri"
                type="text"
                value={values.values.uri}
                onChange={handleChange}
                error={touched.values && touched.values.uri && !!errors.values?.uri}
                helperText={touched.values && touched.values.uri ? errors.values?.uri : ''}
              />
            </Box>
          </Box>
          <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Client ID</Typography>
              <TextField
                name="values.client_id"
                type="text"
                value={values.values.client_id}
                onChange={handleChange}
                error={touched.values && touched.values.client_id && !!errors.values?.client_id}
                helperText={touched.values && touched.values.client_id ? errors.values?.client_id : ''}
              />
            </Box>
            <Box className="form_box_con">
              <Typography component="label">Client Secret</Typography>
              <TextField
                name="values.client_secret"
                type="text"
                value={values.values.client_secret}
                onChange={handleChange}
                error={touched.values && touched.values.client_secret && !!errors.values?.client_secret}
                helperText={touched.values && touched.values.client_secret ? errors.values?.client_secret : ''}
              />
            </Box>
          </Box>
          <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Bucket</Typography>
              <TextField
                name="values.bucket"
                type="text"
                value={values.values.bucket}
                onChange={handleChange}
                error={touched.values && touched.values.bucket && !!errors.values?.bucket}
                helperText={touched.values && touched.values.bucket ? errors.values?.bucket : ''}
              />
            </Box>
          </Box>
        </>
      );
    default:
      return null;
  }
};

export default RenderFieldsByType;