import axios from '../Utils/api'

const UserService = {
  createUser: async data => {
    return axios.post('/users', data)
  },

  getUserList: (page, searchTerm, status) => {
    const parts =
      searchTerm &&
      searchTerm
        .trim()
        .split(/\s+/)
        .filter(part => part.trim() !== '')
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const emailCandidate = parts && parts.find(part => emailRegex.test(part))
    return axios.get(`/users`, {
      params: {
        page: page,
        limit: 10,
        email: emailCandidate ? searchTerm : '',
        first_name: emailCandidate ? '' : searchTerm,
        last_name: emailCandidate ? '' : searchTerm,
        status: status
      }
    })
  },

  getUserById: id => {
    return axios.get(`/users/${id}`)
  },

  updateUser: (userId, userData) => {
    return axios.put(`/users/${userId}`, userData)
  },

  deleteUser: userId => {
    return axios.delete(`/users/${userId}`)
  },

  updateTabs: async (userId, tabData) => {
    try {
      const userResponse = await axios.get(`/users/${userId}`)
      const userData = userResponse.data

      // Atualizar os tabs no userData
      const updatedTabs = { ...userData, tabs: tabData }

      // Enviar a atualização para o backend
      return axios.put(`/users/${userId}`, { ...updatedTabs })
    } catch (error) {
      console.error('Error adding tab:', error)
      throw error
    }
  },

  updateTabViews: async (userId, tabId, views) => {
    try {
      const userResponse = await axios.get(`/users/${userId}`)
      const userData = userResponse.data

      const updatedTabs = userData.tabs.map(tab =>
        tab.name === tabId ? { ...tab, views } : tab
      )

      // Enviar a atualização para o backend
      return axios.put(`/users/${userId}`, { ...userData, tabs: updatedTabs })
    } catch (error) {
      console.error('Error updating tab views:', error)
      throw error
    }
  }
}

export default UserService
