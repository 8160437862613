import toast from 'react-hot-toast'

export const toastSuccess = message =>
  toast.success(message, {
    iconTheme: {
      primary: '#364030',
      secondary: '#fff'
    },
    style: {
      background: '#fff',
      color: '#364030'
    }
  })

export const toastError = message =>
  toast.error(message, {
    iconTheme: {
      primary: '#eee',
      secondary: '#8C3A3A'
    },
    style: {
      background: '#8C3A3A',
      color: '#fff'
    }
  })
