import * as Yup from 'yup';

const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,18}$/;
const passwordSchema = Yup.string()
  .required('Password is required')
  .test('password-strength', 'Password is too weak', value => {
    if (!value) return false;

    // Define strength criteria
    const isStrong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(value);
    const isNormal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(value);
    const isWeak = value.length >= 6;

    if (isStrong) return true;
    if (isNormal) return true;
    if (isWeak) return true;

    return false;
  })
  .test('password-strength-type', 'Password must be at least weak', value => {
    if (!value) return false;

    // Define strength criteria
    const isStrong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(value);
    const isNormal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(value);
    const isWeak = value.length >= 6;

    return isStrong || isNormal || isWeak;
});
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const plainTextRegex = /^[A-Za-z\s]+$/;
const plainTextWithUnderscoreAndSpace = /^[a-zA-Z_]+(?:\s[a-zA-Z_]+)*$/;
const plainTextWithUnderScoreAndNumber = /^[a-zA-Z0-9_]+$/;

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(validRegex, 'Email cannot contain spaces at the beginning or end')
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
});

export const ChangePasswordValidationSchema = Yup.object({
  old_password: Yup.string()
    .min(6, 'Old Password must be at least 6 characters')
    .required('Old Password is Required'),
  new_password: Yup.string()
    .min(6, 'New Password must be at least 6 characters')
    .required('New Password is Required'),
  confirm_password: Yup.string()
    .required('Confirm Password is Required')
    .oneOf([Yup.ref('new_password')], 'Password must match'),
});

export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(validRegex, 'Email cannot contain spaces at the beginning or end')
    .email('Invalid email address')
    .required('Required'),
});

export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .matches(
      passwordRegex,
      'Password must have a capital letter, a small letter, a number, a symbol, and a length between 6 and 12 characters'
    )
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export const addUserValidationSchema = Yup.object({
  firstname: Yup.string()
    .required('First Name is Required')
    .matches(plainTextRegex, 'Invalid First Name'),
  lastname: Yup.string()
    .required('Last Name is Required')
    .matches(plainTextRegex, 'Invalid Last Name'),
  email: Yup.string()
    .required('Email is Required')
    .matches(emailRegex, 'Invalid email id'),
  roles: Yup.array()
  .of(Yup.string().required('Role ID is required'))
  .min(1, 'At least one role is required')
  .required('Roles are required'),
  password: Yup.string()
    .required('Password is Required')
});

export const editUserValidationSchema = Yup.object({
  firstname: Yup.string()
  .required('First Name is Required')
  .matches(plainTextRegex, 'Invalid First Name'),
  lastname: Yup.string()
  .required('Last Name is Required')
  .matches(plainTextRegex, 'Invalid Last Name'),
  roles: Yup.array()
  .of(Yup.string().required('Role ID is required'))
  .min(1, 'At least one role is required')
  .required('Roles are required'),
});

export const roleValidationSchema = Yup.object({
  name: Yup.string()
    .required('Required')
    // .matches(plainTextWithUnderscoreAndSpace, 'Invalid title')
    .min(3, 'Name must consist atleast 3 characters')
    .max(40, 'Name may consist max 40 characters'),
  // status: Yup.string().required('Required'),
});

export const dashboardSettingsValidationSchema = Yup.object({
  name: Yup.string()
    .required('Required')
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be at most 50 characters'),
  parameter: Yup.string().required('Required'),
  stats_type: Yup.string().required('Required'), // Check the field name and values
  chart: Yup.string().required('Required'),
  x_axis: Yup.string().when('chart', {
    is: 'bar_chart',
    then: () => Yup.string().required('required'),
    otherwise: () => Yup.string().nullable(true),
  }),
  y_axis: Yup.string().when('chart', {
    is: 'bar_chart', // Simplified condition as Yup will already check the value
    then: () => Yup.string().required('Required'),
    otherwise: () => Yup.string().nullable(true),
  }),
  chart_parameters: Yup.array().when('chart', {
    is: (value) => ['line_chart', 'pie_chart'].includes(value), // Check if value is in array
    then: () =>
      Yup.array()
        .min(1, 'Chart parameters must not be empty')
        .required('Chart parameters must not be empty'),
    otherwise: () => Yup.array(),
  }),
});

export const videoUploadValidationSchema = Yup.object({
  name: Yup.string()
    .required('Required')
    .matches(plainTextRegex, 'Invalid name')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must not exceed 100 characters'),
  description: Yup.string()
    .required('Required')
    .min(2, 'Description must be at least 2 characters')
    .max(200, 'Description must not exceed 200 characters'),
  file: Yup.mixed().required('File is required'),
  start_time: Yup.number().required('Required'),
});

export const addZoneValidator = Yup.object({
  zone_name: Yup.string()
    .required('Required')
    .matches(plainTextWithUnderScoreAndNumber, 'Invalid name'),
  color: Yup.string().required('Required'),
  application_id: Yup.string().required('Required'),
  analytics_id: Yup.string().required('Required'),
});


export const addDeviceValidator = Yup.object({
  name: Yup.string()
    .trim()
    .min(1, 'Device Name must be at least 1 character')
    .max(50, 'Device Name must be at most 50 characters')
    .required('Device Name is required'),
  url: Yup.string()
    .trim()
    // .matches(/^(rtsp:\/\/|http:\/\/|https:\/\/).+$/, 'Invalid URL format')
    .required('RTSP URL is required'),
  username: Yup.string().trim().required('Username is required'),
  password: passwordSchema
});

export const editProfileValidationSchema = Yup.object({
  firstname: Yup.string()
  .required('First Name is Required')
  .matches(plainTextRegex, 'Invalid First Name'),
  lastname: Yup.string()
  .required('Last Name is Required')
  .matches(plainTextRegex, 'Invalid Last Name'),
});