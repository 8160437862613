import React from 'react';
import { Box, Typography } from '@mui/material';
import { StorageSettings } from '../../types/storageSettings';

interface RenderFieldsByTypeProps {
  type: String | 'storage';
  initialData: StorageSettings;
}

const ViewStorageFieldsByType: React.FC<RenderFieldsByTypeProps> = ({ type, initialData }) => {
  const { values } = initialData;
  switch (type) {
    case 'storage':
      return (
        <>
          <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Type</Typography>
              <Typography component="p">
                {values.uri}
              </Typography>
            </Box>
            <Box className="form_box_con">
              <Typography component="label">URI</Typography>
              <Typography component="p" sx={{ wordBreak: 'break-all' }}>
                {values.uri}
              </Typography>
            </Box>
          </Box>
          <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Client ID</Typography>
              <Typography component="p" sx={{ wordBreak: 'break-all' }}>
                {values.client_id}
              </Typography>
            </Box>
            <Box className="form_box_con">
              <Typography component="label">Client Secret</Typography>
              <Typography component="p" sx={{ wordBreak: 'break-all' }}>
                {values.client_secret}
              </Typography>
            </Box>
          </Box>
          <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Bucket</Typography>
              <Typography component="p" sx={{ wordBreak: 'break-all' }}>
                {values.bucket}
              </Typography>
            </Box>
          </Box>
        </>
      );
    default:
      return null;
  }
};

export default ViewStorageFieldsByType;