import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Pagination, Stack } from '@mui/material';
import dayjs from 'dayjs';
import io from 'socket.io-client';
import { UserStyle } from '../../../Layouts/style';
import AlertHeading from './AlertsHeading';
import AlertsDataTable from '../../../Components/DataTable/AlertsDataTable';
import AnalyticsService from '../../../RestSevices/analyticsService';
import AlertsService from '../../../RestSevices/alertService';
import { toast } from 'react-toastify';
import useAuth from '../../../Redux/Actions/authActions';

const Alerts = () => {
  const [loading, setLoading] = useState(false);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [analyticsSelection, SetAnalyticsSelection] = useState('');
  const [alerts, setAlerts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const [serachTerm, setSearchTerm] = useState('');
  const [displayFields, setDisplayFields] = useState([]);

  const { logout } = useAuth();

  useEffect(() => {
    const apiUrl = `http://mirasys_api_tenant_gateway.localhost`;
    const socket = io(apiUrl);

    // Handle socket connection
    socket.on('connect', () => {
      console.log('Socket connected');
    });

    // Handle socket disconnection
    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Handle socket errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    socket.on('alert_created', (data) => {
      let dateFilter;
      if (!fromDate && !toDate) {
        dateFilter = null;
      }
      if (dateFilter === null && serachTerm === '') {
        setAlerts((prevAlerts) => [...prevAlerts, data]);
      }
    });

    return () => {
      console.log('Socket disconnect');
      socket.disconnect();
    };
  }, [serachTerm, filterDateStart, filterDateEnd]);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  useEffect(() => {
    fetchAlerts();
  }, [analyticsSelection, page, serachTerm, filterDateStart, filterDateEnd]);

  const handleAnalyticsSelection = (value) => {
    const analyticName = value;
    if (analyticName) {
      const analytic = analyticsList.find(
        (analytic) => analytic.analytic_name === analyticName
      );
      SetAnalyticsSelection(analyticName);
      AnalyticsService.getAnalyticsPostFields(analytic.analytic_id).then(
        (resp) => {
          setDisplayFields(resp.data.data);
        }
      );
    } else {
      SetAnalyticsSelection('');
      setAlerts([]);
      setTotalPages(0);
      setDisplayFields([]);
    }
  };

  const fetchAlerts = () => {
    if (!filterDateStart && !filterDateEnd && analyticsSelection) {
      const dateFilter = null;
      let search = null;
      if (serachTerm) {
        search = serachTerm;
      }
      AlertsService.getAlerts({
        analyticsSelection,
        page,
        dateFilter: dateFilter,
        search,
      })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
    if (
      filterDateStart &&
      filterDateEnd &&
      dayjs(filterDateStart).isBefore(filterDateEnd) &&
      analyticsSelection
    ) {
      const dateFilter = {
        start: filterDateStart ? new Date(filterDateStart).getTime() : null,
        end: filterDateEnd ? new Date(filterDateEnd).getTime() : null,
      };
      let search = null;
      if (serachTerm) {
        search = serachTerm;
      }
      setLoading(true);
      AlertsService.getAlerts({ analyticsSelection, page, search, dateFilter })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
  };

  const fetchAnalytics = () => {
    try {
      AnalyticsService.getAllAnalyticsList()
        .then((resp) => {
          setAnalyticsList(resp.data);
        })
        .catch((error) => {
          if (error.response?.data?.error === 'Authentication failed') {
            logout();
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(new Date(date));
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateStart(formattedDate);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateEnd(formattedDate);
  };

  const handleOnClickSearch = () => {
    setPage(1);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const resetFilter = () => {
    setToDate(null);
    setFromDate(null);
    setFilterDateStart(null);
    setFilterDateEnd(null);
    setSearchTerm('');
    fetchAlerts();
  };

  const columns = ['thumbnailUrl', 'analyticType', 'cameraName', 'zoneName'];
  const columnsToDisplay = columns;
  displayFields.forEach((field) => {
    if (
      field.field_name !== 'thumb_url' &&
      field.field_name !== 'image_url' &&
      field.is_displayed === 1
    ) {
      columnsToDisplay.push(field.field_name);
    }
  });
  const headCells = [
    {
      id: 'thumbnailUrl',
      numeric: false,
      disablePadding: true,
      label: 'Thumbnail URL',
    },
    {
      id: 'analyticType',
      numeric: false,
      disablePadding: true,
      label: 'Analytic Type',
    },
    {
      id: 'cameraName',
      numeric: false,
      disablePadding: true,
      label: 'Camera Name',
    },
    {
      id: 'zoneName',
      numeric: false,
      disablePadding: true,
      label: 'Zone Name',
    },
  ];
  const columnHeads = headCells;
  displayFields.forEach((field) => {
    if (
      field.field_name !== 'thumb_url' &&
      field.field_name !== 'image_url' &&
      field.is_displayed === 1
    ) {
      columnHeads.push({
        id: field.field_name,
        numeric: false,
        disablePadding: true,
        label: field.field_name,
      });
    }
  });
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Alerts</title>
      </Helmet>
      <Box className="user_area">
        <AlertHeading
          analytics={analyticsList}
          handleAnalyticsSelection={handleAnalyticsSelection}
          analyticsSelection={analyticsSelection}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          fromDate={fromDate}
          toDate={toDate}
          serachTerm={serachTerm}
          handleSearch={handleSearch}
          resetFilter={resetFilter}
          handleOnClickSearch={handleOnClickSearch}
        />
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <AlertsDataTable
            rows={alerts}
            headCells={columnHeads}
            columns={columnsToDisplay}
            analyticsSelection={analyticsSelection}
          />
        )}
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </Box>
    </UserStyle>
  );
};

export default Alerts;
