import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
// import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ModalLayoutStyle } from '../../Layouts/style';
import DeviceService from '../../RestSevices/deviceService';
import useAuth from '../../Redux/Actions/authActions';
import { addDeviceValidator } from '../../Utils/validators';
import PasswordField from '../../Components/PasswordField';

const EditDeviceForm = ({ onClose, fetchData, deviceForEdit }) => {
  const [hashId, setHashId] = useState(deviceForEdit?.id || '');
  // const newCameraId = uuidv4();
  const { logout } = useAuth();

  const formik = useFormik({
    initialValues: {
      name: deviceForEdit?.name || '',
      url: deviceForEdit?.url || '',
      username: deviceForEdit?.username || '',
      password: deviceForEdit?.password || '',
    },
    validationSchema: addDeviceValidator,
    onSubmit: async (values) => {
      try {
        if (hashId) {
          const data = {
            ...values
          };
          const result = await DeviceService.updateDevice(hashId, data);
          if (result.status === 200) {
            fetchData();
            onClose();
            toast.success('Device updated successfully!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          const result = await DeviceService.createDevice(
            values
          );
          if (result.status === 200) {
            fetchData();
            onClose();
            toast.success('Device created successfully!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          logout();
        } else {
          toast.error('Error in creating device', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
  });

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body" component={'div'}>
        <form onSubmit={formik.handleSubmit}>
          <Box className="form_field_area">
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Device Name</Typography>
                <TextField
                  name="name"
                  placeholder="Enter Device Name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name && formik.errors.name
                  }
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Rtsp Url</Typography>
                <TextField
                  name="url"
                  placeholder="Enter Administratior"
                  value={formik.values.url || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.url && Boolean(formik.errors.url)
                  }
                  helperText={formik.touched.url && formik.errors.url}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Username</Typography>
                <TextField
                  name="username"
                  placeholder="Enter Username"
                  value={formik.values.username || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con password_holder">
                <PasswordField
                  label="Password"
                  name="password"
                  placeholder="Enter Password"
                  value={formik.values.password || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.password && !!formik.errors.password}
                  helperText={
                    formik.touched.password ? formik.errors.password : ''
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className="add_btn">
            <Button className="cancel_btn" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{hashId ? 'Update' : 'Add'}</Button>
          </Box>
        </form>
      </Box>
    </ModalLayoutStyle>
  );
};

export default EditDeviceForm;
