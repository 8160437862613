import { Box, Button } from '@mui/material';
import PasswordField from '../../Components/PasswordField';
import { Formik, FormikHelpers } from 'formik';
import { ChangePasswordValidationSchema } from '../../Utils/validators';
import { ModalLayoutStyle } from '../../Layouts/style';
import ProfileService from '../../RestSevices/profileService';
import useAuth from '../../Redux/Actions/authActions';
import { toast } from 'react-toastify';

// Define the structure of the form state
interface ChangePasswordState {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

const ResetPassword = ({ onClose }) => {

  const { user } = useAuth();
  const initialState: ChangePasswordState = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const handleSubmit = async (
    values: ChangePasswordState,
    { resetForm }: FormikHelpers<ChangePasswordState>
  ) => {
    await ProfileService.resetPassword(user._id, values.new_password).then((response: any) => {
      toast.success(response, {
        position: toast.POSITION.TOP_RIGHT,
      });
      resetForm();
      onClose();
    }).catch((error: any) => {
      if (error.response.data.error === 'Authentication failed') {
        toast.error('Session expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        onClose();
      }
    })
  };

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
      <Formik
        initialValues={initialState}
        validationSchema={ChangePasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Box className="form_field_area">
              <Box className="form_box_hold">
                <Box className="form_box_con">
                  <PasswordField
                    label="Old Password"
                    name="old_password"
                    onChange={props.handleChange}
                    error={
                      props.touched.old_password && !!props.errors.old_password
                    }
                    helperText={
                      props.touched.old_password
                        ? props.errors.old_password
                        : ''
                    }
                  />
                </Box>
              </Box>
              <Box className="form_box_hold">
                <Box className="form_box_con">
                  <PasswordField
                    label="New Password"
                    name="new_password"
                    onChange={props.handleChange}
                    error={
                      props.touched.new_password && !!props.errors.new_password
                    }
                    helperText={
                      props.touched.new_password
                        ? props.errors.new_password
                        : ''
                    }
                  />
                </Box>
              </Box>
              <Box className="form_box_hold">
                <Box className="form_box_con">
                  <PasswordField
                    label="Confirm Password"
                    name="confirm_password"
                    onChange={props.handleChange}
                    error={
                      props.touched.confirm_password &&
                      !!props.errors.confirm_password
                    }
                    helperText={
                      props.touched.confirm_password
                        ? props.errors.confirm_password
                        : ''
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box className="add_btn">
              <Button className="cancel_btn" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    </ModalLayoutStyle>
  );
};

export default ResetPassword;
