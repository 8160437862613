import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../Redux/Slice/auth';
import { 
  Paper, 
  Divider, 
  Box, 
  Typography, 
  Avatar, 
  Checkbox, 
  Button, 
  FormControlLabel,
  TextField,
  } from '@mui/material';
import { Formik } from 'formik';
import {
  editProfileValidationSchema
} from '../../../Utils/validators';
import AvatarUpload from '../../../Components/ImageUpload/ProfileAvatar';
import useAuth from '../../../Redux/Actions/authActions';
import moment from 'moment/moment';
import BaseModal from '../../../Components/Modal/BaseModal';
import ResetPassword from '../../../Components/Form/ResetPassword';
import ProfileService from '../../../RestSevices/profileService';
import { toast } from 'react-toastify';
import { base64ToUrl } from '../../../Utils/dashboardHelper';

const UserProfile = () => {
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [profileUserState, setProfileUserState] = useState({
    email: '',
    firstname: '',
    lastname: '',
    image: '',
  });
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAuth();
  const token = useSelector((state: AuthState) => state.auth.token); // Assuming the token is stored in `auth` reducer.

  const getUserData = async() => {
    if(token){
      await ProfileService.getProfileData(token).then((response: any) => {
        setProfileUserState({
          ...profileUserState,
          email: response?.email || '',
          firstname: response?.first_name || '',
          lastname: response?.last_name || '',
          image: response?.image || '',
        });
        setProfileImage(response?.image);
      })
    }
  }

  const handleImageCropped = async(croppedImage: string | null) => {
    // console.log(croppedImage);
    // console.log(profileImageFile);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    getUserData();
  }

  const getProfileImageFile = async(profileImage: File | null) => {
    if(profileImage){
      await ProfileService.uploadProfileImage(profileImage, user?._id).then((response: any) => {
        toast.success(response, {
          position: toast.POSITION.TOP_RIGHT
        })
      }).catch((error: any) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }
  }

  const handleSubmit = (values: any) => {
    handleEditClick();
    // try {
    //   console.log(values);
    //   // Handle form submission logic here

    // } catch (error) {
    //   console.error(error);
    // }
  }
  useEffect(() => {
    if (user) {
      getUserData();
      // setProfileUserState({
      //   email: user.email,
      //   firstname: user.first_name,
      //   lastname: user.last_name,
      //   image: user.image,
      // });
      // setProfileImage(base64ToUrl(user.image));
    }
  }, [user]);
  return (
    <>
    {isEditing ? (
      <Paper
        elevation={3}
        sx={{
          backgroundColor: '#2F3E2C',
          borderRadius: '10px',
          color: '#FFFFFF',
          width: '100%',
          margin: 'auto auto 30px auto',
        }}
      >
        <Box sx={{ padding: '35px' }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: '30px', fontSize: '18px', fontWeight: 'bold', color: '#FFFFFF' }}
          >
            Personal Info
          </Typography>
          <Formik
            initialValues={profileUserState}
            validationSchema={editProfileValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <AvatarUpload 
                onImageCropped={handleImageCropped}
                getImageFile={getProfileImageFile}
                initialImage={profileImage}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '25px', marginTop: '30px' }}>
                <Box sx={{ width: '20%' }}>
                  <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase', marginBottom: '5px' }}>
                    First Name
                  </Typography>
                  <TextField
                    name="firstname"
                    type="text"
                    value={props.values.firstname}
                    onChange={props.handleChange}
                    error={
                      props.touched.firstname && !!props.errors.firstname
                    }
                    helperText={
                      props.touched.firstname ? props.errors.firstname : ''
                    }
                  />
                </Box>
                <Box sx={{ width: '20%' }}>
                  <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase', marginBottom: '5px' }}>
                    Last Name
                  </Typography>
                  <TextField
                    name="lastname"
                    type="text"
                    value={props.values.lastname}
                    onChange={props.handleChange}
                    error={
                      props.touched.lastname && !!props.errors.lastname
                    }
                    helperText={
                      props.touched.lastname ? props.errors.lastname : ''
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                <Box sx={{ width: '20%' }}>
                  <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase', marginBottom: '5px' }}>
                    Email
                  </Typography>
                  <TextField
                    name="email"
                    type="text"
                    value={props.values.email}
                    disabled
                    sx={{
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: 'lightgray', // Label color when input is disabled
                      },
                    }}
                  />
                </Box>
              </Box>
              <Divider sx={{ backgroundColor: '#555555', marginBottom: '25px' }} />
              {/* Notification Info Section */}
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: '35px', fontSize: '23px', fontWeight: 'bold', color: '#FFFFFF' }}
              >
                Notification Info
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase' }}>
                Notification Type
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '35px' }}>
                <Box>
                  <FormControlLabel control={<Checkbox  />} label="People Movement Detection" sx={{ textTransform: 'uppercase'}}/>
                </Box>
                <Box>
                  <FormControlLabel control={<Checkbox  />} label="Vehicle Movement Detection" sx={{ textTransform: 'uppercase'}}/>
                </Box>
              </Box>
              {/* Action Buttons */}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#4B5148', // Set to black
                  color: '#FFFFFF',
                  padding: '8px 45px',
                  '&:hover': {
                    backgroundColor: '#fff', // A slightly lighter black for hover effect
                    color: '#000',
                  },
                  marginRight: '15px',
                }}
                onClick={handleEditClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type='submit'
                sx={{
                  backgroundColor: '#fff',
                  color: '#000',
                  padding: '8px 55px',
                  '&:hover': {
                    backgroundColor: '#4B5148',
                    color: '#fff',
                  },
                }}
              >
                Save
              </Button>
            </form>
          )}
          </Formik>
        </Box>
      </Paper>
    ): (
      <>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#2F3E2C',
            borderRadius: '10px',
            color: '#FFFFFF',
            width: '100%',
            margin: 'auto auto 30px auto',
          }}
          >
          {/* Header */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '35px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={base64ToUrl(profileUserState.image) || ''}
                sx={{
                  width: 95,
                  height: 95,
                  marginRight: '16px',
                  backgroundColor: '#A0A0A0',
                }}
              />
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: '20px', fontWeight: 'bold', color: '#FFFFFF', textTransform: 'uppercase' }}
                >
                  {profileUserState?.firstname}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: '20px', fontWeight: 'bold', color: '#FFFFFF', textTransform: 'uppercase' }}
                >
                  {profileUserState?.lastname}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase' }}>
                  Username
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                  {user?.username}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ textAlign: 'right', fontSize: '14px', color: '#A0A0A0', textTransform: 'uppercase' }}>
                Created on
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'right', fontSize: '14px', color: '#fff' }}>
                {moment(user?.created_at).format('MMMM Do, YYYY')}
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#2F3E2C',
            borderRadius: '10px',
            color: '#FFFFFF',
            width: '100%',
            margin: 'auto',
          }}
          >
          <Box sx={{ padding: '35px' }}>
            {/* Personal Info Section */}
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: '35px', fontSize: '23px', fontWeight: 'bold', color: '#FFFFFF' }}
            >
              Personal Info
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
              <Box sx={{ width: '20%' }}>
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase' }}>
                  First Name
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff', fontSize: '17px' }}>
                  {user?.first_name}
                </Typography>
              </Box>
              <Box sx={{ width: '20%' }}>
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase' }}>
                  Last Name
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff', fontSize: '17px' }}>
                  {user?.last_name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
              <Box sx={{ width: '20%' }}>
                <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase' }}>
                  Email
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff', fontSize: '17px' }}>
                  {user?.email}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ backgroundColor: '#555555', marginBottom: '25px' }} />
            {/* Notification Info Section */}
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: '35px', fontSize: '23px', fontWeight: 'bold', color: '#FFFFFF' }}
            >
              Notification Info
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#A0A0A0', textTransform: 'uppercase' }}>
              Notification Type
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '35px' }}>
              <Box>
                <FormControlLabel 
                  control={<Checkbox  />} 
                  label="People Movement Detection"  
                  disabled 
                  sx={{ 
                    textTransform: 'uppercase',
                    '& .MuiFormControlLabel-label': {
                      color: 'gray', // Default label color
                    },
                    '&.Mui-disabled': {
                      '& .MuiFormControlLabel-label': {
                        color: 'lightgray', // Label color when checkbox is disabled
                      },
                    },
                  }}
                />
              </Box>
              <Box>
                <FormControlLabel 
                  control={<Checkbox  />} 
                  label="Vehicle Movement Detection" 
                  disabled 
                  sx={{ 
                    textTransform: 'uppercase',
                    '& .MuiFormControlLabel-label': {
                      color: 'gray', // Default label color
                    },
                    '&.Mui-disabled': {
                      '& .MuiFormControlLabel-label': {
                        color: 'lightgray', // Label color when checkbox is disabled
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            {/* Action Buttons */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#000000', // Set to black
                color: '#FFFFFF',
                padding: '8px 45px',
                '&:hover': {
                  backgroundColor: '#fff', // A slightly lighter black for hover effect
                  color: '#000',
                },
                marginRight: '15px',
              }}
              onClick={() => setResetPasswordModalOpen(true)}
            >
              Reset Password
            </Button>
            <Button
              variant="contained"
              onClick={handleEditClick}
              sx={{
                backgroundColor: '#fff',
                color: '#000',
                padding: '8px 55px',
                '&:hover': {
                  backgroundColor: '#000',
                  color: '#fff',
                },
              }}
            >
              Edit
            </Button>
            {/* Footer */}
            <Typography
              variant="body2"
              sx={{ padding: '16px', fontSize: '12px', color: '#A0A0A0', textAlign: 'left', paddingLeft: '0' }}
            >
              Last updated on - {moment(user?.updated_at).format('MMMM Do, YYYY')}
            </Typography>
          </Box>
        </Paper>
        <BaseModal 
          open={resetPasswordModalOpen}
          onClose={() => setResetPasswordModalOpen(false)}
          title={'Reset Password'}
        >
          <ResetPassword 
            onClose={() => setResetPasswordModalOpen(false)}
          />
        </BaseModal>
      </>
    )}
    
    </>
  );
};

export default UserProfile;
