import { Hidden, styled } from '@mui/material'
import { Dialog } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import { heIL } from '@mui/x-date-pickers'

export const AuthStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none'
  },
  '.MuiInputBase-root': {
    minHeight: '0 !important',
    minWidth: '0 !important'
  },
  '.signup_area': {
    background: theme.colors.color.color3,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    '.signup_left': {
      height: '100%',
      padding: '10rem' /* converted from '160px' */,
      flex: 1,
      background: theme.colors.color.color6,
      position: 'relative',
      '.logo': {
        position: 'absolute',
        top: '1.25rem' /* converted from '20px' */,
        left: '2.5rem' /* converted from '40px' */
      },
      '.signup_left_pic': {
        width: '100%',
        height: '100%',
        background: theme.colors.color.color3
      }
    },
    '.signup_right': {
      height: '100%',
      padding: '9.375rem 8.75rem' /* converted from '150px 140px' */,
      flex: 1,
      overflow: 'auto',
      position: 'relative',
      '.sign_toplink': {
        position: 'absolute',
        right: '8.75rem' /* converted from '140px' */,
        top: '3.125rem' /* converted from '50px' */,
        '& a': {
          color: theme.colors.invertColor.color1,
          fontWeight: 600
        }
      },
      '.sign_heading': {
        textAlign: 'center',
        paddingBottom: '5rem' /* converted from '80px' */,
        '& h2': {
          fontSize: '1.875rem' /* converted from '30px' */,
          fontWeight: 600,
          marginBottom: '0.4375rem' /* converted from '7px' */
        },
        '& p': {
          fontSize: '1.5rem' /* converted from '24px' */
        }
      },
      '.form_box_grid': {
        display: 'flex',
        alignItems: 'start',
        gap: '1.5625rem' /* converted from '25px' */
      },
      '.form_box': {
        marginBottom: '1.5625rem' /* converted from '25px' */,
        flex: 1,
        '& label': {
          fontSize: '1.125rem' /* converted from '18px' */,
          marginBottom: '0.375rem' /* converted from '6px' */,
          display: 'block',
          paddingLeft: '0.3125rem' /* converted from '5px' */
        },
        '.MuiInputBase-root': {
          background: theme.colors.color.color6
        },
        '& input': {
          background: theme.colors.color.color6,
          height: '2.1875rem' /* converted from '35px' */,
          borderRadius: '0.625rem' /* converted from '10px' */
        },
        '.MuiSelect-select': {
          background: theme.colors.color.color6,
          height: '2.1875rem' /* converted from '35px' */,
          borderRadius: '0.625rem' /* converted from '10px' */,
          display: 'flex',
          alignItems: 'center'
        },
        '.MuiSelect-icon': {
          color: theme.colors.invertColor.color1
        },
        '.check_con': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '0.625rem' /* converted from '10px' */
        },
        '.checkbox_con': {
          display: 'flex',
          alignItems: 'center',
          '& label': {
            margin: 0
          },
          '& input': {
            height: 'auto',
            marginRight: '0.3125rem' /* converted from '5px' */
          }
        },

        '.forget_pass': {
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: '0.625rem' /* converted from '10px' */,
          '& a': {
            color: theme.colors.invertColor.color1
          }
        },
        '& button.submitBtn': {
          width: '100%',
          background: theme.colors.invertColor.color2,
          color: theme.colors.color.color18,
          position: 'relative',
          height: '3.75rem' /* converted from '60px' */,
          borderRadius: '0.625rem' /* converted from '10px' */,
          marginTop: '1.5625rem' /* converted from '25px' */,
          fontSize: '1.25rem' /* converted from '20px' */,
          '& svg': {
            position: 'absolute',
            right: '1.25rem' /* converted from '20px' */,
            top: '1.125rem' /* converted from '18px' */
          }
        },
        '& button.googleBtn': {
          width: '100%',
          background: theme.colors.color.color18,
          color: theme.colors.invertColor.color1,
          position: 'relative',
          height: '3.75rem' /* converted from '60px' */,
          borderRadius: '0.625rem' /* converted from '10px' */,
          fontSize: '1.25rem' /* converted from '20px' */,
          '& img': {
            marginRight: '0.625rem' /* converted from '10px' */
          },
          '& svg': {
            position: 'absolute',
            right: '1.25rem' /* converted from '20px' */,
            top: '1.125rem' /* converted from '18px' */
          }
        }
      },
      '.form_checkbox': {
        marginTop: '1.25rem' /* converted from '20px' */,
        '& label': {
          display: 'flex',
          alignItems: 'start',
          gap: '0.625rem' /* converted from '10px' */,
          fontSize: '0.875rem' /* converted from '14px' */,
          '& span': {
            padding: 0,
            '& svg': {
              width: '1.625rem' /* converted from '26px' */,
              height: '1.625rem' /* converted from '26px' */,
              color: theme.colors.invertColor.color1
            }
          }
        }
      },
      '.streanth_info_area': {
        paddingTop: '0.625rem' /* converted from '10px' */,
        '.streanth_box': {
          display: 'flex',
          alignItems: 'center',
          gap: '0.625rem' /* converted from '10px' */,
          '& div': {
            borderRadius: '2.125rem' /* converted from '34px' */,
            border:
              '0.0625rem solid rgba(255, 255, 255, 0.25)' /* converted from '1px' */,
            background: theme.colors.color.color6,
            width: '8.75rem' /* converted from '140px' */,
            height: '0.4375rem' /* converted from '7px' */
          }
        },
        '.week_pass_msg': {
          display: 'block',
          color: theme.colors.error,
          marginTop: '0.625rem' /* converted from '10px' */,
          fontStyle: 'italic'
        },
        '.streanth_info_area': {
          '& ul': {
            padding: '0 0 0 1rem' /* converted from '16px' */,
            margin: 0,
            '& li': {
              marginTop: '0.625rem' /* converted from '10px' */,
              fontStyle: 'italic'
            }
          }
        }
      }
    }
  },
  '@media (max-width: 1600px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '7.5rem 6.25rem' /* converted from '120px 100px' */,
        '.sign_toplink': {
          right: '6.25rem' /* converted from '100px' */
        },
        '.sign_heading': {
          paddingBottom: '3.125rem' /* converted from '50px' */
        }
      },
      '.signup_left': {
        padding: '7.5rem 6.25rem' /* converted from '120px 100px' */
      }
    }
  },
  '@media (max-width: 1200px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '7.5rem 3.75rem' /* converted from '120px 60px' */,
        '.form_box_grid': {
          flexDirection: 'column',
          gap: 0,
          '& div': {
            flex: 1,
            minWidth: '100%'
          }
        },
        '.sign_toplink': {
          right: '3.75rem' /* converted from '60px' */
        }
      },
      '.signup_left': {
        padding: '7.5rem 3.75rem' /* converted from '120px 60px' */
      }
    }
  },
  '@media(max-width: 992px)': {
    '.sign_heading': {
      '& h2': {
        fontSize: '1.5rem !important' /* converted from '24px' */
      },
      '& p': {
        fontSize: '1.125rem !important' /* converted from '18px' */
      }
    },
    '.sign_toplink': {
      right: '1.875rem !important' /* converted from '30px' */
    },
    '.signup_area': {
      '.signup_right': {
        padding: '7.5rem 1.875rem' /* converted from '120px 30px' */
      },
      '.signup_left': {
        padding: '7.5rem 3.125rem' /* converted from '120px 50px' */
      }
    },
    '& button.googleBtn': {
      fontSize: '1.125rem !important' /* converted from '18px' */,
      '& svg': {
        width: '1rem' /* converted from '16px' */
      }
    },
    '& button.submitBtn': {
      fontSize: '1.125rem !important' /* converted from '18px' */,
      '& svg': {
        width: '1rem' /* converted from '16px' */
      }
    }
  },
  '@media(max - width: 767px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '5rem 1.875rem 2.5rem' /* converted from '80px 30px 40px' */,
        '.sign_heading': {
          paddingBottom: '1.875rem' /* converted from '30px' */
        }
      },
      '.signup_left': {
        display: 'none'
      }
    },
    '.sign_toplink': {
      top: '1.875rem !important' /* converted from '30px' */
    },
    '& input': {
      height: '1.5625rem !important' /* converted from '25px' */
    },
    '.MuiSelect-select': {
      height: '1.5625rem !important' /* converted from '25px' */
    }
  }
}))

export const ForgotPasswordStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none'
  },
  '.MuiInputBase-root': {
    minHeight: '0 !important',
    minWidth: '0 !important'
  },
  '.forgot_pass_area': {
    background: theme.colors.color.color3,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    paddingTop: '3.75rem',
    paddingBottom: '1.875rem',
    overflow: 'auto',
    justifyContent: 'center',
    '.forgot_box_con': {
      width: '46.875rem',
      borderRadius: '1.5625rem',
      background: theme.colors.color.color6,
      padding: '2.5rem 4.375rem 4.375rem'
    },
    '.forgot_heading': {
      textAlign: 'center',
      paddingBottom: '2.8125rem',
      '& h2': {
        fontSize: '1.75rem',
        fontWeight: 600,
        marginBottom: '0.4375rem',
        marginTop: '2.8125rem'
      },
      '& p': {
        fontSize: '1.25rem'
      }
    },
    '.form_box': {
      marginBottom: '1.5625rem',
      flex: 1,
      '& label': {
        fontSize: '1.125rem',
        marginBottom: '0.375rem',
        display: 'block',
        paddingLeft: '0.3125rem'
      },
      '& input': {
        background: theme.colors.color.color3,
        height: '2.1875rem',
        borderRadius: '0.625rem'
      },
      '.MuiInputBase-root': {
        background: theme.colors.color.color3
      },
      '& button.submitBtn': {
        width: '100%',
        background: theme.colors.invertColor.color1,
        color: theme.colors.color.color18,
        position: 'relative',
        height: '3.75rem',
        borderRadius: '0.625rem',
        marginTop: '0.625rem',
        fontSize: '1.25rem',
        '& svg': {
          position: 'absolute',
          right: '1.25rem',
          top: '1.125rem'
        }
      }
    },
    '.forgot_botinfo2': {
      '& p': {
        textAlign: 'center',
        fontSize: '1.375rem',
        '& a': {
          fontWeight: 700,
          color: theme.colors.invertColor.color1
        }
      }
    },
    '.forgot_botinfo': {
      paddingTop: '1.875rem',
      '& p': {
        textAlign: 'center',
        fontSize: '1.375rem',
        '& a': {
          fontWeight: 700,
          color: theme.colors.invertColor.color1
        }
      }
    }
  },
  '@media (max-width: 992px)': {
    '.forgot_pass_area': {
      '.forgot_box_con': {
        width: '40.625rem',
        padding: '2.5rem'
      },
      '.forgot_heading': {
        paddingBottom: '1.875rem',
        '& h2': {
          fontSize: '1.5rem',
          marginTop: '1.875rem'
        },
        '& p': {
          fontSize: '1.125rem'
        }
      },
      '.form_box': {
        marginBottom: '0.9375rem'
      },
      '.forgot_botinfo2': {
        '& p': {
          fontSize: '1.25rem'
        }
      },
      '.forgot_botinfo': {
        paddingTop: '1.25rem',
        '& p': {
          fontSize: '1.25rem'
        }
      },
      'button.submitBtn': {
        '& svg': {
          width: '1.125rem'
        }
      }
    }
  },
  '@media (max-width: 767px)': {
    '.forgot_pass_area': {
      '.forgot_box_con': {
        width: 'calc(100% - 2.5rem)',
        padding: '1.875rem'
      },
      '.form_box': {
        '& input': {
          height: '1.5625rem'
        },
        'button.submitBtn': {
          height: '3.25rem',
          '& svg': {
            top: '0.9375rem'
          }
        }
      }
    }
  }
}))

export const HeaderStyle = styled('div')(({ theme }) => ({
  '.header_area': {
    // background: theme.colors.color.color1,
    background: theme.colors.color.gray800,
    marginBottom: '0.7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 .8rem',
    width: '100%',
    height: '2.275rem',
    '.header_logo': {
      width: '2.5rem',
      display: 'flex',
      justifyContent: 'center'
    },

    '.header_left': {
      display: 'flex',
      alignItems: 'center',
      '.header_date': {
        paddingLeft: '1.5625rem',
        '& h2': {
          fontSize: '1.5rem',
          fontWeight: 700
        }
      }
    },
    '.header_right': {
      display: 'flex',
      alignItems: 'center',
      gap: '.15rem',

      '.head_user_name': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.4375rem',
        marginLeft: '4rem',
        '.user_pic': {
          width: '1rem',
          height: '1rem',
          borderRadius: '50%',
          overflow: 'hidden',
          '& img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%'
          }
        },
        '& h4': {
          fontSize: '0.875rem'
        }
      },
      '.header_drop_icon': {
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  },
  '@media (max-width: 81.25rem)': {
    '.header_area': {
      padding: '1.25rem 1.875rem 1.25rem 0'
    }
  },
  '@media (max-width: 62rem)': {
    '.header_left': {
      '.header_date': {
        '& h2': {
          fontSize: '1.25rem'
        }
      }
    },
    '.head_user_name': {
      '& h4': {
        display: 'none'
      },
      '& h3': {
        display: 'none'
      }
    },
    '.header_right': {
      gap: '0.75rem'
    }
  },
  '@media (max-width: 47.9375rem)': {
    '.header_logo': {
      paddingLeft: '4.375rem'
    },
    '.header_date': {
      display: 'none'
    },
    '.header_area': {
      padding: '1.25rem 1.25rem 1.25rem 0'
    }
  }
}))

export const SidebarStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none'
  },
  '.sidebar': {
    width: '7.5rem',
    background: theme.colors.color.color4,
    height: 'calc(100vh - 6.375rem)',
    overflow: 'auto',
    borderRight: 'solid 0.0625rem',
    borderColor: theme.colors.invertColor.color1,
    position: 'fixed',
    top: '6.375rem',
    left: 0,
    '& ul': {
      margin: 0,
      padding: '1.875rem 1.375rem',
      '& li': {
        display: 'block',
        textAlign: 'center',
        fontSize: '0.75rem',
        margin: '0.625rem 0',
        '& a': {
          color: theme.colors.invertColor.color1,
          '& div': {
            width: '3.125rem',
            height: '3.125rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto 0.9375rem',
            borderRadius: '0.625rem',
            '& svg': {
              filter: 'grayscale(1) invert(1)'
            }
          },
          '&.active': {
            '& div': {
              background: theme.colors.color.color12
            }
          }
        },
        '&:hover': {
          '& a': {
            '& div': {
              background: theme.colors.color.color12
            }
          }
        }
      }
    }
  },
  '.menu_toggle_btn': {
    display: 'none',
    zIndex: 9,
    background: theme.colors.invertColor.color1,
    top: '1.875rem',
    left: '1.25rem',
    width: '2.5rem',
    height: '2.5rem',
    position: 'fixed'
  },
  '@media (max-width: 47.9375rem)': {
    '.menu_toggle_btn': {
      display: 'block'
    },
    '.sidebar': {
      left: '-7.5rem',
      transition: 'all 500ms ease-in-out 0ms',
      zIndex: 9
    },
    '.sidebar.sidebar_open': {
      left: '0',
      transition: 'all 500ms ease-in-out 0ms'
    }
  }
}))

export const DatatableHeadingStyle = styled('div')(({ theme }) => ({
  '.user_heading': {
    paddingBottom: '1.5625rem', // 25px converted to rem
    '& h2': {
      fontSize: '1.5rem', // 24px converted to rem
      marginBottom: '1.25rem' // 20px converted to rem
    },
    '.user_head_search': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '.MuiInputBase-root': {
        background: theme.colors.color.color6
      },
      '& input': {
        height: '1.3rem', // 35px converted to rem
        borderRadius: '0.625rem', // 10px converted to rem
        padding: '0.9375rem', // 15px converted to rem
        width: '17.5rem' // 280px converted to rem
      },

      '.MuiSelect-select': {
        background: theme.colors.color.color6,
        height: '0rem', // 20px converted to rem
        minHeight: '0rem',
        borderRadius: '0.625rem', // 10px converted to rem
        display: 'flex',
        alignItems: 'center',
        width: '8.75rem', // 140px converted to rem
        overflow: 'hidden'
      },
      '.MuiSelect-icon': {
        color: theme.colors.invertColor.color1
      },
      '.table_head_btn_con': {
        display: 'flex',
        alignItems: 'end',
        gap: '0.625rem' // 10px converted to rem
      }
    },
    '.page_search': {
      '& button': {
        padding: 0,
        background: 'none',
        position: 'absolute',
        right: '0.625rem' // 10px converted to rem
      }
    },
    '@media (max-width: 1300px)': {
      // 1300px converted to rem
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '1.25rem', // 20px converted to rem
        '.table_head_btn_con': {
          width: '100%',
          justifyContent: 'end'
        },
        '.MuiSelect-select': {
          width: '6.25rem' // 100px converted to rem
        },
        '& button': {
          padding: '0.75rem 0.625rem' // 12px 10px converted to rem
        }
      }
    },
    '@media (max-width: 767px)': {
      // 767px converted to rem
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.9375rem', // 15px converted to rem
        '& div': {
          width: '100%'
        },
        '& input': {
          width: '100%'
        },
        '.table_head_btn_con': {
          flexWrap: 'wrap',
          gap: '0.9375rem' // 15px converted to rem
        }
      }
    }
  },
  '.object_table_head_btn_con': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '.object_table_head_left': {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  '.business_heading': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h2': {
      margin: 0
    },
    '& button': {
      background: theme.colors.invertColor.color1,
      padding: '0.75rem 2.1875rem', // 12px 35px converted to rem
      borderRadius: '0.625rem', // 10px converted to rem
      color: theme.colors.color.color2,
      fontSize: '1rem', // 16px converted to rem
      height: '3.5rem', // 56px converted to rem
      '& svg': {
        marginRight: '0.3125rem' // 5px converted to rem
      },
      '& img': {
        width: '1.125rem', // 18px converted to rem
        marginRight: '0.4375rem', // 7px converted to rem
        filter: 'grayscale(1) invert(1)'
      }
    }
  },
  '.object_table_head_main': {
    display: 'flex',
    justifyContent: 'space-between'
  },
  '.object_table_head_left, .object_table_head_right': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '0.9375rem', // 15px converted to rem
    paddingBottom: '0.9375rem', // 15px converted to rem
    '.MuiInputBase-root': {
      background: theme.colors.color.color6
    },
    '& input': {
      height: '2.1875rem', // 35px converted to rem
      padding: '0.6875rem 0.9375rem', // 11px 15px converted to rem
      width: '8.75rem' // 140px converted to rem
    },
    '.MuiTextField-root': {
      '& label': {
        color: theme.colors.invertColor.color1,
        width: '6.25rem', // 100px converted to rem
        background: theme.colors.color.color6
      }
    },
    '.MuiTextField-root.search': {
      width: '17.5rem', // 280px converted to rem
      '& input': {
        width: '100%'
      }
    },
    '.MuiSelect-select': {
      background: theme.colors.color.color6,
      height: '1.25rem', // 20px converted to rem
      borderRadius: '0.625rem', // 10px converted to rem
      display: 'flex',
      alignItems: 'center',
      width: '8.75rem' // 140px converted to rem
    },
    '.MuiSelect-icon': {
      color: theme.colors.invertColor.color1
    }
  }
}))

export const FilterDropdownStyle = styled('div')(({ theme }) => ({
  '.filter_heading': {
    paddingBottom: '1.5625rem', // 25px converted to rem
    '.filter_head_search': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.invertColor.color3 // Default border color
        }
      },
      '.MuiInputBase-root': {
        background: theme.colors.color.color6
      },
      '& input': {
        height: '1.3rem', // 35px converted to rem
        borderRadius: '0.625rem', // 10px converted to rem
        padding: '0.9375rem', // 15px converted to rem
        width: '17.5rem' // 280px converted to rem
      },

      '.MuiSelect-select': {
        background: theme.colors.color.color6,
        height: '0rem', // 20px converted to rem
        minHeight: '0rem',
        borderRadius: '0.625rem', // 10px converted to rem
        display: 'flex',
        alignItems: 'center',
        width: '8.75rem', // 140px converted to rem
        overflow: 'hidden'
      },
      '.MuiSelect-icon': {
        color: theme.colors.invertColor.color1
      },
      '.table_head_btn_con': {
        display: 'flex',
        alignItems: 'end',
        gap: '0.625rem' // 10px converted to rem
      }
    },
    '.page_search': {
      '& button': {
        padding: 0,
        background: 'none',
        position: 'absolute',
        right: '0.625rem' // 10px converted to rem
      }
    },
    '@media (max-width: 1300px)': {
      // 1300px converted to rem
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '1.25rem', // 20px converted to rem
        '.table_head_btn_con': {
          width: '100%',
          justifyContent: 'end'
        },
        '.MuiSelect-select': {
          width: '6.25rem' // 100px converted to rem
        },
        '& button': {
          padding: '0.75rem 0.625rem' // 12px 10px converted to rem
        }
      }
    },
    '@media (max-width: 767px)': {
      // 767px converted to rem
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.9375rem', // 15px converted to rem
        '& div': {
          width: '100%'
        },
        '& input': {
          width: '100%'
        },
        '.table_head_btn_con': {
          flexWrap: 'wrap',
          gap: '0.9375rem' // 15px converted to rem
        }
      }
    }
  }
}))

export const UserStyle = styled('div')(({ theme }) => ({}))
export const DialogStyle = styled(Dialog)(({ theme }) => {
  return {
    '.modal-head': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      padding: '1.25rem 2.1875rem', // 20px 35px converted to rem
      backgroundColor: theme.colors.color.color5,

      '.global_modal_title': {
        fontSize: '1.375rem',
        fontWeight: 700,
        textTransform: 'capitalize'
      },

      svg: {
        fontSize: '2rem' // 32px converted to rem
      }
    },

    '.modal-body': {
      overflow: 'auto',
      '.body': {
        padding: '2.1875rem' // 35px converted to rem
      },
      '.dashboard_chart_con': {
        background: theme.colors.bodyBgColor,
        padding: '30px 25px',
        // borderRadius: '15px',
        // flex: 1,
        '@media (max-width: 1199px)': {
          width: '100%',
          padding: '20px'
        },
        '.dashboard_chart_head': {
          position: 'relative',
          '& button': {
            position: 'absolute',
            top: '-10px',
            right: '0',
            '& img': {
              filter: 'grayscale(1) invert(1)',
              width: '28px'
            }
          }
        },
        '& h3': {
          fontSize: '20px',
          color: theme.colors.loginButtonBgColor,
          fontWeight: 600,
          marginBottom: '20px'
        }
      },
      '.action': {
        padding: '1.25rem 2.1875rem', // 20px 35px converted to rem
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        gap: '0.9375rem', // 15px converted to rem

        '.cancle': {
          fontSize: '2.2rem', // 35px converted to rem
          fontWeight: 500,
          letterSpacing: '0.00625rem', // 0.1px converted to rem

          svg: {
            fontSize: '2.2rem' // 35px converted to rem
          },

          '&:hover': {
            background: 'none'
          }
        },

        '.save': {
          fontSize: '2.2rem', // 35px converted to rem
          fontWeight: 500,
          letterSpacing: '0.00625rem', // 0.1px converted to rem
          padding: '0.1875rem 2.5rem' // 3px 40px converted to rem
        }
      },
      '.table_head th': {
        borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color3}` // 1px converted to rem
      }
    },
    '@media (max-width: 767px)': {
      // 767px converted to rem
      '.modal-head': {
        padding: '1.25rem', // 20px converted to rem
        '.global_modal_title': {
          fontSize: '1.375rem' // 22px converted to rem
        }
      },
      '.form_field_area': {
        padding: '1.5625rem 1.25rem' // 25px 20px converted to rem
      },
      '.add_btn': {
        padding: '1.25rem' // 20px converted to rem
      }
    }
  }
})

export const ModalLayoutStyle = styled('div')(({ theme }) => ({
  '.modal_form_body': {
    '.form_field_area': {
      padding: '1.875rem 2.5rem' /* 30px 40px */,
      backgroundColor: theme.colors.modalComponentBgColor,
      '@media (max-width: 767px)': {
        padding: '1.5625rem 1.25rem' /* 25px 20px */
      },
      '&.analytics_form': {
        maxHeight: '28.125rem' /* 450px */,
        overflow: 'auto'
      },
      '.form_box_hold': {
        display: 'flex',
        alignItems: 'start',
        gap: '1.25rem' /* 20px */,
        '.password_holder': {
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.colors.invertColor.color3 // Default border color
            }
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'red' // Default border color
            }
          },
          input: {
            borderRight: '0',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            border: 'none'
          }
        },
        '@media (max-width: 1299px)': {
          flexDirection: 'column',
          gap: 0
        },
        '& h3': {
          color: theme.colors.invertColor.color1,
          fontSize: '1.625rem' /* 26px */,
          margin: '0.9375rem 0' /* 15px 0 */
        },
        '.form_box_con': {
          flex: 1,
          width: '100%',
          '& div': {
            width: '100%'
          },
          '& button': {
            padding: 0,
            minWidth: 0,
            background: 'none'
          }
        }
      },

      '.MuiAutocomplete-root': {
        '.MuiInputBase-root': {
          width: '100%',
          padding: 0
        },

        '.MuiAutocomplete-endAdornment': {
          width: 'unset !important',

          svg: {
            color: theme.colors.invertColor.color1
          }
        }
      },
      '.MuiAlert-root': {
        minWidth: '100% !important',
        marginTop: '0.5rem' /* 8px */,
        fontSize: '1rem!important' /* 16px */,
        '.MuiAlert-icon': {
          fontSize: '1.5625rem' /* 25px */
        }
      }
    },
    '& label': {
      fontSize: '1rem' /* 16px */,
      fontWeight: 500,
      lineHeight: '1.25rem' /* 20px */,
      marginBottom: '0.625rem' /* 10px */,
      display: 'block'
    },
    '& input': {
      height: '2.5rem' /* 40px */,
      padding: '0.5rem 0.9375rem' /* 8px 15px */,
      fontSize: '1rem' /* 16px */,
      borderRadius: 'inherit',
      border: 'solid 1px',
      borderColor: theme.colors.invertColor.color3,
      '&:disabled': {
        color: theme.colors.color.color17,
        WebkitTextFillColor: theme.colors.color.color17
      }
    },
    '.MuiInputBase-multiline': {
      padding: '0.5rem 0.9375rem' /* 8px 15px */,
      fontSize: '1rem' /* 16px */,
      borderRadius: 'inherit',
      border: 'solid 1px',
      borderColor: theme.colors.invertColor.color3,
      '&:disabled': {
        color: theme.colors.color.color17,
        WebkitTextFillColor: theme.colors.color.color17
      }
    },
    '.MuiInputBase-root': {
      width: '100%'
    },
    '.MuiSelect-select': {
      height: '1.5625rem' /* 25px */,
      borderRadius: '0.625rem' /* 10px */,
      display: 'flex',
      alignItems: 'center',
      border: 'solid 1px',
      borderColor: theme.colors.invertColor.color3
    },
    '.MuiSelect-icon': {
      color: theme.colors.invertColor.color1
    },
    '.form_box_con': {
      marginBottom: '1.25rem' /* 20px */
    },
    '.add_icon': {
      color: theme.colors.invertColor.color1,
      fontSize: '1.5rem' /* 24px */,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem' /* 8px */
    },
    '.add_btn': {
      display: 'flex',
      justifyContent: 'end',
      padding: '1.5625rem 2.5rem' /* 25px 40px */,
      backgroundColor: theme.colors.color.color5,
      gap: '0.9375rem' /* 15px */,
      '@media (max-width: 767px)': {
        padding: '1.5625rem 1.5625rem' /* 25px 25px */
      },
      '& button': {
        width: '11.875rem' /* 190px */,
        height: '3.375rem' /* 54px */,
        fontSize: '1.125rem' /* 18px */,
        fontWeight: 600,
        background: theme.colors.palatte.white,
        color: theme.colors.color.color5
      },
      '.cancel_btn': {
        color: theme.colors.palatte.white,
        background: theme.colors.color.color6
      }
    },
    '.camera_info_area': {
      padding: '1.875rem 2.5rem' /* 30px 40px */,
      '.camera_preview_area': {
        display: 'flex',
        alignItems: 'start',
        '& div': {
          flex: 1,
          '& img': {
            width: '100%',
            minHeight: '14.375rem' /* 230px */,
            objectFit: 'cover',
            borderRadius: '0.3125rem' /* 5px */
          }
        },
        '.preview_info': {
          paddingLeft: '1.875rem' /* 30px */
        }
      }
    },
    '.camera_info': {
      marginBottom: '0.9375rem' /* 15px */,
      '& h4': {
        color: theme.colors.lightTextColor,
        fontSize: '0.8125rem' /* 13px */,
        fontWeight: 600,
        textTransform: 'uppercase',
        marginBottom: 0
      },
      '& h3': {
        color: theme.colors.invertColor.color1,
        fontSize: '1.0625rem' /* 17px */,
        wordBreak: 'break-all'
      }
    },
    '@media (max-width: 767px)': {
      '.form_box_hold': {
        flexDirection: 'column',
        gap: '0 !important'
      }
    }
  }
}))

export const FormLayoutStyle = styled('div')(({ theme }) => ({
  '.form_body': {
    '.form_field_area': {
      '.form_box_hold': {
        display: 'flex',
        alignItems: 'start',
        gap: '1.25rem' /* 20px */,
        '@media (max-width: 1299px)': {
          flexDirection: 'column',
          gap: 0
        },
        '& h3': {
          color: theme.colors.invertColor.color1,
          fontSize: '1.625rem' /* 26px */,
          margin: '0.9375rem 0' /* 15px 0 */
        },
        '.form_box_con': {
          flex: 1,
          width: '100%',
          '& div': {
            width: '100%'
          },
          '& button': {
            padding: 0,
            minWidth: 0,
            background: 'none'
          }
        }
      },
      '.MuiAutocomplete-root': {
        '.MuiInputBase-root': {
          width: '100%',
          padding: 0
        },

        '.MuiAutocomplete-endAdornment': {
          width: 'unset !important',

          svg: {
            color: theme.colors.invertColor.color1
          }
        }
      }
    },
    '& label': {
      fontSize: '1rem' /* 16px */,
      fontWeight: 500,
      lineHeight: '1.25rem' /* 20px */,
      marginBottom: '0.625rem' /* 10px */,
      display: 'block'
    },
    '& input': {
      height: '2.5rem' /* 40px */,
      padding: '0.5rem 0.9375rem' /* 8px 15px */,
      fontSize: '1rem' /* 16px */,
      borderRadius: 'inherit',
      border: 'solid 1px',
      borderColor: theme.colors.invertColor.color3,
      '&:disabled': {
        color: theme.colors.color.color17,
        WebkitTextFillColor: theme.colors.color.color17
      }
    },
    '.MuiInputBase-multiline': {
      padding: '0.5rem 0.9375rem' /* 8px 15px */,
      fontSize: '1rem' /* 16px */,
      borderRadius: 'inherit',
      border: 'solid 1px',
      borderColor: theme.colors.invertColor.color3,
      '&:disabled': {
        color: theme.colors.color.color17,
        WebkitTextFillColor: theme.colors.color.color17
      }
    },
    '.MuiInputBase-root': {
      width: '100%'
    },
    '.MuiSelect-select': {
      height: '1.5625rem' /* 25px */,
      borderRadius: '0.625rem' /* 10px */,
      display: 'flex',
      alignItems: 'center',
      border: 'solid 1px',
      borderColor: theme.colors.invertColor.color3
    },
    '.MuiSelect-icon': {
      color: theme.colors.invertColor.color1
    },
    '.form_box_con': {
      marginBottom: '1.25rem' /* 20px */
    }
  }
}))

export const ChangePasswordStyle = styled('div')(({ theme }) => ({
  '.heading': {
    paddingBottom: '1.5625rem' /* converted from 25px to rem */,
    '& h2': {
      fontSize: '1.5rem' /* converted from 24px to rem */,
      marginBottom: '1.25rem' /* converted from 20px to rem */
    }
  },
  '.change_pass_con': {
    width: '43.75rem' /* converted from 700px to rem */,
    padding: '2.5rem' /* converted from 40px to rem */,
    background: theme.colors.color.color2,
    borderRadius: '1.25rem' /* converted from 20px to rem */,
    margin: '0 auto',
    '.form_box': {
      marginBottom: '1.25rem' /* converted from 20px to rem */,
      flex: 1,
      '& label': {
        fontSize: '1.125rem' /* converted from 18px to rem */,
        marginBottom: '0.375rem' /* converted from 6px to rem */,
        display: 'block',
        paddingLeft: '0.3125rem' /* converted from 5px to rem */
      },
      '.MuiInputBase-root': {
        background: theme.colors.color.color6
      },
      '& input': {
        background: theme.colors.color.color6,
        height: '2.1875rem' /* converted from 35px to rem */,
        borderRadius: '0.625rem' /* converted from 10px to rem */
      },
      '& button.submitBtn': {
        width: '100%',
        background: theme.colors.invertColor.color2,
        color: theme.colors.color.color18,
        position: 'relative',
        height: '3.75rem' /* converted from 60px to rem */,
        borderRadius: '0.625rem' /* converted from 10px to rem */,
        marginTop: '1.5625rem' /* converted from 25px to rem */,
        fontSize: '1.25rem' /* converted from 20px to rem */
      }
    }
  },
  '@media (max-width: 1300px)': {
    /* converted from 1300px to rem */
    '.change_pass_con': {
      width: '37.5rem' /* converted from 600px to rem */,
      padding: '1.875rem' /* converted from 30px to rem */
    }
  },
  '@media (max-width: 767px)': {
    /* converted from 767px to rem */
    '.change_pass_con': {
      width: '100%',
      padding: '1.875rem' /* converted from 30px to rem */
    }
  }
}))

export const MainLayoutStyle = styled('div')(({ theme }) => ({
  '.main_area': {
    // background: theme.colors.color.color3,
    background: '#333',
    height: '100vh',
    overflowY: 'hidden',
    width: '100%'
    // paddingTop: '2.525rem'
  },
  '@media (max-width: 81.25rem)': {
    /* converted from 1300px to rem */
    '.main_area': {
      padding:
        '8.125rem 1.875rem 0 2.375rem' /* converted from 130px 30px 0 150px to rem */
    }
  },
  '@media (max-width: 767px)': {
    /* converted from 767px to rem */
    '.main_area': {
      padding:
        '8.125rem 1.25rem 0 1.25rem' /* converted from 130px 20px 0 20px to rem */
    }
  }
}))

export const PermissionStyle = styled('div')(({ theme }) => ({
  '.page_heading': {
    '& button': {
      color: theme.colors.invertColor.color1,
      fontSize: '1.375rem' /* converted from 22px to rem */,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '0.9375rem' /* converted from 15px to rem */
    }
  },
  '.permission_heading': {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.color.color6,
    margin: '1.5625rem 0 0.9375rem' /* converted from 25px 0 15px to rem */,
    borderRadius: '0.625rem' /* converted from 10px to rem */,
    '.permission_name_hold': {
      width: '14.6875rem' /* converted from 235px to rem */,
      padding: '1.25rem' /* converted from 20px to rem */,
      borderRight: `solid 0.0625rem ${theme.colors.invertColor.color1}` /* converted from 1px to rem */,
      '& h3': {
        fontSize: '1.25rem' /* converted from 20px to rem */,
        fontWeight: '500'
      }
    },
    '.permission_name_hold_right': {
      width: 'calc(100% - 14.6875rem)' /* converted from 235px to rem */,
      padding: '1.25rem' /* converted from 20px to rem */,
      '& h3': {
        fontSize: '1.25rem' /* converted from 20px to rem */,
        fontWeight: '500'
      }
    }
  },
  '.permission_box_con': {
    display: 'flex',
    alignItems: 'start',
    padding: '0' /* converted from 15px 0 0 15px to rem */,
    position: 'relative',
    '&.border_item': {
      borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color1}` /* converted from 1px to rem */
    },
    '&:last-child': {
      border: 'none'
    },
    '.permission_name': {
      width: '13.75rem' /* converted from 220px to rem */,
      background: theme.colors.color.color6,
      padding: '1.25rem' /* converted from 20px to rem */,
      borderRadius: '0' /* converted from 10px to rem */,
      borderRight: `solid 0.0625rem ${theme.colors.invertColor.color1}` /* converted from 1px to rem */,
      height: 'calc(100% - 0.00625rem)' /* converted from 15px to rem */,
      position: 'absolute',
      '&.first_name': {
        borderTopLeftRadius: '0.625rem' /* converted from 10px to rem */
      },
      '&.last_name': {
        borderBottomLeftRadius: '0.625rem' /* converted from 10px to rem */
      },
      '& h3': {
        fontSize: '1.25rem' /* converted from 20px to rem */,
        fontWeight: '500'
      }
    },
    '.permission_box': {
      width: 'calc(100% - 13.75rem)' /* converted from 220px to rem */,
      marginLeft: '13.75rem' /* converted from 220px to rem */,
      display: 'flex',
      flexDirection: 'column',
      gap: '1.875rem' /* converted from 30px to rem */,
      '.permission_box_con': {
        background: theme.colors.color.color6,
        padding: '1.25rem' /* converted from 20px to rem */,
        '&.first_child': {
          borderTopRightRadius: '0.625rem' /* converted from 10px to rem */
        },
        '&.last_child': {
          borderBottomRightRadius: '0.625rem' /* converted from 10px to rem */
        },
        '.MuiFormGroup-root': {
          margin: 0,
          padding: 0
        }
      },
      '& h4': {
        color: ' #64748B',
        fontSize: '1.25rem' /* converted from 20px to rem */,
        fontWeight: 300
      },
      '.MuiFormGroup-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.875rem 6.25rem' /* converted from 30px 100px to rem */,
        paddingTop: '0.4375rem' /* converted from 7px to rem */,
        '& label': {
          display: 'flex',
          alignItems: 'center',
          gap: '0.625rem' /* converted from 10px to rem */,
          margin: '0 0 0 -0.125rem' /* converted from 0 0 0 -2px to rem */
        }
      }
    }
  },
  '.add_btn': {
    display: 'flex',
    justifyContent: 'end',
    padding: '2.5rem 0' /* converted from 40px to rem */,
    gap: '0.9375rem' /* converted from 15px to rem */,
    '& button': {
      width: '11.875rem' /* converted from 190px to rem */,
      height: '3.375rem' /* converted from 54px to rem */,
      fontSize: '1.125rem' /* converted from 18px to rem */,
      fontWeight: 600,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '1.125rem' /* converted from 18px to rem */,
      background: theme.colors.color.color2
    }
  },
  '@media (max-width: 992px)': {
    /* converted from 992px to rem */
    '.permission_name': {
      width: '15.625rem !important' /* converted from 250px to rem */
    },
    '.permission_box': {
      width:
        'calc(100% - 15.625rem) !important' /* converted from 250px to rem */,
      marginLeft: '15.625rem !important' /* converted from 250px to rem */
    },
    '.MuiFormGroup-root': {
      gap: '1.25rem 3.75rem !important' /* converted from 20px 60px to rem */
    },
    '.permission_heading': {
      '.permission_name_hold': {
        width: '16.5625rem' /* converted from 265px to rem */
      },
      '.permission_name_hold_right': {
        width: 'calc(100% - 16.5625rem)' /* converted from 265px to rem */
      }
    }
  },
  '@media (max-width: 767px)': {
    /* converted from 767px to rem */
    '.permission_box_con': {
      flexDirection: 'column',
      '.permission_name': {
        width: '100% !important',
        borderRadius: '0.625rem' /* converted from 10px to rem */,
        borderRight: 'none',
        borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color1}` /* converted from 1px to rem */,
        height: 'auto',
        position: 'relative',
        marginBottom: '0.9375rem' /* converted from 15px to rem */,
        '& h3': {
          fontSize: '1.125rem' /* converted from 18px to rem */,
          fontWeight: '500'
        }
      },
      '.permission_box': {
        width: '100% !important',
        marginLeft: '0 !important',
        gap: '1.25rem' /* 20px converted to rem */,
        '.permission_box_con': {
          background: theme.colors.color.color6,
          padding: '1.25rem' /* 20px converted to rem */,
          '.MuiFormGroup-root': {
            margin: 0,
            padding: 0
          }
        },
        '& h4': {
          fontSize: '1.125rem'
        },
        '.MuiFormGroup-root': {
          gap: '1.25rem !important'
        }
      }
    }
  },
  '@media (max-width: 600px)': {
    '.permission_heading': {
      flexDirection: 'column',
      '.permission_name_hold': {
        width: '100%',
        borderRight: 'none',
        borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color1}` /* converted from 1px to rem */
      },
      '.permission_name_hold_right': {
        width: '100%'
      }
    }
  }
}))

export const ApplicationStyle = styled('div')(({ theme }) => ({
  '.application_page_heading': {
    padding: '1.5625rem 3.75rem 1.5625rem 1.875rem',
    background: theme.colors.color.color15,
    width: 'calc(100% + 5.625rem)',
    margin: '-1.6875rem 0 1.875rem -1.875rem',
    alignItems: 'center',
    '@media (max-width: 1300px)': {
      width: 'calc(6.25rem + 3.75rem)',
      padding: '1.5625rem 1.875rem'
    },
    '@media (max-width: 767px)': {
      width: 'calc(6.25rem + 3.125rem)'
    },
    '& h2': {
      color: theme.colors.invertColor.color1,
      fontSize: '1.375rem',
      fontWeight: 500
    },
    '& button': {
      background: theme.colors.color.color2,
      padding: '0.75rem 1.25rem',
      borderRadius: '0.625rem',
      color: theme.colors.invertColor.color1,
      fontSize: '1rem',
      height: '3.5rem'
    }
  },
  '.apply_box_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '1.875rem',
    flexWrap: 'wrap',
    width: 'calc(100% + 1.875rem)',
    '@media (max-width: 992px)': {
      gap: '1.25rem 0',
      width: '100%'
    },
    '.apply_box': {
      background: theme.colors.color.color6,
      width: 'calc(25% - 1.875rem)',
      borderRadius: '0.625rem',
      '.apply_box_heading': {
        padding: '0.9375rem 4.0625rem 0.9375rem 1.25rem',
        borderBottom: `solid 0.0625rem ${theme.colors.lightBorderColor}`,
        position: 'relative'
      },
      '.application_menu': {
        position: 'absolute',
        right: '1.875rem',
        top: '1.875rem'
      },
      '& h4': {
        color: theme.colors.lightTextColor,
        fontSize: '0.8125rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      },
      '& h3': {
        color: theme.colors.invertColor.color1,
        fontSize: '1.25rem',
        wordBreak: 'break-all'
      },
      '.apply_box_info': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.9375rem',
        padding: '0.9375rem 1.25rem'
      },
      '@media (max-width: 992px)': {
        width: '100% !important '
      }
    },
    '@media (max-width: 1500px)': {
      '.apply_box': {
        width: 'calc(33.33% - 1.875rem)'
      }
    },
    '@media (max-width: 1299px)': {
      '.apply_box': {
        width: 'calc(50% - 1.875rem)'
      }
    }
  },
  '.action_camera_icon': {
    '& svg': {
      height: '1.125rem',
      width: '1.125rem'
    },
    '& button.Mui-disabled': {
      opacity: '.6',
      cursor: 'copy'
    }
  },
  '.applydropbox': {
    position: 'absolute',
    top: '3.75rem',
    right: '1.875rem',
    width: '10rem',
    padding: '0.625rem',
    display: 'none',
    background: theme.colors.color.color13,
    '&.show': {
      display: 'block'
    },
    '& button': {
      width: '100%',
      border: 'none',
      padding: '0.375rem 0.9375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.colors.invertColor.color1,
      background: theme.colors.color.color13,
      fontSize: '1rem',
      '&.Mui-disabled': {
        opacity: '.7'
      },
      '&:hover': {
        background: theme.colors.color.color6
      }
    }
  },
  '.tab_toggle_area': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.5rem',
    '.page_search': {
      '.MuiInputBase-root': {
        border: `solid 0.0625rem ${theme.colors.invertColor.color1}`
      },
      '& input': {
        width: '18.75rem'
      }
    },
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      alignItems: 'end',
      gap: '0.9375rem',
      '& input': {
        width: '100%'
      },
      '.page_search': {
        width: '100%',
        '& div': {
          width: '100%'
        }
      }
    }
  },
  '.page_search': {
    '& input': {
      paddingLeft: '0.9375rem'
    }
  }
}))

export const ZoneStyle = styled('div')(({ theme }) => ({
  '.canvas_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '1.5625rem',
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      gap: 0
    },
    '.canvas_left': {
      width: '60%',
      '@media (max-width: 1199px)': {
        width: '50%'
      },
      '@media (max-width: 992px)': {
        width: '100%'
      },
      '.canvas_con': {
        width: '100%',
        background: theme.colors.canvasBgColor,
        borderRadius: '0.9375rem',
        position: 'relative',
        '& canvas': {
          position: 'absolute',
          // width: "100%",
          // height: "100%",
          top: 0,
          left: 0,
          maxWidth: '100%'
        }
      },
      '.selected': {
        border: '2px solid #fff'
      },
      '.canvas_bot_info': {
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
        padding: '1.25rem 0',
        gap: '1.25rem',
        '.canvas_bot_info_con': {
          background: theme.colors.color.color8,
          borderRadius: '0.625rem',
          padding: '0.625rem 0.9375rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '17.1875rem',
          '.canvas_bot_info_hold': {
            display: 'flex',
            alignItems: 'center',
            gap: '0.625rem'
          },

          '.color_use': {
            background: theme.colors.canvasBgColor,
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '50%',
            '&.blue': {
              background: theme.colors.blueChartColor
            }
          },
          '& p': {
            color: theme.colors.invertColor.color1,
            fontSize: '0.75rem'
          },
          '& button': {
            background: theme.colors.color.color1,
            borderRadius: '0.625rem',
            height: '1.875rem',
            '& svg': {
              width: '0.9375rem'
            }
          }
        }
      }
    },
    '.canvas_right': {
      width: '40%',
      '@media (max-width: 1199px)': {
        width: '50%'
      },
      '@media (max-width: 992px)': {
        width: '100%'
      },
      '.canvas_right_default': {
        background: theme.colors.canvasRightPanelBgColor,
        padding: '1.5625rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30rem',
        borderRadius: '0.9375rem',
        '&.hide': {
          display: 'none'
        },
        '.canvas_right_default_step': {
          width: '70%',
          textAlign: 'center',
          '@media (max-width: 992px)': {
            width: '85%'
          },
          '@media (max-width: 767px)': {
            width: '100%'
          }
        },
        '& h2': {
          fontSize: '1.875rem',
          color: theme.colors.invertColor.color1,
          margin: '0.625rem 0'
        },
        '& p': {
          fontSize: '1.25rem',
          color: theme.colors.invertColor.color1,
          margin: '0 0 1.875rem'
        },
        '& button': {
          fontSize: '0.9375rem',
          color: theme.colors.invertColor.color1,
          fontWeight: 600,
          background: theme.colors.color.color18,
          gap: '0.9375rem',
          borderRadius: '0.625rem',
          padding: '0.9375rem 1.875rem'
        }
      },
      '.canvas_right_form': {
        display: 'none',
        '&.show': {
          display: 'block'
        },
        '.canvas_right_form_con': {
          background: theme.colors.canvasRightPanelBgColor,
          padding: '0.9375rem 0',
          borderRadius: '0.9375rem'
        },
        '& input': {
          background: theme.colors.color.color10,
          border: `solid 0.0625rem ${theme.colors.color.color13}`
        },
        '.MuiSelect-outlined': {
          background: theme.colors.color.color10,
          border: `solid 0.0625rem ${theme.colors.color.color13}`
        },
        '.add_btn': {
          paddingTop: 0,
          background: 'none'
        },
        '& input[type=color]': {
          width: '6.25rem',
          padding: '0.25rem',
          borderRadius: '0.3125rem'
        },
        '.color_area': {
          width: '100%',
          gap: '1.5625rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '@media (max-width: 1499px)': {
            alignItems: 'start',
            flexDirection: 'column'
          },
          '.color_field_area': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1.25rem',
            '@media (max-width: 767px)': {
              gap: '0.625rem'
            }
          },
          '.color_field': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '1.25rem',
            '@media (max-width: 767px)': {
              gap: '0.625rem'
            },
            '.color_name': {
              '.MuiInputBase-root': {
                minWidth: '5.625rem'
              }
            },
            '.MuiInputBase-root': {
              width: 'auto',
              border: 'none !important',
              '.MuiSelect-select': {
                background: 'none',
                border: 'none !important',
                padding: 0
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none !important'
              }
            },
            '.color_input': {
              width: '100%',
              background: theme.colors.color.color10,
              border: `solid 0.0625rem ${theme.colors.color.color13}`,
              borderRadius: '0.625rem',
              height: '3.4375rem',
              display: 'flex',
              alignItems: 'center',
              '& input': {
                flex: 1,
                border: 'none',
                padding: '0 0.625rem',
                textAlign: 'center',
                borderRight: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
                borderRadius: 0
              },
              '& > div:last-child': {
                '& input': {
                  border: 'none !important'
                }
              }
            }
          }
        }
      },
      '.canvas_right_bot': {
        padding: '2.5rem 0',
        '& p': {
          color: theme.colors.invertColor.color1,
          fontSize: '1rem',
          marginBottom: '0.625rem'
        },
        '.canvas_btn_con': {
          display: 'flex',
          alignItems: 'center',
          gap: '1.25rem',
          '@media (max-width: 767px)': {
            gap: '0.625rem'
          },
          '& div': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.5rem',
            overflow: 'hidden',
            '& button': {
              borderRadius: 0,
              '@media (max-width: 1399px)': {
                '& img': {
                  width: '1.875rem'
                }
              },
              '@media (max-width: 767px)': {
                minWidth: '1.5625rem',
                '& img': {
                  width: '1.25rem'
                }
              }
            }
          },
          '& button': {
            background: theme.colors.invertColor.color1,
            padding: '0.5rem 0.625rem',
            display: 'flex',
            alignItems: 'center',
            gap: '0.4375rem',
            borderRadius: '0.5rem',
            border: 'none',
            height: '3.75rem',
            color: theme.colors.color.color18,
            minWidth: 0,
            lineHeight: '1.25rem',
            '@media (max-width: 1399px)': {
              '& img': {
                width: '3.125rem'
              }
            },
            '&:hover': {
              background: theme.colors.canvasBgColor,
              color: theme.colors.invertColor.color1,
              '& img': {
                filter: 'brightness(2)'
              }
            },
            '&.reset_btn': {
              width: '8.125rem'
            },
            '&.Mui-disabled': {
              opacity: '.7'
            }
          },
          '.active': {
            background: theme.colors.canvasBgColor,
            color: theme.colors.loginButtonBgColor,
            '& img': {
              filter: 'brightness(2)'
            }
          }
        }
      }
    }
  }
}))

export const VideoUploadStyle = styled('div')(({ theme }) => ({
  '.video_upload_area': {
    display: 'flex',
    alignItems: 'start',
    marginTop: '-1.875rem',
    marginLeft: '-1.875rem',
    width: 'calc(100% + 5.625rem)',
    overflow: 'hidden',
    position: 'relative',
    height: 'calc(100vh - 6.25rem)',
    '@media (max-width: 767px)': {
      width: 'calc(100% + 3.125rem)',
      height: 'calc(100vh - 5.0625rem)'
    },
    '.video_upload_form': {
      minWidth: 0,
      height: '100%',
      overflow: 'auto',
      padding: '1.875rem',
      width: '45%',
      background: theme.colors.color.color3,
      position: 'absolute',
      left: '-45%',
      top: 0,
      transition: 'all 500ms ease-in-out 0ms',
      '@media (max-width: 992px)': {
        width: '60%',
        left: '-60%'
      },
      '@media (max-width: 767px)': {
        width: '100%',
        left: '-100%',
        padding: '1.25rem 1.25rem 1.25rem 1.875rem'
      },
      '&.show': {
        left: 0,
        transition: 'all 500ms ease-in-out 0ms'
      },
      '.video_upload_form_con': {
        borderRadius: '0.9375rem',
        background: theme.colors.color.color6,
        '& h3': {
          fontSize: '1.0625rem',
          fontWeight: 600,
          marginBottom: '1.25rem'
        },
        '.custom_upload': {
          background: theme.colors.canvasRightPanelBgColor,
          height: '15.625rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '1.25rem',
          cursor: 'pointer',
          '& label': {
            cursor: 'pointer'
          },
          '.MuiTextField-root': {
            display: 'none'
          },
          '.custom_upload_info': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1.25rem',
            padding: '0.625rem',
            textAlign: 'center',
            '& p': {
              fontSize: '0.875rem'
            }
          }
        },
        '& input': {
          border: `solid 0.125rem ${theme.colors.canvasRightPanelBgColor}`
        },
        '.MuiInputBase-multiline': {
          border: `solid 0.125rem ${theme.colors.canvasRightPanelBgColor}`
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent'
        },
        '.add_btn': {
          background: 'none',
          paddingTop: 0,
          paddingBottom: '3.75rem',
          button: {
            background: theme.colors.color.color18,
            color: theme.colors.invertColor.color1,
            '&.cancel_btn': {
              color: theme.colors.palatte.white,
              background: theme.colors.canvasRightPanelBgColor
            }
          }
        }
      }
    },
    '.video_upload_list': {
      width: '100%',
      background: theme.colors.color.color6,
      height: '100%',
      overflow: 'auto',
      padding: '1.875rem 3.125rem 1.875rem 1.5625rem',
      transition: 'all 500ms ease-in-out 0ms',
      '@media (max-width: 81.25rem)': {
        padding: '1.875rem 1.875rem 1.875rem 1.5625rem'
      },
      '@media (max-width: 767px)': {
        padding: '1.875rem 1.25rem 1.875rem 1.875rem'
      },
      '&.small': {
        paddingLeft: 'calc(45% + 1.25rem)',
        transition: 'all 500ms ease-in-out 0ms',
        '@media (max-width: 992px)': {
          paddingLeft: 'calc(60% + 1.25rem)'
        },
        '@media (max-width: 767px)': {
          paddingLeft: 0
        }
      },
      '.video_upload_btn': {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: '0.9375rem',
        '&.hide': {
          display: 'none'
        },
        button: {
          background: theme.colors.color.color18,
          color: theme.colors.invertColor.color1
        }
      },
      '& th': {
        background: theme.colors.canvasRightPanelBgColor
      },
      '& td': {
        background: theme.colors.canvasRightPanelBgColor
      }
    }
  }
}))
export const DashboardStyle = styled('div')(({ theme }) => ({
  '.dash_tab_btn': {
    margin: '0 .7px .7px 0',
    background: '#1E1E1E',
    '& button': {
      color: theme.colors.palatte.white,
      fontSize: '12px',
      marginRight: '2px',
      '&:hover': {
        color: theme.colors.palatte.white,
        background: theme.colors.palatte.gray800
      },
      '&.Mui-selected': {
        color: theme.colors.palatte.white,
        background: '#333'
      }
    },
    '.MuiTabs-indicator': {
      display: 'none'
    },
    '.MuiTabs-root': {
      height: 'auto'
    },
    '.css-1cqe59s-MuiButtonBase-root-MuiTab-root': {
      padding: '0px 2px',
      minHeight: '24px'
    },
    '.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
      padding: '2px'
    },
    '.css-16fol8f-MuiTabs-root': {
      minHeight: '0px',
      height: 'auto'
    }
  },
  '.dashboard_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '0.7px',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      paddingBottom: '1.875rem'
    },
    '.dashboard_left_folder_area': {
      minWidth: '200px',
      height: '100vh',
      overflow: 'auto',
      background: theme.colors.color.gray800,
      paddingTop: '1rem',
      paddingLeft: '.7rem',
      paddingRight: '.7rem',

      '& ul': {
        padding: 0,
        margin: 0,
        '.MuiTreeItem-content': {
          padding: '5px',
          position: 'relative',
          background: theme.colors.color.color,
          '&.Mui-selected': {
            background: theme.colors.color.color6,
            borderRadius: '7px'
          }
        },
        '& li': {
          listStyle: 'none',
          '& a': {
            color: theme.colors.palatte.white,
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          },
          '& ul': {
            marginLeft: '10px'
          }
        }
      }
    },
    '.dashboard_left': {
      // width: 'calc(100% - 15rem)',
      '@media (max-width: 767px)': {
        width: '100%'
      },
      '.dashboard_left_con': {
        background: '#0E0E0E',

        // borderBottomRightRadius: '0.9375rem',
        // borderBottomLeftRadius: '0.9375rem',
        // height: 'calc(100vh - 11.7rem)',
        height: '100vh',

        overflow: 'hidden',
        '@media (max-width: 767px)': {
          padding: '0.9375rem',
          height: 'auto'
        },
        '.time_chart_area': {
          padding: '1.875rem 1.5625rem',
          borderRadius: '0.5rem',
          background: theme.colors.color.color3,
          position: 'relative',
          margin: '25px 0 0 -15px',
          width: 'calc(100% + 30px)',
          '.pause_play_btn_con': {
            borderRadius: '40px',
            padding: '20px 0',
            background: theme.colors.color.color3,
            position: 'absolute',
            left: '50%',
            top: '-100px',
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            '.pause_play_btn': {
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              padding: '0 25px',
              '&.pause_play_btn_mid': {
                borderLeft: `solid 1px ${theme.colors.palatte.white}`,
                borderRight: `solid 1px ${theme.colors.palatte.white}`
              }
            },
            '& button': {
              border: `solid 1px ${theme.colors.palatte.white}`,
              width: '50px',
              height: '50px'
            }
          }
        },
        '.time_con_hold': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '.time_con': {
            justifyContent: 'space-between',
            height: '45px',
            marginLeft: '5px',
            paddingLeft: '8px',
            borderLeft: 'solid 1px #fff'
          }
        }
      },
      '.dashboard_heading': {
        paddingBottom: '1.5625rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.625rem',
        '@media (max-width: 1100px)': {
          flexDirection: 'column',
          alignItems: 'start',
          width: '100%',
          '.MuiInputBase-root': {
            width: '100%'
          }
        },
        '.dash_select_area': {
          display: 'flex',
          alignItems: 'center',
          gap: '15px',

          '.MuiInputBase-root': {
            width: '12rem'
          },

          '@media (max-width: 1100px)': {
            width: '100%'
          },
          '@media (max-width: 940px)': {
            flexDirection: 'column',
            position: 'relative',
            '& button': {
              position: 'absolute',
              top: '-55px',
              right: '-5px'
            }
          },
          '& img': {
            width: '34px',
            filter: 'grayscale(1) invert(1)',
            marginLeft: '-10px'
          }
        },
        '& h2': {
          fontSize: '1.5rem'
        },
        '.MuiSelect-select': {
          background: theme.colors.color.color6,
          height: '1.25rem',
          borderRadius: '0.625rem',
          display: 'flex',
          alignItems: 'center',
          width: '75%',
          paddingRight: 0,
          overflow: 'hidden',
          '@media (max-width: 900px)': {
            width: '100%'
          }
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.lightBorderColor
        },
        '.MuiSelect-icon': {
          color: theme.colors.invertColor.color1
        }
      },
      '.dashboard_top_info': {
        display: 'flex',
        alignItems: 'center',
        gap: '1.25rem',
        flexWrap: 'wrap',
        '@media (max-width: 1199px)': {
          gap: '0.9375rem'
        },
        '@media (max-width: 992px)': {
          flexDirection: 'column'
        },
        '.dashboard_top_info_box': {
          background: theme.colors.color.color3,
          padding: '1.25rem',
          borderRadius: '0.625rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.9375rem',
          width: 'calc(33.33% - 0.9375rem)',
          '@media (max-width: 1550px)': {
            height: '8.75rem'
          },
          '@media (max-width: 1199px)': {
            flexDirection: 'column',
            alignItems: 'center',
            height: '13.75rem',
            '& div': {
              textAlign: 'center'
            }
          },
          '@media (max-width: 992px)': {
            flexDirection: 'row',
            alignItems: 'center',
            height: '8.75rem',
            width: '100%',
            '& div': {
              textAlign: 'left'
            }
          },
          '& p': {
            fontSize: '1.125rem',
            color: theme.colors.invertColor.color1,
            '@media (max-width: 1650px)': {
              fontSize: '1rem'
            },
            '@media (max-width: 1199px)': {
              lineHeight: '1.25rem'
            }
          },
          '& h2': {
            fontSize: '2.5rem',
            color: theme.colors.invertColor.color1,
            fontWeight: 700,
            marginTop: '0.3125rem',
            '@media (max-width: 1650px)': {
              fontSize: '2.125rem',
              marginTop: 0
            },
            '@media (max-width: 1199px)': {
              fontSize: '1.75rem',
              marginTop: 0
            }
          },
          '.dashboard_top_info_icon': {
            width: '4.375rem',
            height: '4.375rem',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.colors.color.color6,
            '@media (max-width: 1650px)': {
              width: '3.75rem',
              height: '3.75rem',
              minWidth: '3.75rem'
            },
            '@media (max-width: 1199px)': {
              minHeight: '3.75rem'
            }
          }
        }
      },
      '.dashboard_chart_area': {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        // gap: '20px',
        paddingTop: '1.25rem',
        // '@media (max-width: 1199px)': {
        //   flexDirection: 'column',
        // },
        '.dashboard_chart_con': {
          background: theme.colors.color.color3,
          padding: '1.875rem 1.5625rem',
          borderRadius: '0.9375rem',
          flex: 1,
          // flex: 1,
          '@media (max-width: 1199px)': {
            width: '100%',
            padding: '1.25rem'
          },
          '.dashboard_chart_head': {
            position: 'relative',
            '.dashboard_chart_head_btn': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '10px',
              '@media (max-width: 1199px)': {
                width: '100%'
              }
            },
            '& button': {
              background: '#364030',
              width: '36px',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& img': {
                filter: 'grayscale(1) invert(1)',
                width: '20px'
              },
              '& svg': {
                filter: 'grayscale(1) invert(1)'
              }
            }
          },
          '& h3': {
            fontSize: '1.25rem',
            color: theme.colors.invertColor.color1,
            fontWeight: 600,
            marginBottom: '1.25rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '15px',
            '@media (max-width: 1199px)': {
              flexDirection: 'column',
              alignItems: 'start'
            }
          }
        }
      },
      '.chart_con': {
        '&.hide': {
          display: 'none'
        }
      },
      '.dashboard_camera_pic': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1.25rem',
        paddingTop: '1rem',
        marginLeft: '-15px',
        width: 'calc(100% + 30px)',
        flexWrap: 'wrap',
        '@media (max-width: 1199px)': {
          flexDirection: 'column',
          gap: '0.9375rem'
        },
        '& > div': {
          background: theme.colors.color.color3,
          padding: '1.875rem 1.5625rem',
          borderRadius: '0.9375rem',
          width: 'calc(50% - 15px)',
          '@media (max-width: 1199px)': {
            padding: '1.25rem'
          },
          '& h3': {
            fontSize: '1.25rem',
            color: theme.colors.invertColor.color1,
            fontWeight: 600,
            marginBottom: '1.25rem'
          },
          '& img': {
            width: '100%'
          },
          '& video': {
            width: '100%'
          }
        }
      }
    },
    '.dashboard_right': {
      background: theme.colors.color.color6,
      borderRadius: '0.9375rem',
      width: '24rem',
      height: 'calc(100vh - 8.675rem)',
      overflow: 'auto',
      '@media (max-width: 767px)': {
        width: '100%',
        height: 'auto',
        padding: '0.9375rem'
      },
      '& h3': {
        fontSize: '1.25rem',
        color: theme.colors.invertColor.color1,
        fontWeight: 600,
        marginBottom: '1.25rem',
        '@media (max-width: 767px)': {
          marginBottom: '0.9375rem'
        }
      },
      '& ul': {
        padding: '0 0 0 1.25rem',
        margin: 0,
        '& li': {
          fontSize: '1.125rem',
          color: theme.colors.invertColor.color1,
          fontWeight: 600,
          marginBottom: '1.25rem',
          position: 'relative',
          listStyle: 'disc',
          '@media (max-width: 767px)': {
            marginBottom: '0.625rem'
          }
        }
      }
    }
  }
}))

export const DashboardForm = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 9.375rem)',
  background: theme.colors.color.color6,
  borderRadius: '0.9375rem',
  padding: '1.875rem 3.125rem',
  paddingRight: '0',

  '.inner-content': {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingRight: '3.125rem',
    overflow: 'auto',

    '.select-top': {
      position: 'relative',
      width: '100%',
      padding: '0rem 6.25rem',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        fontSize: '1.125rem',
        paddingLeft: '0.625rem',
        color: theme.colors.invertColor.color1,

        '@media (max-width: 1460px)': {
          paddingLeft: '2.5rem'
        },

        '@media (max-width: 991px)': {
          paddingLeft: '1.875rem'
        }
      },

      '.select-box': {
        position: 'relative',
        width: '28.125rem',
        height: '3.125rem',
        margin: '0',
        marginTop: '0.625rem',

        '.MuiInputBase-root': {
          borderRadius: '0.5625rem',
          border: '0.125rem solid',
          borderColor: theme.colors.color.color11,
          background: theme.colors.color.color14,

          svg: {
            color: theme.colors.invertColor.color1
          }
        },

        '.css-nu8v1e-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.colors.color.color11
          },

        '@media (max-width: 1460px)': {
          width: '50%',
          padding: '0rem 1.25rem'
        },

        '@media (max-width: 991px)': {
          padding: '0rem 0.9375rem'
        },

        '@media (max-width: 840px)': {
          width: '100%'
        }
      },

      '@media (max-width: 1460px)': {
        padding: '0rem 3.75rem'
      },

      '@media (max-width: 1199px)': {
        padding: '0rem 1.875rem'
      },

      '@media (max-width: 991px)': {
        padding: '0rem 1.25rem'
      },

      '@media (max-width: 499px)': {
        padding: '0rem 0rem'
      }
    },

    '.add-form': {
      position: 'relative',
      width: '100%',
      marginTop: '1.5625rem',
      marginBottom: '1.875rem',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        position: 'relative',
        padding: '0rem 6.5625rem',
        fontSize: '1.3125rem',

        '@media (max-width: 1460px)': {
          padding: '0rem 5.3125rem'
        },

        '@media (max-width: 1199px)': {
          padding: '0rem 3.75rem'
        },

        '@media (max-width: 991px)': {
          padding: '0rem 2.8125rem',
          fontSize: '1.1875rem'
        },

        '@media (max-width: 499px)': {
          padding: '0rem 1.25rem',
          fontSize: '1.125rem'
        }
      }
    },

    '@media (max-width: 499px)': {
      paddingRight: '1.5625rem'
    }
  },

  '@media (max-width: 499px)': {
    padding: '1.875rem 1.5625rem',
    paddingRight: '0'
  }
}))

export const AddForm = styled('form')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginTop: '0.625rem',
  padding: '1.875rem 6.25rem',
  background: theme.colors.color.color3,
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,

  '@media (max-width: 1460px)': {
    padding: '1.5625rem 3.75rem'
  },
  '@media (max-width: 1199px)': {
    padding: '1.5625rem 1.875rem'
  },
  '@media (max-width: 991px)': {
    padding: '1.5625rem 1.25rem'
  },
  '.action': {
    display: 'flex',
    width: '100%',
    gap: '1.25rem',
    justifyContent: 'flex-end'
  }
}))

export const InputBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 'calc(50% - 0.625rem)',
  margin: '0.625rem 0',
  display: 'flex',
  flexDirection: 'column',

  ':nth-of-type(even)': {
    marginRight: '0rem'
  },

  '& span': {
    position: 'relative',
    paddingLeft: '0.9375rem',
    fontSize: '1.125rem',
    color: theme.colors.invertColor.color1,
    marginBottom: '0.625rem'
  },

  '.input': {
    position: 'relative',
    width: '100%',
    height: '3.125rem',
    border: '0.125rem solid',
    borderColor: theme.colors.color.color14,
    background: theme.colors.color.color14,
    borderRadius: '0.5rem'
  },

  '.select': {
    position: 'relative',
    width: '100%',
    height: '3.125rem',
    border: '0.125rem solid',
    borderColor: theme.colors.color.color14,
    background: theme.colors.color.color14,
    borderRadius: '0.5rem !important',

    svg: {
      color: theme.colors.invertColor.color1
    }
  },

  '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },

  '@media (max-width: 1460px)': {
    width: '50%',
    padding: '0.625rem 1.25rem',
    margin: '0rem'
  },

  '@media (max-width: 991px)': {
    padding: '0.625rem 0.9375rem'
  },

  '@media (max-width: 840px)': {
    width: '100%'
  },

  '@media (max-width: 499px)': {
    padding: '0.625rem 0rem'
  }
}))

export const ButtonBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '59.375rem',
  marginTop: '0.625rem',
  marginBottom: '1.25rem',
  display: 'flex',
  justifyContent: 'flex-end',

  '@media (max-width: 1460px)': {
    width: '100%',
    padding: '0rem 1.5625rem'
  },
  '@media (max-width: 991px)': {
    width: '100%',
    padding: '0rem 0.9375rem'
  },
  '@media (max-width: 840px)': {
    marginTop: '1.5625rem'
  },
  '@media (max-width: 499px)': {
    padding: '0rem'
  }
}))
export const Button = styled('button')(({ theme }) => ({
  position: 'relative',
  width: '12.5rem',
  height: '3.125rem',
  borderRadius: '0.5rem',
  fontSize: '1rem',
  color: theme.colors.color.color18,
  background: theme.colors.invertColor.color1,
  cursor: 'pointer',
  border: 'none',

  '@media (max-width: 499px)': {
    width: '100%'
  }
}))
