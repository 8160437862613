import React, { useEffect, useState }from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { ModalLayoutStyle } from '../../Layouts/style';
import { StorageFormData, AddStorageSettingFormProps, StorageSettings} from '../../types/storageSettings';
import { addStorageSettingValidationSchema, StorageSettingType } from '../../Utils/validator';
import RenderFieldsByType from './StorageSettingType/RenderFieldsByType';
import SettingsService from '../../RestSevices/settingsService';
import { toast } from 'react-toastify';
import useAuth from '../../Redux/Actions/authActions';
import moment from 'moment/moment';

const AddStorageSettingForm: React.FC<AddStorageSettingFormProps> = ({ onClose, initialData, fetchSettings }) => {
  const initialValues: StorageFormData = {
    key: initialData?.key || '',
    description: initialData?.description || '',
    values: {
      type: initialData?.values?.type || '',
      uri: initialData?.values?.uri || '',
      client_id: initialData?.values?.client_id || '',
      client_secret: initialData?.values?.client_secret || '',
      bucket: initialData?.values?.bucket || ''
    }
  };
  const editValues: StorageSettings = {
    id: initialData?.id || '',
    key: initialData?.key || '',
    description: initialData?.description || '',
    values: {
      type: initialData?.values?.type || '',
      uri: initialData?.values?.uri || '',
      client_id: initialData?.values?.client_id || '',
      client_secret: initialData?.values?.client_secret || '',
      bucket: initialData?.values?.bucket || ''
    },
    status: initialData?.status || '',
    created_at: initialData?.created_at || '',
    updated_at: initialData?.updated_at || ''
  };
  const [validationSchema, setValidationSchema] = useState(addStorageSettingValidationSchema(initialValues.key as StorageSettingType));
  const [typeList, setTypeList] = useState<any[]>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [storageData, setStorageData] = useState<any>({});

  const { logout } = useAuth();

  const handleSettingTypeChange = (event: any, props: any) => {
    // console.log(event.target.value);
    props.setFieldValue('key', event.target.value);
    // const selectedType = typeList.find((type: any) => type.key === event.target.value);
  
    // if (selectedType) {
    //   const { key, values } = selectedType;
  
    //   if (key === 'storage') {
    //     props.setFieldValue('values.uri', values.uri || '');
    //     props.setFieldValue('values.type', values.type || '');
    //     props.setFieldValue('values.client_id', values.client_id || '');
    //     props.setFieldValue('values.client_secret', values.client_secret || '');
    //     props.setFieldValue('values.bucket', values.bucket || '');
    //   } else {
    //     props.setFieldValue('values.uri', '');
    //     props.setFieldValue('values.type', '');
    //     props.setFieldValue('values.client_id', '');
    //     props.setFieldValue('values.client_secret', '');
    //     props.setFieldValue('values.bucket', '');
    //   }
    // }
  
    setValidationSchema(addStorageSettingValidationSchema(event.target.value as StorageSettingType));
  };
  
  const handleSubmit = (values: StorageFormData) => {
    // Handle form submission logic here
    // console.log(values);
    try {
      if (initialData && initialData.id) {
        setTriggerUpdate(true);
        setStorageData({ 
          ...storageData, 
          ...values,
          id: initialData.id,
          key: values.key,
          status: initialData.status,
          description: values.description,
          values: {
            ...values.values
          },
          created_at: initialData.created_at,
          updated_at: initialData.updated_at
        });
        setTriggerUpdate(true);
      }else{
        setStorageData({ 
          ...storageData, 
          ...values,
          key: values.key,
          description: values.description,
          values: {
            ...values.values
          }
        });
        setTriggerUpdate(true);
      }
    } catch (error:any) {
      if (error.response?.data?.error === 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  useEffect(() => {
    if (triggerUpdate) {
      const updateRole = async () => {
        try {
          if (initialData && initialData.id) {
            const resp = await SettingsService.updateStorage(editValues.key, storageData);
            if (resp.status === 200) {
              toast.success('Settings updated successfully!', {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchSettings && fetchSettings();
              onClose();
            }
          } else {
            const response = await SettingsService.createStorageSettings(storageData);
            if (response.status === 200) {
              toast.success('Settings created successfully!', {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchSettings && fetchSettings();
              onClose();
            }
          }
        } catch (err:any) {
          if (err.response.status === 401) {
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            logout();
          } else {
            toast.error('Error in creating/updating Setting', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } finally {
          setTriggerUpdate(false);
        }
      };
      updateRole();
    }
  }, [triggerUpdate, storageData, initialData?.id, logout]);
  useEffect(() => {
    setValidationSchema(addStorageSettingValidationSchema(initialValues.key as StorageSettingType));
    if (initialData && initialData.id) {
      setTypeList([
        'storage',
        'keycloak',
        'mqtt',
        'ice-servers'
      ])
    }else{
      setTypeList([
        'storage'
      ])
    }
  }, [initialValues.key]);
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Formik
          initialValues={initialData?.id ? editValues : initialValues }
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props: FormikProps<StorageFormData>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Box className="form_field_area">
                  <Box className="form_box_hold">
                    <Box className="form_box_con full_width">
                      <Typography component="label">Key</Typography>
                      {initialData && initialData.id ? ( 
                        <TextField
                          name="key"
                          type="text"
                          disabled
                          value={initialData.key}
                        />
                      ) : (
                        <>
                          <Select
                            name="key"
                            value={props.values.key}
                            onChange={(e) => {
                              handleSettingTypeChange(e, props);
                            }}
                            error={props.touched.key && !!props.errors.key}
                            displayEmpty
                            disabled={initialData && initialData.id ? true : false}
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {typeList.map((type, index) => (
                              <MenuItem key={`type-${index}`} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                          error={props.touched.key && !!props.errors.key}
                        >
                          {props.touched.key ? props.errors.key : ''}
                        </FormHelperText>
                      </>
                      )}
                    </Box>
                  </Box>
                  <Box className="form_box_hold">
                    <Box className="form_box_con full_width">
                      <Typography component="label">Description</Typography>
                      <TextField
                        name="description"
                        type="text"
                        multiline
                        rows={4}
                        value={props.values.description}
                        onChange={props.handleChange}
                        error={props.touched.description && !!props.errors.description}
                        helperText={props.touched.description ? props.errors.description : ''}
                      />
                    </Box>
                  </Box>
                  {(props.values.key && props.values.key === 'storage') && (
                    <RenderFieldsByType type={props.values.key as StorageSettingType} formikProps={props} />
                  )}
                  {initialData?.id && (
                    <Box className="form_box_hold">
                    <Box className="form_box_con">
                      <Typography component="label">Created At</Typography>
                      <TextField
                        name="created_at"
                        type="text"
                        disabled
                        value={moment((props.values as any).created_at).format('MMMM Do, YYYY')}
                      />
                    </Box>
                    <Box className="form_box_con">
                      <Typography component="label">Updated At</Typography>
                      <TextField
                        name="updated_at"
                        type="text"
                        disabled
                        value={moment((props.values as any).updated_at).format('MMMM Do, YYYY')}
                      />
                    </Box>
                  </Box>
                  )}
                </Box>
                <Box className="add_btn">
                  <Button className="cancel_btn" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit">
                    {initialData?.id ? 'Update' : 'Add'}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </ModalLayoutStyle>
  );
};

export default AddStorageSettingForm;
