import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress } from '@mui/material';
import { UserStyle } from '../../../Layouts/style';
import { Pagination, Stack } from '@mui/material';
import BaseModal from '../../../Components/Modal/BaseModal';
import RoleHeading from './RoleHeading';
import AddRoleForm from '../../../Components/Form/AddRoleForm';
import RoleService from '../../../RestSevices/roleService';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import CommonDataTable from '../../../Components/DataTable/CommonDatable';
import DeleteDialog from '../../../Components/deleteDialog';
import moment from 'moment/moment';

const RoleList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [rolelist, setRoleList] = useState([]);
  const [roleForEdit, setRoleForEdit] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const { logout } = useAuth();

  const navigate = useNavigate();

  const fetchRoles = useCallback(async (searchQuery) => {
    // Fetch data from API
    setLoading(true);
    RoleService.getRoleList(page, searchQuery)
      .then((response) => {
        setRoleList(response.data);
        // setTotalPages(1);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }, []);

  // Debounced version of the fetchResults function
  const debouncedFetchResults = useCallback(debounce(fetchRoles, 300), []);

  useEffect(() => {
    debouncedFetchResults(search);
  }, [page, search]);

  const handleChangeList = (searchVal) => {
    setSearch(searchVal);
    debouncedFetchResults(searchVal);
    setPage(1);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const onEdit = (row) => {
    setRoleId(row.id);
    const selectedRole = rolelist.find(
      (role) => role._id === row.id
    );
    // setRoleForEdit(selectedRole);
    navigate(`/permission/${row.id}`, { state: { name: selectedRole.name, status: selectedRole.status } });
    // setModalOpen(true);
  };

  const onDelete = async () => {
    try {
      await RoleService.deleteRole(roleId).catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      setIsOpen(false);
      toast.success('Role deleted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchRoles();
    } catch (err) {
      console.log(err.message);
      toast.error('Error in deleting Role', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDelete = (row) => {
    setRoleId(row.id);
    setIsOpen(true);
  }
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleModal = (status, fetchData = false) => {
    setModalOpen(status);
    // if (fetchData) {
    //   fetchRoles();
    // }
  };
  const columns = [
    'name',
    'status',
    'types',
    'created_at',
    'action',
  ];
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Role Name',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    // {
    //   id: 'viewUsers',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'View Users',
    // },
    {
      id: 'types',
      numeric: false,
      disablePadding: true,
      label: 'Permissions',
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Last Updated on',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  const rows = [
    ...rolelist.map((role) => {
      return {
        id: role._id,
        name: role.name,
        status: role.status,
        types: role.permissions,
        created_at: moment(role.created_at).format('MMMM Do, YYYY'),
        ...role,
      };
    }),
  ];
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Roles</title>
      </Helmet>
      <Box className="user_area">
        <RoleHeading
          fetchRoles={fetchRoles}
          handleChangeList={handleChangeList}
        />
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
          <CommonDataTable
              rows={rows}
              headCells={headCells}
              columns={columns}
              handleEdit={onEdit}
              handleDelete={handleDelete}
            />
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
          </>
        )}

        <BaseModal
          open={modalOpen}
          onClose={(fetchData) => handleModal(false, fetchData)}
          title={`Edit Role`}
        >
          <AddRoleForm
            roleId={roleId}
            roleForEdit={roleForEdit}
            onClose={() => handleModal(false)}
          />
        </BaseModal>
        <DeleteDialog
          isOpen={isOpen}
          title="Delete Role?"
          message="Are you sure you want to delete this role?"
          setIsOpen={setIsOpen}
          onClose={handleClose}
          onDelete={onDelete}
        />
      </Box>
    </UserStyle>
  );
};

export default RoleList;
