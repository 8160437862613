import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Autocomplete,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DatatableHeadingStyle } from '../../../Layouts/style';
import { CloseIcon, SearchIcon } from '../../../Icons/SVGContainer';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const AlertsHeading = ({
  analytics,
  handleAnalyticsSelection,
  analyticsSelection,
  fromDate,
  toDate,
  handleFromDateChange,
  handleToDateChange,
  serachTerm,
  handleSearch,
  resetFilter,
  handleOnClickSearch,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (value) => {
    setSearchValue(value);
    handleAnalyticsSelection(value);
  };
  const handleInputChange = (event) => {
    if (!event.target.value) {
      // If input field is empty, reset the list
      setSearchValue(null);
      handleAnalyticsSelection(null);
    }
  };
  return (
    <DatatableHeadingStyle>
      <Box className="user_heading business_heading">
        <Typography component="h2">Alerts</Typography>
      </Box>
      <Box className="object_table_head_main">
        <Box className="object_table_head_left">
          <Autocomplete
            defaultValue={null} // Start with no initial value selected
            onChange={(event, value) => handleSearchChange(value)}
            options={analytics.map((item) => item.analytic_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Analytic"
                variant="outlined"
                style={{ width: '300px' }}
                onChange={handleInputChange}
              />
            )}
          />
          {/* {analytics.map((item) => (
        <MenuItem key={item.analytic_id} value={item.analytic_name} onClick={() => handleAnalyticsSelection(item.analytic_name)}>
          {item.analytic_name}
        </MenuItem>
      ))} */}
        </Box>
        <Box className="object_table_head_right">
          <TextField
            className="search"
            type="text"
            placeholder="Searching for....."
            value={serachTerm}
            onChange={handleSearch}
            onClick={handleOnClickSearch}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          {/* <TextField
          type="date"
          label="From Date"
          placeholder="Searching for....."
        />
        <TextField
          type="date"
          label="To Date"
          placeholder="Searching for....."
        /> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="From Date"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="To Date"
                value={toDate}
                onChange={handleToDateChange}
                disableFuture
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button
            onClick={resetFilter}
            disabled={!fromDate && !toDate && !serachTerm}
          >
            {' '}
            Reset{' '}
          </Button>
        </Box>
      </Box>
    </DatatableHeadingStyle>
  );
};

export default AlertsHeading;
