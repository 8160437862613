import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  TextField,
  CircularProgress
} from '@mui/material';
import { LeftArrow } from '../../../Icons/SVGContainer';
import { PermissionStyle, FormLayoutStyle } from '../../../Layouts/style';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import RoleService from '../../../RestSevices/roleService';
import PermissionService from '../../../RestSevices/permissionService';
import useAuth from '../../../Redux/Actions/authActions';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter} from '../../../Utils/dashboardHelper';
import { MuiColorInput } from 'mui-color-input';

const PermissionView = () => {
  const { id } = useParams();
  const location = useLocation();
  const { name, status, color } = location.state || {};
  const navigate = useNavigate();

  const { logout } = useAuth();

  const [roleId, setRoleId] = useState(null);
  const [permissionList, setPermissionList] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [roleData, setRoleData] = useState({});
  const [loading, setLoading] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const fetchRoleById = async (id) => {
    setLoading(true);
    let permissionTypes = [];
    let permissionData = [];
  
    try {
      const response = await RoleService.getRoleById(id);
  
      if (response.data.permissions.length > 0) {
        setRoleData(response.data);
        response.data.permissions.forEach((permission) => {
          // Ensure 'types' exists as an array
          const types = Array.isArray(permission.types) ? permission.types : [];
          types.forEach((type) => {
            permissionTypes.push({
              objectType: type.name,
              permissionId: permission._id,
            });
          });
          if(types.length > 0){
            permissionData.push({
              permissionId: permission._id,
              parentPermissionname: permission.name,
              objectType: types.length > 0 ? types.map((type) => type.name) : [], // Ensure objectType is an array
              created_at: permission.created_at,
            });
          }
        });
      }
  
      setAllPermissions(permissionTypes);
      setCheckedPermissions(permissionData);
    } catch (error) {
      if (error.response?.data?.error === 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error('Error fetching role data', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  const fetchPermissions = async () => {
    setLoading(true);
    PermissionService.getPermissionList()
      .then((response) => {
        setPermissionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  
  const groupPermissions = (permissions) => {
    const grouped = {};
  
    permissions.forEach(permission => {
      const { permissionId, parentPermissionname, objectType, created_at } = permission;
      
      if (!grouped[permissionId]) {
        grouped[permissionId] = {
          _id: permissionId,
          name: parentPermissionname,
          types: [],
          created_at: created_at ? created_at : moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]')
        };
      }
      objectType.forEach((objectType) => {
        if (!grouped[permissionId].types.some((type) => type.name === objectType)) {
          grouped[permissionId].types.push({ name: objectType });
        }
      })
    });

    return Object.values(grouped);
  };

  const handleCheckboxClick = (event, permissionId, subPermissionName, parentPermissionname) => {
    const permissionIndex = checkedPermissions.findIndex(
      (perm) => perm.permissionId === permissionId
    );
    if (event.target.checked) {
      if (permissionIndex !== -1) {
        const updatedCheckedPermissions = [...checkedPermissions];
        updatedCheckedPermissions[permissionIndex].objectType.push(subPermissionName);
        setCheckedPermissions(updatedCheckedPermissions);
      } else {
        setCheckedPermissions([
          ...checkedPermissions,
          {
            permissionId,
            parentPermissionname,
            objectType: [subPermissionName],
          },
        ]);
      }
    } else {
      if (permissionIndex !== -1) {
        const updatedCheckedPermissions = [...checkedPermissions];
        updatedCheckedPermissions[permissionIndex].objectType = updatedCheckedPermissions[permissionIndex].objectType.filter(
          (type) => type !== subPermissionName
        );
        // Remove the permission entirely if no sub-permissions are left
        if (updatedCheckedPermissions[permissionIndex].objectType.length === 0) {
          updatedCheckedPermissions.splice(permissionIndex, 1);
        }
        setCheckedPermissions(updatedCheckedPermissions);
      }
    }
  };

  const checkIfChecked = ({ permissionId, objectType }, checkAll = false) => {
    if (checkAll) {
      const permissionsForType = allPermissions.filter(
        (perm) => perm.permissionId === permissionId
      );
  
      return permissionsForType.every((perm) =>
        checkedPermissions.some(
          (checkedPerm) =>
            checkedPerm.permissionId === perm.permissionId &&
            perm.objectType.every((type) => checkedPerm.objectType.includes(type))
        )
      );
    } else {
      return checkedPermissions.some(
        (perm) =>
          perm.permissionId === permissionId &&
          perm.objectType.includes(objectType)
      );
    }
  };  
  
  const handleColorChange = (color) => {
    setRoleData({
      ...roleData,
      color: color,
    });
  };

  const handleRoleNameChange = (name) => {
    setRoleData({
      ...roleData,
      name: name,
    });
  }
  const handleSubmit = () => {
    if (checkedPermissions.length > 0 && roleData.name) {
      if (roleId) {
        setRoleData({
          ...roleData,
          permissions: groupPermissions(checkedPermissions)
        })
        setTriggerUpdate(true);
      } else {
        const newRole = {
          name,
          status: status === 1 ? 'active' : 'inactive',
          permissions: groupPermissions(checkedPermissions),
          color: color,
        };
        setRoleData(newRole);
        setTriggerUpdate(true);
      }
    } else {
      toast.error('Set Role Name and Permissions to Create/Update Role!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    if (triggerUpdate) {
      const updateRole = async () => {
        try {
          if (roleId) {
            const resp = await RoleService.updateRole(roleId, roleData);
            if (resp.status === 200) {
              toast.success('Role updated successfully!', {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate('/role-list');
            }
          } else {
            const resp = await RoleService.createRole(roleData);
            if (resp.status === 201) {
              toast.success('Role created successfully!', {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate('/role-list');
            }
          }
        } catch (err) {
          if (err.response.status === 401) {
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            logout();
          } else {
            toast.error('Error in creating/updating role permission', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } finally {
          setTriggerUpdate(false);
        }
      };
      updateRole();
    }
  }, [triggerUpdate, roleData, roleId, logout, navigate]);


  useEffect(() => {
    fetchPermissions();
    setRoleData({
      ...roleData,
      name: name,
      color: color ? color : '#fff',
    });
    if(id){
      setRoleId(id);
      fetchRoleById(id);
    }
  }, []);
  return (
    <PermissionStyle>
      <Box className="page_heading">
        <IconButton onClick={() => navigate(-1)}>
          <LeftArrow /> Permission
        </IconButton>
      </Box>
      {/* <Box className="permission_heading">
        <Box className="permission_name_hold">
          <Typography component="h3">Role Name</Typography>
        </Box>
        <Box className="permission_name_hold_right">
          <Typography component="h3">{name}</Typography>
        </Box>
      </Box> */}
      <FormLayoutStyle>
        {roleData && roleData.name ? 
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, marginTop: 2 }} className="form_body">
            <Box>
              <Typography variant="h6" component="div" sx={{ marginBottom: 1 }}>
                Role Name
              </Typography>
              <TextField
                value={roleData.name}
                fullWidth
                onChange={(e) => handleRoleNameChange(e.target.value)}
                sx={{ width: '300px' }}
              />
            </Box>
            <Box sx={{ marginLeft: 3 }}>
              <Typography variant="h6" component="div">
                Color
              </Typography>
              <MuiColorInput value={roleData.color} format="hex" onChange={handleColorChange} sx={{ marginTop: 1 }} size="small" variant="outlined" className='color_picker' />
            </Box>
          </Box> 
        : null}
      </FormLayoutStyle>
      {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
          {permissionList.map((permission, index) => {
            const isFirst = index === 0;
            const isLast = index === permissionList.length - 1;
            return (
              <Box key={permission._id} className={`permission_box_con ${isLast ? '' : 'border_item'}`}>
                <Box className={`permission_name ${isFirst ? 'first_name' : ''} ${isLast ? 'last_name' : ''}`}>
                  <Typography component="span" style={{ marginTop: '0.68rem', display: 'block' }}>{capitalizeFirstLetter(permission.name)}</Typography>
                </Box>
                <Box className="permission_box">
                  <Box className={`permission_box_con ${isFirst ? 'first_child' : ''} ${isLast ? 'last_child' : ''}`}>
                    {permission.types.map((subPermission, subIndex) => (
                      <FormControlLabel
                        key={`permission-${permission._id}-${subPermission.name}-${subIndex}`}
                        control={
                          <Checkbox
                            onChange={(event) => handleCheckboxClick(event, permission._id, subPermission.name, permission.name)}
                            checked={checkIfChecked({
                              permissionId: permission._id,
                              objectType: subPermission.name
                            }, false)}
                          />
                        }
                        label={`${subPermission.name === 'read' ? 'View' : 'Edit'}`}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            );
          })}
          </>
        )}
      <Box className="add_btn">
        <Button type="button" onClick={handleSubmit}>
          {roleId ? 'Update' : 'Save'}
        </Button>
      </Box>
    </PermissionStyle>
  );
};

export default PermissionView;
