import * as Yup from 'yup';

export type StorageSettingType = 'storage' | '';

export const addStorageSettingValidationSchema = (type: StorageSettingType) => {
  let schema = {
    key: Yup.string().required('Key is required'),
    description: Yup.string().required('Description is required'),
    values: Yup.object().shape({
      type: Yup.string(),
      uri: Yup.string(),
      client_id: Yup.string(),
      client_secret: Yup.string(),
      bucket: Yup.string(),
    }),
  };
  if (type === 'storage') {
    schema = {
     ...schema,
      values: Yup.object().shape({
        type: Yup.string().required('Storage Type is required'),
        uri: Yup.string().required('URI is required'),
        client_id: Yup.string().required('Client ID is required'),
        client_secret: Yup.string().required('Client Secret is required'),
        bucket: Yup.string().required('Bucket is required'),
      }),
    };
  }

  return Yup.object().shape(schema);
};
