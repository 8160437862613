import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useRef, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import { CrossIcon, UploadIcon } from '../../Icons/SVGContainer';
import { Formik } from 'formik';
import { videoUploadValidationSchema } from '../../Utils/validators';
import VideoUploadService from '../../RestSevices/videoUploadService';
import { toast } from 'react-toastify';
import { FileUploader } from 'react-drag-drop-files';
import useAuth from '../../Redux/Actions/authActions';
import dayjs from 'dayjs';

const VideoUploadForm = ({ handleVideoUploadForm, fetchData }) => {
  const [videoUploadState, setVideoUploaState] = useState({
    name: '',
    description: '',
    file: '',
    start_time: null,
  });
  const { logout } = useAuth();

  const [isUploading, setIsuploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const fileinput = useRef(null);
  const dateinput = useRef(null);

  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append('name', values.name.trim());
    formData.append('description', values.description);
    formData.append('video', values.file);
    formData.append('start_time', values.start_time);
    // If no errors, handle form submission here
    setIsuploading(true);
    VideoUploadService.uploadVideo(formData, setUploadProgress)
      .then((resp) => {
        const { error } = resp;
        if (error === 'Invalid Token!') {
          toast.error('Session expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          logout();
        }
        toast.success('Video uploaded successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        resetForm();
        handleVideoUploadForm();
        fetchData();
        setIsuploading(false);
        setUploadProgress(0);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          toast.error('Session expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          logout();
          return;
        }
        setIsuploading(false);
        setUploadProgress(0);
        toast.error(
          error.response?.data?.message
            ? error.response?.data?.message
            : error.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const getTimeStamp = (dtObj) => {
    let datum = Date.parse(dtObj);
    return datum / 1000;
  };
  const fileTypes = ['JPG', 'PNG', 'GIF'];
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Formik
          initialValues={videoUploadState}
          validationSchema={videoUploadValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Box className="form_field_area">
                <Typography component="h3">Video Upload Form</Typography>
                <FileUploader
                  classes={
                    props.values.file ? 'custom_upload hidden' : 'custom_upload'
                  }
                  handleChange={(file) => {
                    if (!file.type.includes('video')) {
                      props.setFieldError('file', 'Invalid file type');
                    } else {
                      props.setFieldValue('file', file);
                    }
                  }}
                  onDrop={(file) => {
                    if (!file.type.includes('video')) {
                      props.setFieldError('file', 'Invalid file type');
                    } else {
                      props.setFieldValue('file', file);
                    }
                  }}
                  name="file"
                >
                  <Typography component="label">
                    <Box className="custom_upload_info">
                      <UploadIcon />
                      <Typography>
                        Drag and Drop or Choose file to upload{' '}
                      </Typography>
                    </Box>
                    <FormHelperText error={!!props.errors.file}>
                      {props.errors.file}
                    </FormHelperText>
                  </Typography>
                </FileUploader>
                <Box
                  className={
                    props.values.file
                      ? 'custom_upload_filename show'
                      : 'custom_upload_filename'
                  }
                >
                  <Typography>
                    {props.values.file && (
                      <Typography>{props.values.file.name}</Typography>
                    )}
                  </Typography>
                  {props.values.file && (
                    <IconButton
                      onClick={() => {
                        props.setFieldValue('file', null);
                      }}
                    >
                      <CrossIcon />
                    </IconButton>
                  )}
                </Box>
                <Box className="form_box_hold">
                  <Box className="form_box_con">
                    <Typography component="label">Enter name</Typography>
                    <TextField
                      name="name"
                      type="text"
                      value={props.values.name}
                      onChange={(e) => {
                        if (
                          props.values.name.length == 0 &&
                          e.target.value == ' '
                        ) {
                          return;
                        }
                        props.handleChange(e);
                      }}
                      error={props.touched.name && !!props.errors.name}
                      helperText={props.touched.name ? props.errors.name : ''}
                    />
                  </Box>
                </Box>
                <Box className="form_box_hold">
                  <Box className="form_box_con date_time_picker">
                    <Typography component="label">Video Start Time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        name="start_time"
                        inputRef={dateinput}
                        views={[
                          'year',
                          'month',
                          'day',
                          'hours',
                          'minutes',
                          'seconds',
                        ]}
                        value={
                          props.values.start_time
                            ? new Date(props.values.start_time * 1000)
                            : null
                        }
                        onChange={(e) => {
                          props.setFieldValue('start_time', getTimeStamp(e.$d));
                        }}
                        maxDate={dayjs()}
                      />
                      <FormHelperText
                        error={
                          props.touched.start_time && !!props.errors.start_time
                        }
                      >
                        {props.touched.start_time
                          ? props.errors.start_time
                          : ''}
                      </FormHelperText>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box className="form_box_hold">
                  <Box className="form_box_con">
                    <Typography component="label">Enter description</Typography>
                    <TextField
                      name="description"
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      value={props.values.description}
                      onChange={props.handleChange}
                      error={
                        props.touched.description && !!props.errors.description
                      }
                      helperText={
                        props.touched.description
                          ? props.errors.description
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="add_btn">
                {isUploading ? (
                  <>
                    Uploading...
                    <CircularProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      className="cancel_btn"
                      onClick={handleVideoUploadForm}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Upload</Button>
                  </>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ModalLayoutStyle>
  );
};

export default VideoUploadForm;
