import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  DragEvent,
  MouseEvent,
  KeyboardEvent,
  useMemo
} from 'react'

import { Box } from '@mui/material'
import { CirclePlus, X } from 'lucide-react'
import ImgNoCamera from '../../../Assets/no-camera.svg'
import ImgDot from '../../../Assets/dot.svg'

import { toastSuccess, toastError } from '../../../Utils/hot-toasts'

import { ServiceTree, TabsView, DeleteModalTab } from '../Dashboard'
import DeviceOnlineShow from '../Device/DeviceOnlineShow'
import DeviceService from '../../../RestSevices/deviceService'
import UserService from '../../../RestSevices/userService'

import { Device, TabsState, CustomTabPanelProps } from './all-interfaces'
import { formatDateTime } from '../../../Utils/format-date-time'

const CustomTabPanel = React.memo(
  ({ children, value, index, ...other }: CustomTabPanelProps) => {
    const isVisible = useMemo(() => value === index, [value, index])

    return (
      <div
        role="tabpanel"
        hidden={!isVisible}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {isVisible && (
          <Box component={'div'} className="flex flex-wrap items-center">
            {children}
          </Box>
        )}
      </div>
    )
  }
)

function a11yProps(name: string) {
  return {
    id: `simple-tab-${name}`,
    'aria-controls': `simple-tabpanel-${name}`
  }
}

export function StreamHub() {
  const [devices, setDevices] = useState<Device[]>([])
  const [loading, setLoading] = useState(true)

  const [dateTime, setDateTime] = useState<Date>(new Date())

  const [value, setValue] = useState<number>(0)
  const [tabs, setTabs] = useState<TabsState>([])

  const [isTabModalOpen, setIsTabModalOpen] = useState<boolean>(false)
  const [selectedTabId, setSelectedTabId] = useState<string | null>(null)
  const [showInfo, setShowInfo] = useState(false)

  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // Fetch user data and initialize tabs and widgets
    const fetchUserData = async () => {
      try {
        // Substitua com o ID do usuário correto se necessário
        const response = await UserService.getUserById(
          '665cb9985cfb0188f712d148'
        )
        const userData = response.data
        console.log('userData: ', userData)

        // Verifica se userData e userData.tabs existem
        if (userData && Array.isArray(userData.tabs)) {
          // Inicialize os tabs com os dados retornados
          const fetchedTabs = userData.tabs.map((tab: any) => ({
            name: tab.name,
            isEditing: false,
            views: Array.isArray(tab.views)
              ? tab.views.map((view: any) => ({
                  device: view.device // Certifique-se de mapear o objeto de forma adequada
                }))
              : [] // Adiciona um array vazio se views não estiver presente ou não for um array
          }))

          console.log('fetchedTabs: ', fetchedTabs)
          setTabs(fetchedTabs)
        } else {
          console.log('No tabs data found or incorrect format')
          setTabs([]) // Define tabs como uma lista vazia se não houver dados
        }
      } catch (err) {
        console.error('Error fetching user data:', err)
      }
    }

    fetchUserData()
  }, [])

  useEffect(() => {
    console.log('payload tabs: ', tabs)
  }, [tabs])
  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (tab.isEditing) {
        const inputRef = inputRefs.current[index]
        if (inputRef) {
          inputRef.focus()
        }
      }
    })
  }, [tabs])

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await DeviceService.getDeviceList(1, '')
        setDevices(response.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }

    fetchDevices()
  }, [])

  function openTabModal(tabName: string) {
    setSelectedTabId(tabName)
    setIsTabModalOpen(true)
  }

  function closeTabModal() {
    setIsTabModalOpen(false)
  }

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue)
  }

  function handleTabDoubleClick(name: string) {
    setTabs(
      tabs.map(tab => (tab.name === name ? { ...tab, isEditing: true } : tab))
    )
  }

  function handleChangeInput(name: string, e: ChangeEvent<HTMLInputElement>) {
    const newName = e.target.value
    const trimmedNewName = newName.trim()

    const nameExists = tabs.some(
      tab => tab.name === trimmedNewName && tab.name !== name
    )

    if (nameExists || trimmedNewName === '') {
      if (newName === '' || trimmedNewName === '') {
        setTabs(
          tabs.map(tab => (tab.name === name ? { ...tab, name: newName } : tab))
        )
      } else {
        e.preventDefault()
        toastError(
          'A tab with this name already exists. Only deletion is allowed.'
        )
      }
    } else {
      setTabs(
        tabs.map(tab => (tab.name === name ? { ...tab, name: newName } : tab))
      )
    }
  }

  async function handleBlur(name: string) {
    const trimmedName = name.trim()

    if (trimmedName === '') {
      handleRemoveTab(name)
    } else {
      const updateTab = tabs.map(tab =>
        tab.name === name ? { ...tab, isEditing: false } : tab
      )
      setTabs(updateTab)

      UserService.updateTabs('665cb9985cfb0188f712d148', updateTab)
        .then(() => {})
        .catch(error => {
          console.error('Error updating tab views on server:', error)
          toastError('Failed to update the server.')
        })
    }
  }

  async function handleKeyDown(
    name: string,
    e: KeyboardEvent<HTMLInputElement>
  ) {
    if (e.key === 'Enter') {
      const trimmedName = name.trim()

      // Caso o nome da aba esteja vazio, remove a aba
      if (trimmedName === '') {
        await handleRemoveTab(name)
      } else {
        // Atualiza o estado local
        const updatedTabs = tabs.map(tab =>
          tab.name === name
            ? { ...tab, isEditing: false, name: trimmedName }
            : tab
        )
        setTabs(updatedTabs)

        // Atualiza o servidor
        try {
          await UserService.updateTabs('665cb9985cfb0188f712d148', updatedTabs)
        } catch (error) {
          console.error('Error updating tabs on server:', error)
          toastError('Failed to update the server.')
        }
      }
    }
  }

  function handleRemoveStream(deviceId: string, tabIndex: number) {
    setTabs(prevTabs => {
      // Atualiza o estado local
      const updatedTabs = prevTabs.map((tab, index) => {
        if (index === tabIndex) {
          return {
            ...tab,
            views: tab.views?.filter(view => view.device.id !== deviceId)
          }
        }
        return tab
      })

      // Envia a atualização para o servidor
      const tabId = updatedTabs[tabIndex].name
      UserService.updateTabViews(
        '665cb9985cfb0188f712d148', // Use o ID do usuário apropriado aqui
        tabId,
        updatedTabs[tabIndex].views
      )
        .then(() => {})
        .catch(error => {
          console.error('Error updating tab views on server:', error)
          toastError('Failed to update the server.')
        })

      return updatedTabs
    })
  }

  function handleOnDrag(e: DragEvent<HTMLLIElement>, device: Device) {
    if (tabs.length > 0) {
      e.dataTransfer.setData('device', JSON.stringify({ ...device }))
    }
  }

  function handleDoubleClick(device: Device) {
    if (tabs.length > 0) {
      const alreadyExists = tabs.some(
        (tab, index) =>
          index === value &&
          tab.views?.some(view => view.device.id === device.id)
      )

      if (alreadyExists) {
        toastError('This camera is already in this tab.')
        return
      }

      const updatedTabs = tabs.map((tab, index) =>
        index === value
          ? {
              ...tab,
              views: tab.views ? [...tab.views, { device }] : [{ device }]
            }
          : tab
      )
      setTabs(updatedTabs)

      const tabId = updatedTabs[value].name
      UserService.updateTabViews(
        '665cb9985cfb0188f712d148',
        tabId,
        updatedTabs[value].views
      )
        .then(() => {})
        .catch(error => {
          console.error('Error updating tab views on server:', error)
          toastError('Failed to update the server.')
        })
    } else {
      toastError('Create a new tab to add.')
    }
  }

  function handleOnDrop(e: DragEvent<HTMLDivElement>, tabIndex: number) {
    e.preventDefault()
    const data = e.dataTransfer.getData('device')

    if (!data) {
      toastError('Create a new tab to add.')
      return
    }

    try {
      const deviceData = JSON.parse(data) as Device
      if (deviceData) {
        // Atualiza a aba específica com o dispositivo
        setTabs(prevTabs => {
          const newTabs = [...prevTabs]
          const currentTab = newTabs[tabIndex]
          const alreadyExists = currentTab.views?.some(
            view => view.device.id === deviceData.id
          )

          if (alreadyExists) {
            toastError('This camera is already in this tab.')
            return prevTabs
          }

          // Adiciona o novo dispositivo à aba selecionada
          const updatedTab = {
            ...currentTab,
            views: [...(currentTab.views || []), { device: deviceData }]
          }

          newTabs[tabIndex] = updatedTab

          const tabId = newTabs[tabIndex].name
          UserService.updateTabViews(
            '665cb9985cfb0188f712d148',
            tabId,
            newTabs[tabIndex].views
          )
            .then(() => {
              toastSuccess('Device added successfully!')
            })
            .catch(error => {
              console.error('Error updating tab views on server:', error)
              toastError('Failed to update the server.')
            })
          return newTabs
        })
      } else {
        toastError('Invalid device data.')
      }
    } catch (error) {
      console.error('Error parsing device data:', error)
    }
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
  }

  function handleClick(e: MouseEvent<HTMLUListElement>) {
    e.stopPropagation()
  }

  function handleAddTab() {
    const newValue = tabs.length
    const newTab = { name: '', views: [], isEditing: true }

    setTabs([...tabs, newTab])
    setValue(newValue)
  }

  async function handleRemoveTab(name: string) {
    // Filtra as abas para remover a aba com o nome especificado
    const newTabs = tabs.filter(tab => tab.name !== name)

    // Atualiza o estado local com as abas restantes
    setTabs(newTabs)
    closeTabModal()

    // Atualiza a aba selecionada, se necessário
    if (value >= newTabs.length) {
      setValue(newTabs.length - 1)
    }

    try {
      // Atualiza o servidor com a lista de abas atualizada
      await UserService.updateTabs('665cb9985cfb0188f712d148', newTabs)
    } catch (error) {
      console.error('Error updating tabs on server:', error)
      toastError('Failed to update the server.')
    }
  }

  return (
    <Box className="dashboard_area">
      <ServiceTree
        devices={devices}
        handleOnDrag={handleOnDrag}
        handleDoubleClick={handleDoubleClick}
        handleClick={handleClick}
      />

      <Box className="dashboard_left w-full">
        <TabsView
          value={value}
          handleChange={handleChange}
          tabs={tabs}
          handleChangeInput={handleChangeInput}
          handleBlur={handleBlur}
          handleKeyDown={handleKeyDown}
          handleTabDoubleClick={handleTabDoubleClick}
          openTabModal={openTabModal}
          a11yProps={a11yProps}
          handleAddTab={handleAddTab}
          inputRefs={inputRefs}
        />

        <Box
          className="dashboard_left_con flex"
          component={'div'}
          onDrop={e => handleOnDrop(e, value)}
          onDragOver={handleDragOver}
        >
          {tabs.length === 0 ? (
            <div className="absolute top-[42%] left-[45%] space-y-1">
              <div className="text-sm text-zinc-200/25 text-center mt-8">
                Please create a tab before dragging a video.
              </div>
              <div
                onClick={handleAddTab}
                className="flex items-center text-zinc-200/40 justify-center gap-2 hover:cursor-pointer hover:text-white transition-all duration-150"
              >
                <p className="font-semibold text-base block">
                  Create a new tab
                </p>
                <CirclePlus className="size-4" />
              </div>
            </div>
          ) : (
            <>
              {tabs.map((tab, index) => (
                <CustomTabPanel key={tab.name} value={value} index={index}>
                  {tab.views?.map(view => (
                    <div
                      key={view.device.id}
                      className="relative inline hover:cursor-pointer border-none"
                    >
                      <DeviceOnlineShow
                        deviceId={view.device.id}
                        setShowInfo={setShowInfo}
                      />

                      <button
                        className="absolute top-1 right-1 text-white/30 p-0.5 group z-50 bg-gray-950/55 rounded-sm"
                        onClick={() =>
                          handleRemoveStream(view.device.id, index)
                        }
                      >
                        <X
                          size={12}
                          className="group-hover:scale-110 hover:text-white transition-all duration-150"
                        />
                      </button>

                      {showInfo && (
                        <>
                          <div
                            title={`${view.device.name} - ${formatDateTime(
                              dateTime
                            )}`}
                            className="text-[9px] font-medium absolute top-0 left-0 text-white rounded-sm px-1 py-1 bg-gradient-to-t from-gray-50/5 via-gray-900/10 to-black/10 h-full w-full"
                          >
                            <div className="flex flex-col justify-center">
                              <div className="flex items-center ">
                                <div className="bg-gray-950/55 rounded-sm flex p-0.5">
                                  <img
                                    src={ImgDot}
                                    alt=""
                                    className="mr-0.5 animate-pulse"
                                  />
                                  {view.device.name}
                                </div>
                              </div>
                            </div>
                          </div>

                          <button
                            className="absolute top-1 right-1  text-white/70 p-0.5 group bg-gray-950/55 rounded-sm "
                            onClick={() =>
                              handleRemoveStream(view.device.id, index)
                            }
                          >
                            <X
                              size={12}
                              className="group-hover:scale-110 transition-all duration-150"
                            />
                          </button>
                        </>
                      )}
                    </div>
                  ))}
                </CustomTabPanel>
              ))}

              {/* Show message only if there are no views in any tab */}
              {tabs.every(tab => tab.views?.length === 0) && (
                <div className="absolute top-[45%] left-[45%]">
                  <img
                    src={ImgNoCamera}
                    alt=""
                    className="mx-auto"
                    draggable="false"
                  />
                  <div className="text-sm text-gray-200/30 text-center mt-4">
                    There are no cameras. <br />
                    To see the camera, drag it here from the tree.
                  </div>
                </div>
              )}
            </>
          )}
        </Box>
      </Box>

      {isTabModalOpen && (
        <DeleteModalTab
          closeTabModal={closeTabModal}
          handleRemoveTab={handleRemoveTab}
          tabName={selectedTabId!}
        />
      )}
    </Box>
  )
}
