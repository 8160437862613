export const formatNochart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }

  if (settings.statsType.title === 'Total no') {
    return {
      name: settings.name,
      data: alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
      type: 'no_chart',
      statsType: settings.statsType.title,
    };
  }
  if (settings.statsType.title === 'Average') {
    let count = alerts.length;
    if (count > 0) {
      if (range == '24hours') {
        count = count / 24;
      }
      if (range == 'week' || range == 'last7days') {
        count = count / 7;
      }
      if (range == 'month' || range == 'last30days') {
        count = count / 30;
      }
      if (range == 'lastYear' || range == 'thisYear') {
        count = count / 12;
      }
    }
    return {
      name: settings.name,
      data: Math.round(count),
      type: 'no_chart',
      statsType: settings.statsType.title,
    };
  }
  if (settings.statsType.title === 'Total no per hour') {
    let count = alerts.length;
    if (count > 0) {
      if (range == '24hours') {
        count = count / 24;
      }
      if (range == 'week') {
        count = count / 7;
      }
      if (range == 'month') {
        count = count / 30;
      }
    }
    return {
      name: settings.name,
      data: count,
      type: 'no_chart',
      statsType: settings.statsType.title,
    };
  }
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const dateRange = [
  {
    label: 'Last 24 hours',
    value: '24hours',
  },
  {
    label: 'Last 7 days',
    value: 'last7days',
  },
  {
    label: 'Last 30 days',
    value: 'last30days',
  },
  {
    label: 'This Week',
    value: 'week',
  },
  {
    label: 'This Month',
    value: 'month',
  },
  {
    label: 'Last One Year',
    value: 'lastYear',
  },
  {
    label: 'This Year',
    value: 'thisYear',
  },
];

export const formatBarChart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }
  const x_axisObject = constructXaxis(range);

  const y_axises = constructYaxis(settings, alerts, x_axisObject, range);
  return y_axises;
};

export const formatLineChart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }
  const x_axisObject = constructXaxis(range);

  const y_axises = constructLineYaxis(settings, alerts, x_axisObject, range);
  return y_axises;
};

export const formatPieChart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }
  const data = constructPieChartData(settings, alerts, range);
  return data;
};

const constructXaxis = (range) => {
  if (range == '24hours') {
    const xAxis = [];
    const now = new Date();
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

    // Loop through the last 24 hours starting from 24 hours ago
    for (
      let time = twentyFourHoursAgo.getTime();
      time <= now.getTime();
      time += 60 * 60 * 1000
    ) {
      const hour = new Date(time);
      const formattedHour = hour.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true,
      });
      xAxis.push({ datetime: hour, x_axis: formattedHour });
    }

    return xAxis;
  }

  if (range == 'last7days') {
    const xAxis = [];
    const now = new Date(); // Current date and time

    // Loop through the last 7 days starting from today
    for (let i = 0; i < 7; i++) {
      const date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000); // Subtract days in milliseconds
      //   const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; // Format date as dd/mm/yyyy
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`; // Format date as dd/mm/yyyy
      xAxis.push({ datetime: date, x_axis: formattedDate });
    }

    return xAxis.reverse();
  }

  if (range == 'week') {
    const xAxis = [];
    const now = new Date(); // Current date and time
    const today = now.getDay(); // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
    const startOfWeek = new Date(now.getTime() - today * 24 * 60 * 60 * 1000); // Start of the week (Sunday)

    // Loop through the days of the current week starting from Sunday
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek.getTime() + i * 24 * 60 * 60 * 1000); // Add days in milliseconds
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`; // Format date as dd/mm/yyyy
      xAxis.push({ datetime: date, x_axis: formattedDate });
    }

    return xAxis;
  }

  if (range == 'last30days') {
    const xAxis = [];
    const now = new Date(); // Current date and time

    // Loop through the last 30 days starting from today
    for (let i = 0; i < 30; i++) {
      const date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000); // Subtract days in milliseconds
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`; // Format date as dd/mm/yyyy
      xAxis.push({ datetime: date, x_axis: formattedDate });
    }

    return xAxis.reverse();
  }

  if (range == 'month') {
    const xAxis = [];
    const now = new Date(); // Current date and time
    const currentMonth = now.getMonth(); // Get the current month (0-indexed)
    const currentYear = now.getFullYear(); // Get the current year
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // First day of the current month

    // Loop through the days of the current month starting from the first day
    for (
      let i = 0;
      i < new Date(currentYear, currentMonth + 1, 0).getDate();
      i++
    ) {
      const date = new Date(currentYear, currentMonth, i + 1); // Create date object for each day
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`; // Format date as dd/mm/yyyy
      xAxis.push({ datetime: date, x_axis: formattedDate });
    }

    return xAxis;
  }

  if (range === 'lastYear') {
    const xAxis = [];
    const now = new Date(); // Current date and time
    const currentMonth = now.getMonth(); // Get the current month (0-indexed)
    const currentYear = now.getFullYear(); // Get the current year

    // Loop through each month of the previous year
    for (let month = currentMonth; month < currentMonth + 12; month++) {
      if (month < 12) {
        const date = new Date(currentYear - 1, month, 1); // First day of each month
        const formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`; // Format date as 'Month Year' (e.g., March 2023)
        xAxis.push({ datetime: date, x_axis: formattedDate });
      } else {
        const date = new Date(currentYear, month - 11, 1); // First day of each month
        const formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`; // Format date as 'Month Year' (e.g., March 2023)
        xAxis.push({ datetime: date, x_axis: formattedDate });
      }
    }

    return xAxis;
  }

  if (range === 'thisYear') {
    const xAxis = [];
    const now = new Date(); // Current date and time
    const currentYear = now.getFullYear(); // Get the current year

    // Loop through each month of the previous year
    for (let month = 0; month < 12; month++) {
      const date = new Date(currentYear, month, 1); // First day of each month
      const formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`; // Format date as 'Month Year' (e.g., March 2023)
      xAxis.push({ datetime: date, x_axis: formattedDate });
    }

    return xAxis;
  }
};

const constructYaxis = (settings, alertsList, x_axisObjects, range) => {
  if (range == '24hours') {
    return x_axisObjects.map((x_axisObject) => {
      const datetimeString = x_axisObject.datetime;
      const datetime = new Date(datetimeString);
      // Set the datetime to the start of the hour
      const startHour = new Date(datetime);
      startHour.setMinutes(0);
      startHour.setSeconds(0);
      startHour.setMilliseconds(0);
      const startTimestamp = startHour.getTime();

      // Set the datetime to the end of the hour
      const endHour = new Date(datetime);
      endHour.setMinutes(59);
      endHour.setSeconds(59);
      endHour.setMilliseconds(999);
      const endTimestamp = endHour.getTime();

      const alerts = alertsList.filter((alert) => {
        const alertTimestamp = alert.timestamp * 1000;
        return (
          alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp
        );
      });
      return {
        data: alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
        type: 'bar_chart',
        x_axis: x_axisObject.x_axis,
        statsType: settings.statsType.title,
        x_axis_label: 'Hour',
      };
    });
  }

  if (
    range == 'last7days' ||
    range == 'week' ||
    range == 'last30days' ||
    range == 'month'
  ) {
    return x_axisObjects.map((x_axisObject) => {
      const datetimeString = x_axisObject.datetime;
      const datetime = new Date(datetimeString);
      // Set the datetime to the start of the hour
      const startHour = new Date(datetime);
      startHour.setHours(0);
      startHour.setMinutes(0);
      startHour.setSeconds(0);
      startHour.setMilliseconds(0);
      const startTimestamp = startHour.getTime();
      // Set the datetime to the end of the hour
      const endHour = new Date(datetime);
      endHour.setHours(23);
      endHour.setMinutes(59);
      endHour.setSeconds(59);
      endHour.setMilliseconds(999);
      const endTimestamp = endHour.getTime();

      const alerts = alertsList.filter((alert) => {
        const alertTimestamp = alert.timestamp * 1000;
        return (
          alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp
        );
      });
      return {
        data: alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
        type: 'bar_chart',
        x_axis: x_axisObject.x_axis,
        statsType: settings.statsType.title,
        x_axis_label: 'Date',
      };
    });
  }

  if (range === 'lastYear' || range === 'thisYear') {
    return x_axisObjects.map((x_axisObject) => {
      const datetimeString = x_axisObject.datetime;
      const datetime = new Date(datetimeString);

      // Set the datetime to the start of the month
      const startOfMonth = new Date(
        datetime.getFullYear(),
        datetime.getMonth(),
        1,
        0,
        0,
        0
      );
      const startTimestamp = startOfMonth.getTime();

      // Set the datetime to the end of the month
      const endOfMonth = new Date(
        datetime.getFullYear(),
        datetime.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );
      const endTimestamp = endOfMonth.getTime();

      const alerts = alertsList.filter((alert) => {
        const alertTimestamp = alert.timestamp * 1000;
        return (
          alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp
        );
      });
      return {
        data: alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
        type: 'bar_chart',
        x_axis: x_axisObject.x_axis,
        statsType: settings.statsType.title,
        x_axis_label: 'Date',
      };
    });
  }
};

const constructLineYaxis = (settings, alertsList, x_axisObjects, range) => {
  if (range == '24hours') {
    const series = settings.chart_parameters;
    if (!series.includes('none')) {
      return series.map((val) => {
        let data = x_axisObjects.map((x_axisObject) => {
          const datetimeString = x_axisObject.datetime;
          const datetime = new Date(datetimeString);
          // Set the datetime to the start of the hour
          const startHour = new Date(datetime);
          startHour.setMinutes(0);
          startHour.setSeconds(0);
          startHour.setMilliseconds(0);
          const startTimestamp = startHour.getTime();

          // Set the datetime to the end of the hour
          const endHour = new Date(datetime);
          endHour.setMinutes(59);
          endHour.setSeconds(59);
          endHour.setMilliseconds(999);
          const endTimestamp = endHour.getTime();

          const alerts = alertsList.filter((alert) => {
            const alertTimestamp = alert.timestamp * 1000;
            return (
              alertTimestamp >= startTimestamp &&
              alertTimestamp <= endTimestamp &&
              alert.classes.split(',').includes(val)
            );
          });
          return {
            data:
              alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
            type: 'line_chart',
            x_axis: x_axisObject.x_axis,
            statsType: settings.statsType.title,
            x_axis_label: 'Hour',
          };
        });

        return {
          series_name: val,
          data: data,
          color: getRandomColor(),
        };
      });
    } else {
      let data = x_axisObjects.map((x_axisObject) => {
        const datetimeString = x_axisObject.datetime;
        const datetime = new Date(datetimeString);
        // Set the datetime to the start of the hour
        const startHour = new Date(datetime);
        startHour.setMinutes(0);
        startHour.setSeconds(0);
        startHour.setMilliseconds(0);
        const startTimestamp = startHour.getTime();

        // Set the datetime to the end of the hour
        const endHour = new Date(datetime);
        endHour.setMinutes(59);
        endHour.setSeconds(59);
        endHour.setMilliseconds(999);
        const endTimestamp = endHour.getTime();

        const alerts = alertsList.filter((alert) => {
          const alertTimestamp = alert.timestamp * 1000;
          return (
            alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp
          );
        });
        return {
          data:
            alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
          type: 'line_chart',
          x_axis: x_axisObject.x_axis,
          statsType: settings.statsType.title,
          x_axis_label: 'Hour',
        };
      });

      return [
        {
          series_name: settings.statsType.title,
          data: data,
          color: getRandomColor(),
        },
      ];
    }
  }

  if (
    range == 'last7days' ||
    range == 'week' ||
    range == 'last30days' ||
    range == 'month'
  ) {
    const series = settings.chart_parameters;
    if (!series.includes('none')) {
      return series.map((val) => {
        let data = x_axisObjects.map((x_axisObject) => {
          const datetimeString = x_axisObject.datetime;
          const datetime = new Date(datetimeString);
          // Set the datetime to the start of the hour
          const startHour = new Date(datetime);
          startHour.setHours(0);
          startHour.setMinutes(0);
          startHour.setSeconds(0);
          startHour.setMilliseconds(0);
          const startTimestamp = startHour.getTime();
          // Set the datetime to the end of the hour
          const endHour = new Date(datetime);
          endHour.setHours(23);
          endHour.setMinutes(59);
          endHour.setSeconds(59);
          endHour.setMilliseconds(999);
          const endTimestamp = endHour.getTime();

          const alerts = alertsList.filter((alert) => {
            const alertTimestamp = alert.timestamp * 1000;
            return (
              alertTimestamp >= startTimestamp &&
              alertTimestamp <= endTimestamp &&
              alert.classes.split(',').includes(val)
            );
          });
          return {
            data:
              alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
            type: 'line_chart',
            x_axis: x_axisObject.x_axis,
            statsType: settings.statsType.title,
            x_axis_label: 'Date',
          };
        });

        return {
          series_name: val,
          data: data,
          color: getRandomColor(),
        };
      });
    } else {
      let data = x_axisObjects.map((x_axisObject) => {
        const datetimeString = x_axisObject.datetime;
        const datetime = new Date(datetimeString);
        // Set the datetime to the start of the hour
        const startHour = new Date(datetime);
        startHour.setHours(0);
        startHour.setMinutes(0);
        startHour.setSeconds(0);
        startHour.setMilliseconds(0);
        const startTimestamp = startHour.getTime();
        // Set the datetime to the end of the hour
        const endHour = new Date(datetime);
        endHour.setHours(23);
        endHour.setMinutes(59);
        endHour.setSeconds(59);
        endHour.setMilliseconds(999);
        const endTimestamp = endHour.getTime();
        const alerts = alertsList.filter((alert) => {
          const alertTimestamp = alert.timestamp * 1000;
          return (
            alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp
          );
        });
        return {
          data:
            alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',

          type: 'line_chart',
          x_axis: x_axisObject.x_axis,
          statsType: settings.statsType.title,
          x_axis_label: 'Date',
        };
      });

      return [
        {
          series_name: settings.statsType.title,
          data: data,
          color: getRandomColor(),
        },
      ];
    }
  }

  if (range === 'lastYear' || range === 'thisYear') {
    const series = settings.chart_parameters;
    if (!series.includes('none')) {
      return series.map((val) => {
        let data = x_axisObjects.map((x_axisObject) => {
          const datetimeString = x_axisObject.datetime;
          const datetime = new Date(datetimeString);

          // Set the datetime to the start of the month
          const startOfMonth = new Date(
            datetime.getFullYear(),
            datetime.getMonth(),
            1,
            0,
            0,
            0
          );
          const startTimestamp = startOfMonth.getTime();

          // Set the datetime to the end of the month
          const endOfMonth = new Date(
            datetime.getFullYear(),
            datetime.getMonth() + 1,
            0,
            23,
            59,
            59,
            999
          );
          const endTimestamp = endOfMonth.getTime();

          const alerts = alertsList.filter((alert) => {
            const alertTimestamp = alert.timestamp * 1000;
            return (
              alertTimestamp >= startTimestamp &&
              alertTimestamp <= endTimestamp &&
              alert.classes.split(',').includes(val)
            );
          });
          return {
            data:
              alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
            type: 'line_chart',
            x_axis: x_axisObject.x_axis,
            statsType: settings.statsType.title,
            x_axis_label: 'Date',
          };
        });
        return {
          series_name: val,
          data: data,
          color: getRandomColor(),
        };
      });
    } else {
      let data = x_axisObjects.map((x_axisObject) => {
        const datetimeString = x_axisObject.datetime;
        const datetime = new Date(datetimeString);

        // Set the datetime to the start of the month
        const startOfMonth = new Date(
          datetime.getFullYear(),
          datetime.getMonth(),
          1,
          0,
          0,
          0
        );
        const startTimestamp = startOfMonth.getTime();

        // Set the datetime to the end of the month
        const endOfMonth = new Date(
          datetime.getFullYear(),
          datetime.getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        );
        const endTimestamp = endOfMonth.getTime();
        const alerts = alertsList.filter((alert) => {
          const alertTimestamp = alert.timestamp * 1000;
          return (
            alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp
          );
        });
        return {
          data:
            alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',

          type: 'line_chart',
          x_axis: x_axisObject.x_axis,
          statsType: settings.statsType.title,
          x_axis_label: 'Date',
        };
      });

      return [
        {
          series_name: settings.statsType.title,
          data: data,
          color: getRandomColor(),
        },
      ];
    }
  }
};

const constructPieChartData = (settings, alertsList, range) => {
  const series = settings.chart_parameters;

  const data = series.map((val) => {
    const alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(val);
    });

    let count = alerts.length;
    if (count > 0 && settings.statsType.title == 'Average') {
      if (range == '24hour') {
        count = count / 24;
      }
      if (range == 'week' || range == 'last7days') {
        count = count / 7;
      }
      if (range == 'month' || range == 'last30days') {
        count = count / 30;
      }
      if (range == 'lastYear' || range == 'thisYear') {
        count = count / 12;
      }
    }
    return {
      name: val,
      data: Math.round(count),
      color: getRandomColor(),
    };
  });

  return data;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const generateUsername = (email) => {
  if (!email) return '';
  const usernameBase = email.split('@')[0].replace(/[^a-zA-Z0-9]/g, '');
  const randomSuffix = Math.random().toString(36).substring(2, 8); // Generates a random alphanumeric string of length 6
  return `${usernameBase}_${randomSuffix}`;
};

export const generatePassword = (length = 12) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};

export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied to clipboard');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Copied to clipboard');
    } catch (err) {
      console.error('Could not copy text: ', err);
    }
    document.body.removeChild(textArea);
  }
};

export const base64ToUrl = (base64) => {
  if (!base64) {
    return null;
  }
  const url = `data:image/png;base64,${base64}`;
  return url;
}

