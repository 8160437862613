import axios from '../Utils/api'

const DeviceService = {
  createDevice: async data => {
    return axios.post('/devices', data)
  },

  getDeviceList: (page, searchTerm) => {
    return axios.get(`/devices`, {
      params: {
        page: page,
        limit: 10,
        name: searchTerm
      }
    })
  },

  updateDevice: (deviceHashId, deviceData) => {
    return axios.put(`/devices/${deviceHashId}`, deviceData)
  },

  deleteDevice: cameraHashId => {
    return axios.delete(`/devices/${cameraHashId}`)
  },

  onlineDevice: (deviceId, offer) => {
    return axios.post(`/devices/${deviceId}/online`, { offer: offer })
  }
}

export default DeviceService
