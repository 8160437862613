import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Define the colors for different statuses
const statusColors = {
  active: '#4caf50',  // green
  inactive: '#f44336',  // red
  pending: '#ff9800',  // orange
  unknown: '#9e9e9e',  // grey
};

// Create a styled Box component for the circle
const StatusCircle = styled(Box)(({ status, width, height }) => ({
  borderRadius: '50%',
  backgroundColor: statusColors[status] || statusColors.unknown,
  display: 'inline-block',
}));

const StatusIndicator = ({ status, width, height }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <StatusCircle status={status} width={width} height={height} />
    </Box>
  );
};

export default StatusIndicator;
