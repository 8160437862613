import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

function EnhancedTableHead(props) {
    const { headCells } = props;

    return (
        <TableHead>
            <TableRow className="table_head">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const VideoListDataTable = ({ rows, headCells, columns, onClick }) => {
    const visibleRows = rows;

    function formatTimestamp(timestamp) {
        // Convert timestamp to milliseconds if it's in seconds
        if (timestamp.toString().length <= 10) {
            timestamp *= 1000;
        }
        
        const date = new Date(+timestamp);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        // Convert hours from 24-hour format to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight
    
        // Add leading zero to minutes if less than 10
        const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
    
        const formattedDate = `${month} ${day}, ${year}, ${hours}:${paddedMinutes} ${ampm}`;
    
        return formattedDate;
    }

    const showData = (row,col) =>{
        if(col === 'added_by'){
            return row.created_by.firstname+ " " +row.created_by.lastname
        }else if(col === 'start_time' || col === 'createdAt'){
            return  formatTimestamp(row[col])
        }else{
            return row[col]
        }
    }

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
                className="datatable_common"
            >
                <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
                <TableBody>
                    {visibleRows.map((row) => {
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id}
                                sx={{ cursor: 'pointer' }}
                            >
                                {columns.map((col, index) => (
                                    <TableCell style={{ maxWidth:"100px", whiteSpace: "pre-wrap",overflowWrap: 'break-word' }} align={index === 0 ? 'left' : 'right'} key={col}>
                                        {showData(row,col)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VideoListDataTable;
