import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@mui/material';

import React, { useState, useEffect } from 'react';

import { DashboardStyle } from '../../../Layouts/style';
import { TimeDurationIcon, TotalPeopleIcon } from '../../../Icons/SVGContainer';
import BarChart from '../../../Components/Chart/BarChart';
import AnalyticsService from '../../../RestSevices/analyticsService';
import AlertsService from '../../../RestSevices/alertService';
import ChartModal from '../../../Components/Modal/ChartModal';

import {
  dateRange,
  formatBarChart,
  formatNochart,
  formatLineChart,
  formatPieChart,
  getRandomColor,
} from '../../../Utils/dashboardHelper';

import LineChart from '../../../Components/Chart/LineChart';
import PieChart from '../../../Components/Chart/PieChart';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';

const DashboardViewTwo = () => {
  const [analytics, setAnalytics] = useState('');
  const [analyticsList, setAnalyticsList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [alertsList, setAlertsList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [range, setRange] = useState('24hours');
  const [selectedChartData, setSelectedChartData] = useState({});
  const [openChartModal, setOpenChartModal] = useState(false);
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleAnalyticsChange = (event) => {
    setAnalytics(event.target.value);
  };

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const goToSettings = () => {
    navigate('/dashboard-settings');
  };

  const handleOpenChartModal = (type, data) => {
    setSelectedChartData({ type, data });
    setOpenChartModal(true);
  };

  const fetchData = async () => {
    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
        setAnalytics(resp.data[0]?.hash_id);
      })
      .catch((err) => {
        if (err.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.log(err);
      });
  };

  const fetchAllSettings = async () => {
   
  };

  const fetchAlerts = async () => {
    try {
      let selectedAnalyctis;
      analyticsList.map((data) => {
        if (data.hash_id == analytics) {
          selectedAnalyctis = data.analytic_name;
        }
      });
      const alertsListResp = await AlertsService.getDashboardAlerts({
        analytics: selectedAnalyctis,
        range,
      });
      setAlertsList(alertsListResp.data);
    } catch (error) {
      if (error.response?.data?.error == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log(error);
    }
  };

  useEffect(() => {
    formatDashboardData();
  }, [settingsList, alertsList]);

  const formatDashboardData = () => {
    let tempData = [];
    settingsList.map((data) => {
      if (data.chart == 'no_chart') {
        tempData.push(formatNochart(data, alertsList, range));
      }
      if (data.chart == 'bar_chart') {
        tempData.push({
          name: data.name,
          data: formatBarChart(data, alertsList, range),
          type: 'bar_chart',
          color: getRandomColor(),
        });
      }
      if (data.chart == 'line_chart') {
        tempData.push({
          name: data.name,
          data: formatLineChart(data, alertsList, range),
          type: 'line_chart',
        });
      }
      if (data.chart == 'pie_chart') {
        tempData.push({
          name: data.name,
          data: formatPieChart(data, alertsList, range),
          type: 'pie_chart',
        });
      }
    });
    setDataList(tempData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (analytics) {
      fetchAllSettings();
      fetchAlerts();
    }
  }, [analytics]);

  useEffect(() => {
    if (analytics) {
      fetchAlerts();
    }
  }, [range]);
  return (
    <DashboardStyle>
      <Box className="dashboard_area">
        <Box className="dashboard_left">
          <Box className="dashboard_heading">
            <Typography component="h2">Dashboard</Typography>
            <Box className="dash_select_area">
              <Select
                value={analytics}
                onChange={handleAnalyticsChange}
                displayEmpty
              >
                {analyticsList.map((analytic) => {
                  return (
                    <MenuItem value={analytic.hash_id}>
                      {analytic.analytic_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select value={range} onChange={handleRangeChange} displayEmpty>
                {dateRange.map((data) => {
                  return <MenuItem value={data.value}>{data.label}</MenuItem>;
                })}
              </Select>
              <IconButton onClick={goToSettings}>
                <img src="/images/settings_icon.svg" alt="" />
              </IconButton>
            </Box>
          </Box>
          <Box className="dashboard_top_info">
            {dataList.map((data) => {
              return data.type == 'no_chart' ? (
                <Box className="dashboard_top_info_box">
                  <Box>
                    <Typography>{data.name}</Typography>
                    <Typography component="h2">{data.data}</Typography>
                  </Box>
                  <Box className="dashboard_top_info_icon">
                    {data.statsType == 'Average' ? (
                      <TimeDurationIcon />
                    ) : (
                      <TotalPeopleIcon />
                    )}
                  </Box>
                </Box>
              ) : (
                ''
              );
            })}
          </Box>
          <Grid container spacing={2} className="dashboard_chart_area">
            {dataList.map((data) => {
              return data.type == 'bar_chart' ? (
                <Grid item lg={6} md={12}>
                  <div className="dashboard_chart_con">
                    <Box className="dashboard_chart_head">
                      <Typography component="h3">
                        {data.name.toUpperCase()}
                        <Tooltip title="Expand Chart">
                          <IconButton
                            onClick={() => handleOpenChartModal('bar', data)}
                          >
                            <img src="/images/expand_icon.svg" alt="" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Box>
                    <BarChart configData={data} type="bar" />
                  </div>
                </Grid>
              ) : null;
            })}
            {dataList.map((data) => {
              return data.type == 'line_chart' ? (
                <Grid item lg={6} md={12}>
                  <div className="dashboard_chart_con">
                    <Box className="dashboard_chart_head">
                      <Typography component="h3">
                        {data.name.toUpperCase()}
                        <Tooltip title="Expand Chart">
                          <IconButton
                            onClick={() => handleOpenChartModal('line', data)}
                          >
                            <img src="/images/expand_icon.svg" alt="" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Box>
                    <LineChart configData={data} type="line" />
                  </div>
                </Grid>
              ) : null;
            })}
            {dataList.map((data) => {
              return data.type == 'pie_chart' ? (
                <Grid item lg={6} md={12}>
                  <div className="dashboard_chart_con">
                    <Box className="dashboard_chart_head">
                      <Typography component="h3">
                        {data.name.toUpperCase()}
                        <Tooltip title="Expand Chart">
                          <IconButton
                            onClick={() => handleOpenChartModal('pie', data)}
                          >
                            <img src="/images/expand_icon.svg" alt="" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Box>
                    <PieChart configData={data} type="pie" />
                  </div>
                </Grid>
              ) : null;
            })}
          </Grid>
          <Box className="dashboard_camera_pic">
            <Box>
              <Typography component="h3">Camera pictures</Typography>
              <img src="/images/cam_pic.png" alt="" />
            </Box>
            <Box>
              <Typography component="h3">Camera paths</Typography>
              <img src="/images/cam_pic.png" alt="" />
            </Box>
          </Box>
        </Box>
        <Box className="dashboard_right">
          <Typography component="h3">Alerts</Typography>
          <Box component="ul">
            <Typography component="li">
              Alene women detection at 6.30pm
            </Typography>
            <Typography component="li">
              Stone pelting found at 6.30pm
            </Typography>
            <Typography component="li">Women assult found at 6.30pm</Typography>
            <Typography component="li">
              Alene women detection at 6.30pm
            </Typography>
            <Typography component="li">
              Stone pelting found at 6.30pm
            </Typography>
            <Typography component="li">Women assult found at 6.30pm</Typography>
          </Box>
        </Box>
        <ChartModal
          open={openChartModal}
          onClose={() => {
            setOpenChartModal(false);
            setSelectedChartData({});
          }}
          data={selectedChartData.data}
          type={selectedChartData.type}
          title={selectedChartData.data?.name.toUpperCase()}
          sx={{ minWidth: '1000px !important' }}
        >
          <Box>chart here</Box>
        </ChartModal>
      </Box>
    </DashboardStyle>
  );
};

export default DashboardViewTwo;
