import { useDispatch, useSelector } from 'react-redux'
import { selectUser, selectIsAuthenticated, actions } from '../Slice/auth'
import profileService from '../../RestSevices/profileService'
import { toast } from 'react-toastify'
import { useKeycloak } from '@react-keycloak/web'
import { toastSuccess, toastError } from '../../Utils/hot-toasts'

export default function useAuth() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const { keycloak } = useKeycloak()

  const tenant_login = async ({ token, authenticated }) => {
    try {
      if (!authenticated) {
        toastError('Invalid credentials')
        // toast.error('Invalid credentials', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      } else {
        toastSuccess('Logged in successfully!')
        // toast.success('Logged in successfully!', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        const profile = await profileService.getProfileData(token)
        // console.log('token', token);
        dispatch(
          actions.login({
            token: token,
            user: profile
          })
        )
      }
    } catch (error) {
      console.error('Login error:', error)
    }
  }
  const logout = async () => {
    try {
      toast.success('Logged out successfully!', {
        position: toast.POSITION.TOP_RIGHT
      })
      dispatch(actions.logout())
      keycloak.logout({
        redirectUri: window.location.origin // Redirect URL after logout
      })
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  return {
    tenant_login,
    logout,
    isAuthenticated,
    user
  }
}
