import axios from '../Utils/api';

const RoleService = {
  createRole: async(data) => {
    return axios.post('/roles', data);
  },

  getRoleList: (page, searchTerm) => {
    return axios.get(
      `/roles`,{
        params: {
          page: page,
          limit: 10,
          name: searchTerm
        }
      }
    );
  },
  getRoleById: (id) => {
    return axios.get(
      `/roles/${id}`
    );
  },
  updateRole: (roleId, roleData) => {
    return axios.put(`/roles/${roleId}`, roleData);
  },

  deleteRole: (roleId) => {
    return axios.delete(
      `/roles/${roleId}`
    );
  },
};

export default RoleService;
