import { store } from '../Redux/store';
// const { user } = useAuth();

export const checkHasObjPermission = (obj, objectType, permission) => {
  let hasPermission = false;
  const {
    auth: { user },
  } = store.getState();
  if (user?.userType == 'Admin') {
    return true;
  }
  user?.userRoles?.forEach((userRole) => {
    userRole.role?.rolePermissions?.forEach((rolePermission) => {
      if (
        rolePermission.rolePermissionObjects?.objectType == objectType &&
        rolePermission.rolePermissionObjects?.objectId == obj.id &&
        rolePermission.permission?.slug == permission
      ) {
        hasPermission = true;
      } else {
        if (objectType == 'camera') {
          if (obj.created_by == user.id) hasPermission = true;
        }
      }
    });
  });

  return hasPermission;
};

export const checkPermission = (permission) => {
  let hasPermission = false;
  const {
    auth: { user },
  } = store.getState();
  if (user?.userType == 'Admin') {
    return true;
  }
  user?.userRoles?.forEach((userRole) => {
    userRole.role?.rolePermissions?.forEach((rolePermission) => {
      if (rolePermission.permission?.slug == permission) {
        hasPermission = true;
      }
    });
  });

  return hasPermission;
};

export const checkAddPermission = (ObjectType, permission) => {
  let hasPermission = false;
  const {
    auth: { user },
  } = store.getState();
  if (user?.userType == 'Admin') {
    return true;
  }
  user?.userRoles?.forEach((userRole) => {
    userRole.role?.rolePermissions?.forEach((rolePermission) => {
      if (
        rolePermission.permission?.type == ObjectType &&
        rolePermission.permission?.slug == permission
      ) {
        hasPermission = true;
      }
    });
  });

  return hasPermission;
};

export const checkUserManagementPermission = (
  permission,
  userInList = null
) => {
  let hasPermission = false;
  const {
    auth: { user },
  } = store.getState();
  if (user?.userType == 'Admin') {
    return true;
  }
  if (userInList?.createdByUser?.id == user?.id) {
    return true;
  }
  user?.userRoles?.forEach((userRole) => {
    userRole.role?.rolePermissions?.forEach((rolePermission) => {
      if (rolePermission.permission?.slug == permission) {
        hasPermission = true;
      }
    });
  });

  return hasPermission;
};
