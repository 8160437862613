import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
  Stack,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { ApplicationStyle } from '../../../Layouts/style';
import {
  DropDeleteIcon,
  DropEditIcon,
  DropRestartIcon,
  DropStartIcon,
  DropStopIcon,
  GridIcon,
  ListIcon,
  VerticalDotIcon,
  SearchIcon,
  CloseIcon,
  AscSortIcon,
  DescSortIcon,
} from '../../../Icons/SVGContainer';
import { toast } from 'react-toastify';
import BaseModal from '../../../Components/Modal/BaseModal';
import EditApplicationForm from '../../../Components/Form/EditApplicationForm';
import ApplicationService from '../../../RestSevices/applicationService';
import ApplicationListDataTable from '../../../Components/DataTable/ApplicationListDatatable';
import { checkHasObjPermission } from '../../../Utils/checkPermission';
import useAuth from '../../../Redux/Actions/authActions';

function DeleteUserDialog({ deleteIsOpen, deleteOnClose, onDelete }) {
  return (
    <Dialog
      open={deleteIsOpen}
      onClose={deleteOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete Application?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this application?
          <br />
          *** Deleting Application would also delete all the zones associated
          with it!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function sortProps(index) {
  return {
    id: `sort-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ApplicationListView = () => {
  const [applyDropdown, setApplyDropdown] = useState(null);
  const [applications, setApplications] = useState([]);
  const [clickedApplication, setClickedApplication] = useState(null);
  const [applicationForEdit, setApplicationForEdit] = useState({});
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState('one');
  const [sortValue, setSortValue] = React.useState('asc');
  const open = Boolean(applyDropdown);

  const { logout } = useAuth();
  const handleClick = (event, hash_id) => {
    setApplyDropdown(hash_id);
    setClickedApplication(hash_id);
  };
  const handleClose = () => {
    setApplyDropdown(null);
  };

  const fetchData = () => {
    setLoading(true);
    ApplicationService.getApplicationsList(page, searchTerm, sortValue)
      .then((response) => {
        setApplications(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [page, searchTerm, sortValue]);
  const [modalOpen, setModalOpen] = React.useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        applyDropdown &&
        event.target.closest('.applydropbox') === null &&
        event.target.closest('.application_menu') === null
      ) {
        setApplyDropdown(null);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [applyDropdown]);

  const handleEdit = () => {
    const selectedApplication = applications.find(
      (application) => application.hash_id === clickedApplication
    );
    setApplicationForEdit(selectedApplication);
    setModalOpen(true);
    handleClose();
  };

  const handleDeleteConfirm = () => {
    setDeleteIsOpen(true);
    handleClose();
  };

  const handleDelete = async () => {
    try {
      await ApplicationService.deleteApplication(clickedApplication);
      setDeleteIsOpen(false);
      toast.success('Application deleted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
    } catch (err) {
      console.log(err.message);
      toast.error('Error in deleting application', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSort = (event, newValue) => {
    setSortValue(newValue);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
    // handleChangeList(role, status, '');
  };

  const columns = [
    'applicationId',
    'applicationName',
    'serverUrl',
    'status',
    'action',
  ];
  const headCells = [
    {
      id: 'applicationId',
      numeric: false,
      disablePadding: true,
      label: 'Application Id',
    },
    {
      id: 'applicationName',
      numeric: true,
      disablePadding: true,
      label: 'Application Name',
    },
    {
      id: 'serverUrl',
      numeric: true,
      disablePadding: true,
      label: 'Serve rUrl',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  const rows = [
    ...applications.map((application) => {
      return {
        id: application.id,
        applicationName: application.application_id,
        applicationId: application.application_name,
        serverUrl: application.server_url,
        ...application,
      };
    }),
  ];
  return (
    <ApplicationStyle>
      <Box className="application_page_heading">
        <Typography component="h2">Applications</Typography>
      </Box>
      <Box className="tab_area">
        <Box className="tab_toggle_area">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Tabs
              value={sortValue}
              onChange={handleChangeSort}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="asc"
                label={<AscSortIcon />}
                {...sortProps('asc')}
                className="tab_btn sort_tab_btn"
              />
              <Tab
                value="desc"
                label={<DescSortIcon />}
                {...sortProps('desc')}
                className="tab_btn sort_tab_btn"
              />
            </Tabs>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label={<GridIcon />}
                {...a11yProps('one')}
                className="tab_btn"
              />
              <Tab
                value="two"
                label={<ListIcon />}
                {...a11yProps('two')}
                className="tab_btn"
              />
            </Tabs>
          </Box>
        </Box>
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
            {applications.length === 0 ? (
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: 'center' }}
              >
                No application found
              </Typography>
            ) : (
              <>
                <TabPanel value={value} index="one">
                  <Box className="apply_box_area">
                    {applications.map((application, index) => (
                      <Box className="apply_box" key={application.hash_id}>
                        <Box className="apply_box_heading">
                          <Typography component="h4">Application id</Typography>
                          <Typography component="h3" className="text_truncate">
                            {application.application_id}
                          </Typography>
                          <IconButton
                            className="application_menu"
                            onClick={(event) =>
                              handleClick(event, application.hash_id)
                            }
                          >
                            <VerticalDotIcon />
                          </IconButton>
                          <Box
                            className={
                              applyDropdown === application.hash_id
                                ? 'applydropbox show'
                                : 'applydropbox'
                            }
                          >
                            {checkHasObjPermission(
                              application,
                              'application',
                              'edit_application'
                            ) && (
                              <Button onClick={handleEdit}>
                                Edit <DropEditIcon />
                              </Button>
                            )}
                            {checkHasObjPermission(
                              application,
                              'application',
                              'delete_application'
                            ) && (
                              <Button onClick={handleDeleteConfirm}>
                                Delete <DropDeleteIcon />
                              </Button>
                            )}
                            {checkHasObjPermission(
                              application,
                              'application',
                              'start_application'
                            ) && (
                              <Button onClick={handleClose} disabled>
                                Start <DropStartIcon />
                              </Button>
                            )}
                            {checkHasObjPermission(
                              application,
                              'application',
                              'stop_application'
                            ) && (
                              <Button onClick={handleClose} disabled>
                                Stop <DropStopIcon />
                              </Button>
                            )}
                            {checkHasObjPermission(
                              application,
                              'application',
                              'restart_application'
                            ) && (
                              <Button onClick={handleClose} disabled>
                                Restart <DropRestartIcon />
                              </Button>
                            )}
                          </Box>
                        </Box>
                        <Box className="apply_box_info">
                          <Box className="apply_box_info_con">
                            <Typography component="h4">
                              Application name
                            </Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              {application.application_name}
                            </Typography>
                          </Box>
                          <Box className="apply_box_info_con">
                            <Typography component="h4">Server Url</Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              {application.server_url}
                            </Typography>
                          </Box>
                          <Box className="apply_box_info_con">
                            <Typography component="h4">Status</Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              Not Configured
                            </Typography>
                          </Box>
                          <Box className="apply_box_info_con">
                            <Typography component="h4">
                              Application Fields
                            </Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              {application?.applicationfields?.map(
                                (field, index) =>
                                  // Concatenate field names with a space
                                  `${field.field_name}${
                                    index <
                                    application.applicationfields.length - 1
                                      ? ' '
                                      : ''
                                  }`
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index="two">
                  <ApplicationListDataTable
                    rows={rows}
                    headCells={headCells}
                    columns={columns}
                    handleEdit={handleEdit}
                    handleClose={handleClose}
                    handleDeleteConfirm={handleDeleteConfirm}
                    setClickedApplication={setClickedApplication}
                  />
                </TabPanel>
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </>
            )}
          </>
        )}
      </Box>
      <BaseModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={`Edit Application`}
      >
        <EditApplicationForm
          onClose={() => setModalOpen(false)}
          applicationForEdit={applicationForEdit}
          fetchData={fetchData}
        />
      </BaseModal>
      <DeleteUserDialog
        deleteIsOpen={deleteIsOpen}
        deleteOnClose={() => setDeleteIsOpen(false)}
        onDelete={handleDelete}
      />
    </ApplicationStyle>
  );
};

export default ApplicationListView;
