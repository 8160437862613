import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  AvatarGroup,
  Typography
} from '@mui/material';
import {
  DeleteIcon,
  EditIcon,
} from '../../Icons/SVGContainer';
import StatusIndicator from '../StatusIndicator';
import { capitalizeFirstLetter } from '../../Utils/dashboardHelper';
import moment from 'moment/moment';

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.label === 'Status' ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getUniquePermissions = (types) => {
  const modules = [];
  types?.forEach((rolePermission) => {
    if (rolePermission?.name) {
      if (!modules.includes(rolePermission.name)) {
        modules.push(rolePermission.name);
      }
    }
  });

  return modules.map((val) => {
    return <Avatar sx={{ fontSize: '0.875rem' }} key={`avatar-${val}`}>{val.charAt(0).toUpperCase() + val.slice(1)}</Avatar>;
  });
};

const CommonDataTable = ({
  rows,
  headCells,
  columns,
  handleEdit,
  handleDelete,
  additionalActions,
}) => {
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size="medium"
        className="datatable_common"
      >
        <EnhancedTableHead headCells={headCells} />
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={`row-${row.id}`}
                sx={{ cursor: 'pointer' }}
              >
                {columns.map((col, index) => (
                  <TableCell
                    align={index === 0 ? 'left' : 'right'}
                    key={`cell-${row.id}-${col}`}
                  >
                    {col === 'action' ? (
                      <Box className="action_icon">
                        {handleDelete && (
                          <IconButton
                            title="Delete"
                            onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {handleEdit && (
                          <IconButton
                            title="Edit"
                            onClick={() => handleEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {additionalActions &&
                          additionalActions.map((action, index) => (
                            <IconButton
                              key={index}
                              title={action.title}
                              onClick={() => action.onClick(row)}
                            >
                              {action.icon}
                            </IconButton>
                          ))}
                      </Box>
                    ) : col === 'status' ? (
                      <StatusIndicator
                        status={row.status === 'active' ? 'active' : 'inactive'}
                        width={20}
                        height={20}
                      />
                    ) : col === 'name' ? (
                      capitalizeFirstLetter(row[col])
                    ) : col === 'nameWithAvatar' ? (
                      <Box className="task_name">
                        <Avatar src={row.image ? row.image : '/images/no_image.png'} alt='' />
                        <Typography component="span">{`${capitalizeFirstLetter(row.first_name) } ${capitalizeFirstLetter(row.last_name)}`}</Typography>
                      </Box>
                    ) : col === 'types' ? (
                      <AvatarGroup sx={{ justifyContent: 'start' }} key={`avatar-group-${row._id}-${col}`}>
                        {getUniquePermissions(row[col])}
                      </AvatarGroup>
                    ) : col === 'roles' ? (
                      row?.roles?.map((role, index) => (
                        <Typography key={index} component="span">
                          {role?.name ? ` ${capitalizeFirstLetter(role?.name)}` : ''}
                          {row?.roles.length - 1 !== index ? ',' : ''}
                        </Typography>
                      ))
                    ) : (
                      <>
                        {col === 'created_at' ? (
                          moment(row[col]).format('MMMM Do, YYYY')
                        ) : col === 'last_updated_on' ? (
                          moment(row[col]).format('MMMM Do, YYYY')
                        ) : col === 'updated_at' ? (
                          <>
                            {moment(row[col]).format('MMMM Do, YYYY')} {moment(row[col]).format('HH:mm:ss')}
                          </>
                        ) : (
                          row[col]
                        )}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonDataTable;