import React, { useState, useEffect } from 'react'
import { HeaderStyle } from '../Layouts/style'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider
} from '@mui/material'
import { BellIcon, DownArrow } from '../Icons/SVGContainer'
// import HeaderDateInfo from './HeaderDateInfo';
import { Link } from 'react-router-dom'
import useAuth from '../Redux/Actions/authActions'
import { useKeycloak } from '@react-keycloak/web'

import { Radio, Settings, Shield, User, Users, LogOut } from 'lucide-react'

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { keycloak, initialized } = useKeycloak()
  const { tenant_login, user, logout } = useAuth()
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      tenant_login(keycloak)
    }
  }, [initialized, keycloak])

  useEffect(() => {
    if (anchorEl) {
      document.body.classList.add('has-scroll')
    } else {
      document.body.classList.remove('has-scroll')
    }

    return () => {
      document.body.classList.remove('has-scroll')
    }
  }, [anchorEl])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    logout()
  }
  return (
    <HeaderStyle>
      <Box className="header_area">
        <Box className="header_left">
          <Box className="header_logo">
            <Link to="/dashboard">
              <img src="/images/logo_white.svg" className="size-6" />
            </Link>
          </Box>
          {/* <HeaderDateInfo user={user} /> */}
        </Box>
        <Box className="header_right">
          {/* <IconButton>
            <BellIcon />
          </IconButton> */}
          <Box className="head_user_name">
            <Box className="user_pic">
              <img src="/images/no_image.png" alt="" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.7rem' }}>
                {user?.first_name} {user?.last_name}
              </Typography>
              {/* <Typography component="p">{user?.roles[0]?.name}</Typography> */}
            </Box>
          </Box>
          <IconButton className="header_drop_icon" onClick={handleClick}>
            {/* <DownArrow /> */}
            <div className="bg-[#333] p-2 py-1  border-gradient">
              <Settings className="text-white size-4 " />
            </div>
          </IconButton>
          {user?.roles[0]?.name === 'admin' && (
            <Menu
              id="basic-menu"
              className="profile_toggle"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              {/* <Typography
                variant="h6"
                component="div"
                style={{
                  padding: '0 16px 8px 16px',
                  color: '#959595',
                  fontSize: '12px'
                }}
              >
                Admin Settings
              </Typography>
              <Divider /> */}
              <MenuItem onClick={handleClose}>
                <Link
                  to="profile"
                  className=" flex gap-1 items-center font-extralight"
                >
                  <User className="size-3" />
                  Account{' '}
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="role-list"
                  className="flex gap-1 items-center font-extralight text-left"
                >
                  <Shield className="size-3" />
                  Roles
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="user-list"
                  className="flex gap-1 items-center font-extralight text-left"
                >
                  <Users className="size-3" />
                  Users
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/settings/devices"
                  className="flex gap-1 items-center font-extralight text-left"
                >
                  <Radio className="size-3" />
                  Devices
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/settings/storages"
                  className="flex gap-1 items-center font-extralight text-left"
                >
                  <Settings className="size-3" />
                  Settings
                </Link>
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                className="flex gap-1 items-center font-extralight text-left"
              >
                <LogOut className="size-3" />
                Logout
              </MenuItem>
            </Menu>
          )}
          {user?.roles[0]?.name === 'user' && (
            <Menu
              id="basic-menu"
              className="profile_toggle"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <Typography
                variant="h6"
                component="div"
                style={{
                  padding: '0 16px 8px 16px',
                  color: '#959595',
                  fontSize: '15px'
                }}
              >
                Account Settings
              </Typography>
              <Divider />
              <MenuItem onClick={handleClose}>
                <Link to="#">Settings</Link>
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          )}
        </Box>
      </Box>
    </HeaderStyle>
  )
}

export default Header
