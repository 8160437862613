import { useState } from 'react'
import { Box } from '@mui/material'
import { ChevronRight, ChevronDown, Cctv, Folder, Dot } from 'lucide-react'

import { Device } from './all-interfaces'

interface ServicesProps {
  devices: Device[]
  handleOnDrag: (e: React.DragEvent<HTMLLIElement>, device: Device) => void
  handleDoubleClick: (device: Device) => void
  handleClick: (e: React.MouseEvent<HTMLUListElement>) => void
}

export function ServiceTree({
  devices,
  handleOnDrag,
  handleDoubleClick,
  handleClick
}: ServicesProps) {
  const [showCameras, setShowCameras] = useState(false)

  function handleToggle() {
    setShowCameras(prev => !prev)
  }

  function truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`
    }
    return text
  }

  return (
    <Box className="dashboard_left_folder_area" component={'div'}>
      <ul>
        <li className="space-y-2 text-sm">
          <div className="flex items-center gap-2">
            <Folder className="size-3" />
            <p className="text-xs text-zinc-200 font-semibold">Service</p>
          </div>
          <ul className="space-y-2 text-base">
            <li onClick={handleToggle} className="cursor-pointer pt-1">
              <span className="flex items-center gap-1 mb-1">
                {showCameras ? (
                  <ChevronDown className="size-3" />
                ) : (
                  <ChevronRight className="size-3" />
                )}
                <p className="text-xs text-zinc-200 font-semibold">Cameras</p>
              </span>
              {showCameras && (
                <ul className="" onClick={handleClick}>
                  {devices.map(device => (
                    <li
                      key={device.id}
                      draggable
                      onDragStart={e => handleOnDrag(e, device)}
                      onDoubleClick={() => handleDoubleClick(device)}
                      title={device.name}
                      className={`text-[11px] items-center hover:cursor-pointer `}
                    >
                      <div className="flex items-center">
                        <Dot
                          className=" text-[#339626]"
                          strokeWidth={4}
                          width={20}
                          height={24}
                        />
                        <span className=" text-gray-300 font-thin capitalize">
                          {truncateText(device.name, 30)}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </li>
      </ul>
    </Box>
  )
}
