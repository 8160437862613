import React from 'react'
import RouteComponent from './Routes/Route'
import { ThemeProvider } from '@mui/material'
import createPrimaryTheme from './theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import Styles from './GlobalStyle'
import './index.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Toaster } from 'react-hot-toast'

function App() {
  const theme = createPrimaryTheme('dark')

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={Styles(theme)} />
        <RouteComponent />
        <ToastContainer autoClose={2000} />

        <Toaster
          containerStyle={{
            bottom: '24px',
            right: '24px'
          }}
          toastOptions={{
            position: 'bottom-right'
          }}
        />
      </ThemeProvider>
    </>
  )
}

export default App
