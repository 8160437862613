import React, { useEffect }from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { ModalLayoutStyle } from '../../Layouts/style';
import { ViewStorageSettingProps} from '../../types/storageSettings';
import ViewStorageFieldsByType from '../Modal/ViewStorageFieldsByType';
import moment from 'moment/moment';

const ViewStorageSettings: React.FC<ViewStorageSettingProps> = ({ onClose, initialData }) => {

  useEffect(() => {
    // console.log(initialData, 'initialData');
  }, [initialData?.key]);
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Box className="form_field_area">
          <Box className="form_box_hold">
            <Box className="form_box_con full_width">
              <Typography component="label">Key</Typography>
              {initialData?.key}
            </Box>
          </Box>
          <Box className="form_box_hold">
            <Box className="form_box_con full_width">
              <Typography component="label">Description</Typography>
              {initialData?.description}
            </Box>
          </Box>
          {(initialData?.key && initialData.key === 'storage') && (
            <ViewStorageFieldsByType type={initialData.key} initialData={initialData} />
          )}
            <Box className="form_box_hold">
            <Box className="form_box_con">
              <Typography component="label">Created At</Typography>
              {moment(initialData?.created_at).format('MMMM Do YYYY')}
            </Box>
            <Box className="form_box_con">
              <Typography component="label">Updated At</Typography>
              {moment(initialData?.updated_at).format('MMMM Do YYYY')}
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalLayoutStyle>
  );
};

export default ViewStorageSettings;
