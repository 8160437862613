import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AddIcon, SearchIcon, CloseIcon } from '../../../Icons/SVGContainer';
import { DatatableHeadingStyle } from '../../../Layouts/style';
import BaseModal from '../../../Components/Modal/BaseModal';
import AddUserForm from '../../../Components/Form/AddUserForm';
// import { checkUserManagementPermission } from '../../../Utils/checkPermission';

const UserManagementHeading = ({
  fetchUsers,
  handleChangeList,
  status
}) => {
  const [userStatus, setUserStatus] = useState(status || 'active');
  const [searchTerm, setSearchTerm] = useState('');
  const handleStatusChange = (event) => {
    setUserStatus(event.target.value);
  };
  const [modalOpen, setModalOpen] = useState(false);
  
  useEffect(() => {
    handleChangeList(userStatus, searchTerm);
  }, [userStatus, searchTerm]);

  // Handle search change with debounce
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
    // handleChangeList(role, status, '');
  };

  const addUser = () => {
    setModalOpen(true);
  };

  return (
    <DatatableHeadingStyle>
      <Box className="user_heading">
        <Typography component="h2">User management</Typography>
        <Box className="user_head_search">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box className="table_head_btn_con">
            <Select value={userStatus} onChange={handleStatusChange} displayEmpty>
              <MenuItem value="">Select Status</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
            {/* <Select value={role} onChange={handleRoleChange} displayEmpty>
              <MenuItem value="">Select Role</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Manager">Manager</MenuItem>
            </Select> */}
            <Button
              variant="contained"
              color="primary"
              onClick={addUser}
              endIcon={<AddIcon />}
            >
              Add User
            </Button>
          </Box>
          <BaseModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={`Add User`}
          >
            <AddUserForm
              onClose={() => setModalOpen(false)}
              fetchUsers={fetchUsers}
            />
          </BaseModal>
        </Box>
      </Box>
    </DatatableHeadingStyle>
  );
};

export default UserManagementHeading;
