import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import { Formik } from 'formik';
import { roleValidationSchema } from '../../Utils/validators';
import { useNavigate } from 'react-router-dom';
import { MuiColorInput } from 'mui-color-input'

const AddRoleForm = ({ onClose, roleId, roleForEdit }) => {
  const navigate = useNavigate();
  const [roleState, setRoleState] = useState({
    name: roleForEdit?.name || '',
    status: roleForEdit?.status === 'active' ? 1 : 0 || 1,
    color: roleForEdit?.color || '#fff',
  });

  const handleSubmit = (values, { resetForm }) => {
    navigate('/permission', { state: { ...values } });
  };
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Formik
          initialValues={roleState}
          validationSchema={roleValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Box className="form_field_area ">
                <Box className="form_box_hold">
                  <Box className="form_box_con">
                    <Typography component="label">Role Name</Typography>
                    <TextField
                      name="name"
                      type="text"
                      value={props.values.name}
                      onChange={props.handleChange}
                      error={props.touched.name && !!props.errors.name}
                      helperText={props.touched.name ? props.errors.name : ''}
                    />
                  </Box>
                  <Box>
                    <Typography component="label">Color</Typography>
                    <MuiColorInput format="hex" value={props.values.color} onChange={(color) => props.setFieldValue('color', color)} size="small" variant="outlined" className='color_picker'/>
                  </Box>
                </Box>
                {/* <Box className="form_box_con">
                  <Typography component="label">Status</Typography>
                  <Select
                    name="status"
                    value={props.values.status}
                    onChange={props.handleChange}
                    displayEmpty
                    error={props.touched.status && !!props.errors.status}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                  {props.touched.status && !!props.errors.status && (
                    <div className="error">{props.errors.status}</div>
                  )}
                </Box> */}
              </Box>
              <Box className="add_btn">
                <Button className="cancel_btn" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">{roleId ? 'Edit' : 'Create'}</Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ModalLayoutStyle>
  );
};

export default AddRoleForm;
