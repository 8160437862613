import { Box, Button } from '@mui/material'

interface DeleteModalTabProps {
  closeTabModal: () => void
  handleRemoveTab: (tabId: string, confirm: boolean) => void
  tabName: string
}

export function DeleteModalTab({
  closeTabModal,
  handleRemoveTab,
  tabName
}: DeleteModalTabProps) {
  return (
    <Box
      component={'div'}
      className="fixed inset-0 bg-black/60 flex items-center justify-center z-50"
    >
      <div className="w-[340px] rounded-sm py-5 px-5 shadow-shape bg-[#333] space-y-5 min-w-[460px]">
        <div className="space-y-6">
          <div className="text-center ">
            <h2 className="text-lg font-semibold text-zinc-200 text-left">
              Você tem certeza que deseja excluir esta aba?
            </h2>
            <p className="text-zinc-400 text-sm text-left mt-1">
              Todos os vídeos associados a ela serão removidos permanentemente.
            </p>
          </div>

          <div className="w-full h-px bg-zinc-400/10" />

          <div className="flex gap-3 justify-end ">
            <button
              onClick={closeTabModal}
              color="primary"
              className="w-24 h-9 bg-[#272727]/70 font-bold rounded-sm text-zinc-200 hover:bg-[#272727] transition-all duration-100"
            >
              Cancelar
            </button>
            <button
              // variant="contained"
              // color="primary"
              onClick={() => handleRemoveTab(tabName, true)}
              className="w-24 h-9  bg-zinc-500/20 font-bold rounded-sm hover:bg-zinc-500/30 transition-all duration-100"
            >
              Excluir
            </button>
          </div>
        </div>
      </div>
    </Box>
  )
}
