// @ts-check

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, IconButton, Typography } from '@mui/material';
import { DialogStyle } from '../../Layouts/style';
import { CloseIcon } from '../../Icons/SVGContainer';
import LineChart from '../Chart/LineChart';
import BarChart from '../Chart/BarChart';
import PieChart from '../Chart/PieChart';

/**
 * @typedef {import('@mui/material/Dialog').DialogProps} DialogProps
 * @typedef {Object} CustomDialogProps
 * @property {boolean} open
 * @property {React.ReactNode} [children]
 * @property {() => void} onClose
 * @property {number} [width]
 * @property {number} [height]
 * @property {string} [title]
 */

function ChartModal(props) {
  const { open, onClose, children, width, height, title, data, type } = props;
  const [dialogHeight] = useState(height);
  const [dialogWidth] = useState(width ? width : '600px');

  return (
    <DialogStyle
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          height: dialogHeight,
          minWidth: dialogWidth,
          overflow: 'hidden',
          borderRadius: '20px',
          boxShadow: '0px 4px 40px 5px rgba(0, 0, 0, 0.13)',
        },
      }}
      sx={{
        '.MuiPaper-root': {
          minWidth: '1200px !important',
        },
        '@media(max-width:1099px)': {
          '.MuiPaper-root': {
            minWidth: '60% !important',
          },
        },
        '@media(max-width:767px)': {
          '.MuiPaper-root': {
            minWidth: '90% !important',
          },
        },
      }}
    >
      {data && (
        <>
          <Box className="modal-head">
            <Box>
              {title && (
                <Typography className="global_modal_title">{title}</Typography>
              )}
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box className="modal-body">
            <div className="dashboard_chart_con">
              {/* <Box className="dashboard_chart_head">
                <Typography component="h3">
                  {data.name.toUpperCase()}
                </Typography>
              </Box> */}
              {type == 'pie' ? (
                <PieChart configData={data} type={type} />
              ) : null}
              {type == 'line' ? (
                <LineChart configData={data} type={type} />
              ) : null}
              {type == 'bar' ? (
                <BarChart configData={data} type={type} />
              ) : null}
            </div>
          </Box>
        </>
      )}
    </DialogStyle>
  );
}

export default ChartModal;
