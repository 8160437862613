import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import AnalyticsService from '../../RestSevices/analyticsService';
import { toast } from 'react-toastify';

const EditAnalyticsForm = ({ onClose, analyticsForEdit, fetchAnalytics }) => {
  const [priority, setPriority] = React.useState('');
  const [formData, setFormData] = useState({});
  const handleChange = (event) => {
    setPriority(event.target.value);
  };
  const [value, setValue] = React.useState('');
  const handleValueChange = (event, field) => {
    setFormData({
      ...formData,
      analyticfields: formData.analyticfields.map((item) => {
        if (item.hash_id == field.hash_id) {
          if (field.field_type == 'array') {
            item.field_value = event.target.value.split(',');
            return item;
          }
          item.field_value = event.target.value;
          return item;
        } else {
          return item;
        }
      }),
    });
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const postData = {
      analyticfields: formData.analyticfields.map((data) => {
        return {
          field_value: data.field_value,
          field_hashid: data.hash_id,
          field_type: data.field_type,
        };
      }),
    };

    AnalyticsService.updateAnalytics(formData.hash_id, postData).then(
      (resp) => {
        fetchAnalytics();
        onClose();
        toast.success('Analytics updated successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  useEffect(() => {
    setFormData(analyticsForEdit);
  }, [analyticsForEdit]);
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <form onSubmit={handleUpdate}>
          <Box className="form_field_area analytics_form">
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Analytics Name</Typography>
                <TextField
                  name="analytics_name"
                  placeholder="Enter name"
                  value={formData.analytic_name}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Description</Typography>
                <TextField
                  name="analytics_desc"
                  placeholder="Type..."
                  multiline
                  rows={3}
                  value={formData.analytic_description}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Priority</Typography>
                <FormControl>
                  <Select
                    value={formData?.severity || ''}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={true}
                  >
                    <MenuItem value={'1'}>High</MenuItem>
                    <MenuItem value={'2'}>Medium</MenuItem>
                    <MenuItem value={'3'}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Typography component="h3">Add Fields</Typography>
            </Box>
            {formData.analyticfields?.map((field) => {
              return (
                <Box className="form_box_hold">
                  <Box className="form_box_con">
                    <Typography component="label">Field name</Typography>
                    <TextField
                      name="field_name"
                      placeholder="Enter name"
                      value={field.field_name}
                      disabled={true}
                    />
                  </Box>
                  <Box className="form_box_con">
                    <Typography component="label">Value</Typography>
                    {field.field_type === 'boolean' ? (
                      <FormControl>
                        <Select
                          value={field.field_value}
                          onChange={(event) => handleValueChange(event, field)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>
                      </FormControl>
                    ) : field.field_type === 'array' ? (
                      <TextField
                        name="field_value"
                        placeholder="Enter value"
                        value={field.field_value.join(',')}
                        onChange={(event) => handleValueChange(event, field)}
                      />
                    ) : (
                      <TextField
                        name="field_value"
                        placeholder="Enter value"
                        value={field.field_value}
                        onChange={(event) => handleValueChange(event, field)}
                      />
                    )}
                  </Box>
                  <Box className="form_box_con">
                    <Typography component="label">Data type</Typography>
                    <TextField
                      name="data_type"
                      placeholder="Boolean"
                      value={field.field_type}
                      disabled={true}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box className="add_btn">
            <Button className="cancel_btn" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Box>
        </form>
      </Box>
    </ModalLayoutStyle>
  );
};

export default EditAnalyticsForm;
