import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import { PhotoCamera, Close } from '@mui/icons-material';
import ReactCrop, { Crop } from 'react-image-crop';
import BaseModal from '../../Components/Modal/BaseModal';
import { ModalLayoutStyle } from '../../Layouts/style';
import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';
import { base64ToUrl } from '../../Utils/dashboardHelper';

interface AvatarUploadProps {
  onImageCropped: (croppedImage: string | null) => void,
  getImageFile: (imageFile: File | null) => void,
  initialImage?: string | null
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({
  onImageCropped,
  getImageFile,
  initialImage
}) => {
  const [image, setImage] = useState<string | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [crop, setCrop] = useState<Crop>({
    x: 0,
    y: 0,
    width: 30,
    height: 50,
    unit: '%'
  })
  const [modalOpen, setModalOpen] = useState(false)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null)

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      // Check file type
      const isSupportedFormat = /image\/(jpg|jpeg|png)/.test(file.type);
      if (!isSupportedFormat) {
        toast.error('Supported formats: jpg, jpeg, png', {
          position: toast.POSITION.TOP_RIGHT
        })
        return;
      }

      // Check file size (2MB = 2 * 1024 * 1024 bytes)
      const isWithinSizeLimit = file.size <= 2 * 1024 * 1024;
      if (!isWithinSizeLimit) {
        toast.error('File size should be less than 2MB', {
          position: toast.POSITION.TOP_RIGHT
        })
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string)
        setModalOpen(true)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleCropComplete = (crop: Crop) => {
    setCompletedCrop(crop)
  }

  const handleCropSave = async() => {
    if (completedCrop && imageRef.current) {
      const canvas = document.createElement('canvas')
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height
      const size = Math.min(completedCrop.width!, completedCrop.height!)
      canvas.width = size
      canvas.height = size
      const ctx = canvas.getContext('2d')

      ctx!.beginPath()
      ctx!.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI)
      ctx!.closePath()
      ctx!.clip()

      ctx!.drawImage(
        imageRef.current,
        completedCrop.x! * scaleX,
        completedCrop.y! * scaleY,
        size * scaleX,
        size * scaleY,
        0,
        0,
        size,
        size
      )

      const croppedImageUrl = canvas.toDataURL('image/jpeg')

      // Convert data URL to file
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });

      setCroppedImage(croppedImageUrl)
      onImageCropped(croppedImageUrl) // Pass cropped image to parent
      getImageFile(file);
      setModalOpen(false)
      setImage(null)
    }
  }

  const handleRemoveImage = () => {
    setCroppedImage(null)
    onImageCropped(null) // Clear cropped image in parent
  }

  useEffect(() => {
    if (initialImage) {
      setCroppedImage(base64ToUrl(initialImage))
    }
  }, [initialImage])

  return (
    <>
      {croppedImage ? (
        <Box
          sx={{
            width: 150,
            height: 150,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          <IconButton
            component="label"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <Avatar
              src={croppedImage}
              sx={{ width: 150, height: 150, mb: 1, borderRadius: '50%' }}
            />
          </IconButton>
          <IconButton
            onClick={handleRemoveImage}
            sx={{
              position: 'absolute',
              top: '-10px',
              right: 5,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.7)'
              }
            }}
          >
            <Close />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            width: 150,
            height: 150,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            border: '1px solid #6c6c6c',
            backgroundColor: '#31352E',
            position: 'relative'
          }}
        >
          <IconButton
            component="label"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <input
              accept="image/*"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <Avatar
              sx={{
                width: 100,
                height: 100,
                mb: 1,
                bgcolor: '#31352E',
                borderRadius: '50%'
              }}
            >
              <PhotoCamera fontSize="large" sx={{ color: '#6c6c6c' }} />
            </Avatar>
            <Typography
              variant="body2"
              sx={{
                color: '#6c6c6c',
                fontWeight: 500,
                textAlign: 'center'
              }}
            >
              Upload Image
            </Typography>
          </IconButton>
          <BaseModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={'Crop Image'}
          >
            <ModalLayoutStyle>
              <Box className="modal_form_body">
                <Box className="crop_img">
                  {image && (
                    <>
                      <ReactCrop
                        crop={crop}
                        circularCrop={true}
                        onChange={newCrop => setCrop(newCrop)}
                        onComplete={handleCropComplete}
                      >
                        <img
                          src={image}
                          alt="Avatar"
                          onLoad={event =>
                            (imageRef.current =
                              event.currentTarget as HTMLImageElement)
                          }
                        />
                      </ReactCrop>
                    </>
                  )}
                </Box>
                <Box className="add_btn">
                  <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCropSave}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </ModalLayoutStyle>
          </BaseModal>
        </Box>
      )}
    </>
  )
}

export default AvatarUpload
