import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Button,
  Typography,
  Stack,
  Pagination,
  CircularProgress
} from '@mui/material'
import { toast } from 'react-toastify'
import { ApplicationStyle, DatatableHeadingStyle } from '../../../Layouts/style'
import { AddIcon, OpenEyeIcon } from '../../../Icons/SVGContainer'
import BaseModal from '../../../Components/Modal/BaseModal'
import CommonDataTable from '../../../Components/DataTable/CommonDatable'
import useAuth from '../../../Redux/Actions/authActions'
import debounce from 'lodash.debounce'
import DeleteDialog from '../../../Components/deleteDialog'
import { StorageSettings } from '../../../types/storageSettings'
import { Helmet } from 'react-helmet'
import AddStorageSettingForm from '../../../Components/Form/AddStorageSettingForm'
import SettingsService from '../../../RestSevices/settingsService'
import FilterDropdown from '../../../Components/Form/StorageSettingType/FilterDropdown'
import ViewStorageSettingModal from '../../../Components/Modal/ViewStorageSettingsModal'

const StorageListView: React.FC = () => {
  const [applyDropdown, setApplyDropdown] = useState<null | HTMLElement>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [viewModalOpen, setViewModalOpen] = useState<boolean>(false)
  const [storageList, setStorageList] = useState<StorageSettings[]>([])
  const [filter, setFilter] = useState<any>('')
  const [storageForEdit, setStorageForEdit] = useState<StorageSettings>({
    id: '',
    key: '',
    description: '',
    status: '',
    values: {
      type: '',
      uri: '',
      client_id: '',
      client_secret: '',
      bucket: ''
    },
    created_at: '',
    updated_at: ''
  })
  const [deleteIsOpen, setDeleteIsOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [storageKey, setStorageKey] = useState<string | null>(null)
  const { logout } = useAuth()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        applyDropdown &&
        !(event.target as HTMLElement).closest('.applydropbox') &&
        !(event.target as HTMLElement).closest('.application_menu')
      ) {
        setApplyDropdown(null)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [applyDropdown])

  const fetchData = useCallback(
    async (searchQuery?: string) => {
      setLoading(true)
      try {
        SettingsService.getStorageSettings()
          .then((response: any) => {
            setStorageList(response.data)
            setLoading(false)
          })
          .catch((error: any) => {
            if (error.response?.data?.error === 'Authentication failed') {
              logout()
              toast.error('Session Expired!', {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
      } catch (error: any) {
        if (error.response?.data?.error === 'Authentication failed') {
          logout()
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          // Caso o erro não seja uma instância de Error
          console.error('Ocorreu um erro desconhecido')
        }
      }
    },
    [page, logout]
  )

  const debouncedFetchResults = useCallback(debounce(fetchData, 300), [
    fetchData
  ])

  useEffect(() => {
    debouncedFetchResults(searchTerm)
  }, [page, searchTerm])
  const handleViewStorage = async (row: StorageSettings) => {
    const selectedStorage = storageList.find(
      (storage: StorageSettings) => storage.id === row.id
    )
    if (selectedStorage) {
      try {
        await SettingsService.getStorageSettingsById(selectedStorage.key)
          .then((response: any) => {
            setStorageForEdit({
              ...response.data,
              id: response.data?.id,
              status: response.data?.status,
              key: response.data?.key,
              description: response.data?.description,
              created_at: response.data?.created_at,
              updated_at: response.data?.updated_at,
              values: {
                type: response.data?.values?.type,
                uri: response.data?.values?.uri,
                client_id: response.data?.values?.client_id,
                client_secret: response.data?.values?.client_secret,
                bucket: response.data?.values?.bucket
              }
            })
            setViewModalOpen(true)
          })
          .catch((error: any) => {
            if (error.response?.data?.error === 'Authentication failed') {
              logout()
              toast.error('Session Expired!', {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
      } catch (error: any) {
        if (error.response?.data?.error === 'Authentication failed') {
          logout()
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error('Error in fetching Storage Settings', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }
    }
  }
  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setStorageForEdit({
      id: '',
      key: '',
      description: '',
      status: '',
      values: {
        type: '',
        uri: '',
        client_id: '',
        client_secret: '',
        bucket: ''
      },
      created_at: '',
      updated_at: ''
    })
  }
  const handleOpenForm = () => {
    setModalOpen(true)
  }
  const handleClose = () => {
    setModalOpen(false)
    setStorageForEdit({
      id: '',
      key: '',
      description: '',
      status: '',
      values: {
        type: '',
        uri: '',
        client_id: '',
        client_secret: '',
        bucket: ''
      },
      created_at: '',
      updated_at: ''
    })
  }
  const handleEdit = async (row: StorageSettings) => {
    const selectedStorage = storageList.find(
      (storage: StorageSettings) => storage.id === row.id
    )
    if (selectedStorage) {
      try {
        await SettingsService.getStorageSettingsById(selectedStorage.key)
          .then((response: any) => {
            // console.log(response.data, 'response');
            setStorageForEdit({
              ...response.data,
              id: response.data?.id,
              status: response.data?.status,
              key: response.data?.key,
              description: response.data?.description,
              created_at: response.data?.created_at,
              updated_at: response.data?.updated_at,
              values: {
                type: response.data?.values?.type,
                uri: response.data?.values?.uri,
                client_id: response.data?.values?.client_id,
                client_secret: response.data?.values?.client_secret,
                bucket: response.data?.values?.bucket
              }
            })
            setModalOpen(true)
            // handleClose();
          })
          .catch((error: any) => {
            if (error.response?.data?.error === 'Authentication failed') {
              logout()
              toast.error('Session Expired!', {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
      } catch (error: any) {
        if (error.response?.data?.error === 'Authentication failed') {
          logout()
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error('Error in fetching Storage Settings', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }
    }
  }

  const handleDeleteConfirm = (row: StorageSettings) => {
    setStorageKey(row.key)
    setDeleteIsOpen(true)
    handleClose()
  }

  const handleDelete = async () => {
    if (storageKey) {
      try {
        await SettingsService.deleteStorageSetting(storageKey).catch(error => {
          if (error.response?.data?.error === 'Authentication failed') {
            logout()
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        setDeleteIsOpen(false)
        toast.success('Settings deleted successfully!', {
          position: toast.POSITION.TOP_RIGHT
        })
        fetchData()
      } catch (error: any) {
        if (error.response?.data?.error === 'Authentication failed') {
          logout()
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          console.error(error)
          toast.error('Error in deleting settings', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }
    }
  }

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    e.preventDefault()
    setPage(value)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchTerm(value)
    debouncedFetchResults(value)
  }

  const clearSearchTerm = () => {
    setSearchTerm('')
  }
  const handleForm = () => {
    debouncedFetchResults(searchTerm)
  }
  const handleFilterChange = (filter: string) => {
    setFilter(filter)
    // Implement the logic to filter the list based on the filter value
    console.log('Filter changed:', filter)
  }
  const columns = [
    'key',
    'status',
    'description',
    'created_at',
    'updated_at',
    'action'
  ]
  const headCells = [
    {
      id: 'key',
      numeric: true,
      disablePadding: true,
      label: 'Key'
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: true,
      label: 'Status'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Description'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created At'
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: true,
      label: 'Updated At'
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action'
    }
  ]

  const rows = storageList.map(storage => ({
    ...storage
  }))

  const additionalActions = [
    {
      title: 'View',
      icon: <OpenEyeIcon />,
      onClick: handleViewStorage
    }
  ]

  return (
    <div>
      <Helmet>
        <title>Mirasys: Settings</title>
      </Helmet>
      <DatatableHeadingStyle>
        <Box className="user_heading">
          <Box className="user_head_search">
            <Box className="page_search">
              <Typography component="h2" sx={{ marginBottom: '0 !important' }}>
                Settings
              </Typography>
            </Box>
            <Box className="table_head_btn_con">
              <FilterDropdown onFilterChange={handleFilterChange} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenForm}
                endIcon={<AddIcon color={''} />}
              >
                Add Settings
              </Button>
            </Box>
          </Box>
        </Box>
      </DatatableHeadingStyle>
      <ApplicationStyle>
        <Box className="application_page_content" component="div">
          {loading ? (
            <CircularProgress color="success" />
          ) : (
            <>
              <CommonDataTable
                rows={rows}
                headCells={headCells}
                columns={columns}
                handleEdit={handleEdit}
                handleDelete={handleDeleteConfirm}
                additionalActions={additionalActions}
              />
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </>
          )}
        </Box>
        <BaseModal
          open={modalOpen}
          onClose={handleClose}
          title={storageForEdit?.key ? 'Edit Settings' : 'Add Settings'}
        >
          <AddStorageSettingForm
            onClose={handleClose}
            initialData={storageForEdit}
            fetchSettings={handleForm}
          />
        </BaseModal>
        <BaseModal
          open={viewModalOpen}
          onClose={handleCloseViewModal}
          title={'View Settings'}
        >
          <ViewStorageSettingModal
            onClose={handleCloseViewModal}
            initialData={storageForEdit}
          />
        </BaseModal>
        <DeleteDialog
          title="Delete Settings?"
          message="Are you sure you want to delete this setting?"
          setIsOpen={setDeleteIsOpen}
          isOpen={deleteIsOpen}
          onClose={() => setDeleteIsOpen(false)}
          onDelete={handleDelete}
        />
      </ApplicationStyle>
    </div>
  )
}

export default StorageListView
