import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Stack,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ApplicationStyle, DatatableHeadingStyle } from '../../../Layouts/style';
import {
  AddIcon,
  SearchIcon,
  CloseIcon,
} from '../../../Icons/SVGContainer';
import BaseModal from '../../../Components/Modal/BaseModal';
import EditDeviceForm from '../../../Components/Form/EditDeviceForm';
import DeviceService from '../../../RestSevices/deviceService';
import CommonDataTable from '../../../Components/DataTable/CommonDatable';
import { Helmet } from 'react-helmet';
// import {
//   checkAddPermission,
//   checkHasObjPermission,
// } from '../../../Utils/checkPermission';
import useAuth from '../../../Redux/Actions/authActions';
import debounce from 'lodash.debounce';
import DeleteDialog from '../../../Components/deleteDialog';

const DeviceListView = () => {
  const [applyDropdown, setApplyDropdown] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceForEdit, setDeviceForEdit] = useState({});
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const { logout } = useAuth();
  
  const handleClose = () => {
    setApplyDropdown(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        applyDropdown &&
        event.target.closest('.applydropbox') === null &&
        event.target.closest('.application_menu') === null
      ) {
        setApplyDropdown(null);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [applyDropdown]);

  const fetchData = useCallback(async (searchQuery) => {
    // Fetch data from API
    setLoading(true);
    DeviceService.getDeviceList(page, searchQuery)
      .then((response) => {
        setDeviceList(response.data);
        // setTotalPages(1);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }, []);

  // Debounced version of the fetchResults function
  const debouncedFetchResults = useCallback(debounce(fetchData, 300), []);

  useEffect(() => {
    debouncedFetchResults(searchTerm);
  }, [page, searchTerm]);

  const handleOpenForm = () => {
    setDeviceForEdit({});
    setModalOpen(true);
    handleClose();
  };

  const handleEdit = (row) => {
    const selectedDevice = deviceList.find(
      (camera) => camera.id === row.id
    );
    setDeviceForEdit({
      ...selectedDevice,
      username: selectedDevice?.onvif?.username,
      url: selectedDevice?.onvif?.url,
      password: selectedDevice?.onvif?.password
    });
    setModalOpen(true);
    handleClose();
  };

  const handleDeleteConfirm = (row) => {
    setDeviceId(row.id);
    setDeleteIsOpen(true);
    handleClose();
  };

  const handleDelete = async () => {
    try {
      await DeviceService.deleteDevice(deviceId).catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      setDeleteIsOpen(false);
      toast.success('Device deleted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
    } catch (err) {
      console.log(err.message);
      toast.error('Error in deleting device', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedFetchResults(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
  };
  const handleForm = () => {
    debouncedFetchResults(searchTerm);
  }
  const columns = [
    'name',
    'status',
    'model',
    'last_updated_on',
    'action',
  ];
  const headCells = [
    {
      id: 'name',
      numeric: true,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: true,
      label: 'Status',
    },
    {
      id: 'model',
      numeric: false,
      disablePadding: true,
      label: 'Model',
    },
    {
      id: 'last_updated_on',
      numeric: false,
      disablePadding: true,
      label: 'Last Updated On',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  const rows = [
    ...deviceList.map((device) => {
      return {
        id: device.id,
        name: device.name,
        status: device.status,
        model: device.name,
        last_updated_on: device.created_at,
        ...device,
      };
    }),
  ];
  return (
    <div>
    <DatatableHeadingStyle>
      <Helmet>
        <title>Mirasys: Devices</title>
      </Helmet>
      <Box className="user_heading">
        <Typography component="h2">Device management</Typography>
        <Box className="user_head_search">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box className="table_head_btn_con">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenForm}
              endIcon={<AddIcon />}
            >
              Add New
            </Button>
          </Box>
        </Box>
      </Box>
    </DatatableHeadingStyle>
    <ApplicationStyle>
      <Box className="application_page_content" component={'div'}>
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
            <CommonDataTable
              rows={rows}
              headCells={headCells}
              columns={columns}
              handleEdit={handleEdit}
              handleDelete={handleDeleteConfirm}
            />
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
          </>
        )}
      </Box>
      <BaseModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={deviceForEdit?.id ? 'Edit Device' : 'Add Device'}
      >
        <EditDeviceForm
          onClose={() => setModalOpen(false)}
          deviceForEdit={deviceForEdit}
          fetchData={handleForm}
        />
      </BaseModal>
      <DeleteDialog
        title="Delete Device?"
        message="Are you sure you want to delete this device?"
        setIsOpen={setDeleteIsOpen}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onDelete={handleDelete}
      />
    </ApplicationStyle>
    </div>
  );
  
};

export default DeviceListView;
