import { Box, Button, Pagination, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { VideoUploadStyle } from '../../../Layouts/style';
import VideoUploadForm from '../../../Components/Form/VideoUploadForm';
import VideoListDataTable from '../../../Components/DataTable/VideoListDatatable';
import useAuth from '../../../Redux/Actions/authActions';
try {
  
} catch (error) {
  
}
const VideoUploadView = () => {
  const [videoUpload, setVideoUpload] = useState(false);
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const { logout } = useAuth();
  const handleVideoUploadForm = () => {
    setVideoUpload(!videoUpload);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const columns = [
    'name',
    'description',
    'added_by',
    'start_time',
    'createdAt',
  ];
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'details',
      numeric: false,
      disablePadding: true,
      label: 'Description',
    },
    {
      id: 'idNumber',
      numeric: false,
      disablePadding: true,
      label: 'Created By',
    },
    {
      id: 'startTime',
      numeric: false,
      disablePadding: true,
      label: 'Start Time',
    },
    {
      id: 'dateTime',
      numeric: false,
      disablePadding: true,
      label: 'Upload Time',
    },
  ];

  const fetchData = async () => {
   
  };

  useEffect(() => {
    fetchData();
  }, [page, sortBy, sortOrder]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <VideoUploadStyle>
      <Box className="video_upload_area">
        <Box
          className={
            videoUpload ? 'video_upload_form show' : 'video_upload_form'
          }
        >
          <Box className="video_upload_form_con">
            <VideoUploadForm
              handleVideoUploadForm={handleVideoUploadForm}
              fetchData={fetchData}
            />
          </Box>
        </Box>
        <Box
          className={
            videoUpload ? 'video_upload_list small' : 'video_upload_list'
          }
        >
          <Box
            className={
              videoUpload ? 'video_upload_btn hide' : 'video_upload_btn'
            }
          >
            <Button onClick={handleVideoUploadForm}>Upload Video</Button>
          </Box>
          <VideoListDataTable
            rows={videos}
            headCells={headCells}
            columns={columns}
          />
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </Box>
      </Box>
    </VideoUploadStyle>
  );
};

export default VideoUploadView;
