import React from 'react';
import {
    Box,
    Chip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { ActiveIcon, DeleteIcon, EditIcon } from '../../Icons/SVGContainer';

function EnhancedTableHead(props) {
    const { headCells } = props;

    return (
        <TableHead>
            <TableRow className="table_head">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const BusinessDataTable = ({ rows, headCells, columns, onClick }) => {
    const visibleRows = rows;


    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
                className="datatable_common"
            >
                <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
                <TableBody>
                    {visibleRows.map((row) => {
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id}
                                sx={{ cursor: 'pointer' }}
                            >
                                {columns.map((col, index) => (
                                    <TableCell align={index === 0 ? 'left' : 'right'} key={col}>
                                        {col === 'fullName' ? (
                                            <Box className="task_name">
                                                <img src="/images/admin_pic.png" alt="" />
                                                <Typography component="span">{`${row.firstname} ${row.lastname}`}</Typography>
                                            </Box>
                                        ) : col === 'status' ? (
                                            <Chip
                                                icon={<ActiveIcon />}
                                                label="Active"
                                                className="active_data"
                                            />
                                        ) : col === 'action' ? (
                                            <Box className="action_icon">
                                                <IconButton
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <EditIcon onClick={onClick} />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            row[col]
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BusinessDataTable;
